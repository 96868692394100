import DbManager from './DbManager'
import {
  db_get_social_account_root_url,
  db_get_user_data_url,
  db_get_social_account_by_id_url,
  db_get_user_data_by_id_url,
  db_get_social_account_member_url,
  db_get_user_social_account_by_id_url
} from './dbPaths'

class ManagerSocialAccount {
    constructor(auth) {
      this.auth = auth
      this.current_account = {
        "said" : "not_set"
      }
      this.user_roles = {
        "admin" : "admin",
        "editor" : "editor"
      }
    }

    set_said(said) {
      this.current_account.said = said
    }
    set_auth(auth) {
      this.auth = auth
    }

    async ini() {
      const auth = this.auth
      const current_account_id = await this._get_user_current_account()
      if (current_account_id) {
        this.set_said(current_account_id)
      } else {
        const account_name = auth().currentUser.email
        const uid = auth().currentUser.uid
        const said = await this.create_social_account(account_name)
        this.add_social_account_to_user(said, uid)
        this.set_said(said)
      }
    }

    async _get_user_current_account() {
      const db_manager = new DbManager()
      const origin_url = db_get_user_data_url()
      db_manager.set_url(origin_url)
      const data = await db_manager.get_data()
      const current_id = data.current_social_account_id
      if (current_id && current_id != "not_set" && current_id!=""){
        return current_id
      }
      return false
    }

    async create_social_account(account_name) {
      const auth = this.auth
      const db_manager = new DbManager()
      const url = db_get_social_account_root_url()
      db_manager.set_url(url)
      const obj = {
        "name" : account_name,
        "owner" : auth().currentUser.uid,
        "member" : {
          [auth().currentUser.uid] : {
            "joined" : true
          }
        }
      }
      const ref = db_manager.push_data(obj).ref
      const said = ref.getKey()
      return said
    }

    async get_account_data(said) {
      const db_manager = new DbManager()
      const url = db_get_social_account_by_id_url(said)
      db_manager.set_url(url)
      const data = await db_manager.get_data()
      return data
    }

    async check_if_user_exists(said) {
      const db_manager = new DbManager()
      const url = db_get_user_data_by_id_url(said)
      db_manager.set_url(url)
      const data = await db_manager.get_data()
      if (data) {
        return true
      }
      return false
    }

    async add_social_account_to_user(said, uid) {
      const db_manager = new DbManager()
      const url = db_get_user_social_account_by_id_url(uid)
      db_manager.set_url(url)
      const obj = {
        [said] : {
          "primary" : false
        },
      }
      db_manager.store_data(obj)
    }

    async add_user_to_social_account(said, uid, role) {
      const db_manager = new DbManager()
      const url = db_get_social_account_member_url(said)
      db_manager.set_url(url)
      const obj = {
        [uid] : {
          "role" : role,
          "joined" : false
        },
      }
      db_manager.store_data(obj)
    }
  }

export default ManagerSocialAccount
