export const INFLUXIO_VERSION = "1.4.5"

export const COIN_COUNT_START_IP = 1000
export const COIN_COUNT_START_AUTH = 1500

export const MIN_POST_COUNT = 10
export const MIN_FAN_LIKE_RATE = 25
export const MIN_FAN_COMMENT_RATE = 15
export const MAX_FAN_DISPLAY_AMOUNT = 100

export const GROW_DAILY_TODO_AMOUNT = 20
//limit the amount of accounts craweled for the grow list
export const GROW_MAX_CRAWL = 5
export const GROW_AMOUNT_GET_MORE_GROW_LIST = 50
export const GROW_AMOUNT_GET_METADATA_LENGTH = 1000
// export const GROW_AMOUNT_GET_METADATA_LENGTH = 500
//export const GROW_AMOUNT_GET_MORE_GROW_LIST = 1000

export const DEBUG = false
export const DEBUG_BUFFER = false
export const API_WITH_CORS = true
export const API_LIKER_CRAWL_AMOUNT = 100

var mili_seconds_per_hour = 3600 * 1000
if (DEBUG_BUFFER){
  mili_seconds_per_hour = 60 * 1000 * 1
}

var mili_seconds_per_day = 86400 * 1000
if (DEBUG_BUFFER){
  mili_seconds_per_day = 60 * 1000 * 1
}

export const BUFFER_FAN_MEDIA_LIKER = mili_seconds_per_hour * 24
export const MEDIA_COMMENTER_CRAWLER_BUFFER = mili_seconds_per_hour * 24
//export const PROFILE_CRAWLER_BUFFER = seconds_per_hour * 24
export const PROFILE_USER_BUFFER = mili_seconds_per_hour * 2
export const GROW_PROFILE_BUFFER = mili_seconds_per_hour * 1
export const GROW_PROFILE_CHECK_METADATA = mili_seconds_per_hour * 72
export const GROW_NEXT_INTERACTION_BUFFER = mili_seconds_per_hour * 72
export const GROW_HASHTAG_FEED_BUFFER = mili_seconds_per_hour * 3
export const PROFILE_QUERY_BUFFER = mili_seconds_per_hour * 24
//export const PROFILE_QUERY_BUFFER = 5
export const SEARCH_PROFILE_BUFFER = mili_seconds_per_hour * 1
export const PROFILE_FAN_QUERY_BUFFER = mili_seconds_per_hour * 48
//export const METADATA_CRAWLER_BUFFER = mili_seconds_per_hour * 24
export const PROFILE_REFRESH_PICTURE = mili_seconds_per_hour * 24

export const BUFFER_ANALYTICS_INTEREST_GET_FOLLOWER = mili_seconds_per_hour * 24
export const BUFFER_ANALYTICS_INTEREST_GET_FOLLOWER_METADATA = mili_seconds_per_hour * 24

export const BUFFER_GROW_CREATE_LIST_FOLLOWER_FOLLOWER_GET_FOLLOWER = mili_seconds_per_hour * 48
//export const BUFFER_GROW_CREATE_LIST_FOLLOWER_FOLLOWER_GET_FOLLOWER = 1000 * 60
export const BUFFER_GROW_CREATE_LIST_FOLLOWER_FOLLOWER_GET_METADATA = mili_seconds_per_hour * 48
//export const BUFFER_GROW_CREATE_LIST_FOLLOWER_FOLLOWER_GET_METADATA = 1000 * 20

export const BUFFER_GROW_CREATE_LIST_FOLLOWER_FOLLOWING_GET_FOLLOWER = mili_seconds_per_hour * 168
export const BUFFER_GROW_CREATE_LIST_FOLLOWER_FOLLOWING_GET_FOLLOWING = mili_seconds_per_hour * 168
export const BUFFER_GROW_CREATE_LIST_FOLLOWER_FOLLOWING_GET_METADATA = mili_seconds_per_hour * 168

export const PROFILE_REFRESH_PROXY = mili_seconds_per_hour * 1

export const DEFAULT_CLIENT_LANGUAGE = "de"
export const HASHTAG_LANGUAGE_CHECK_MIN = 3
export const GROW_PROFILE_CHECK_MIN_POST_AMOUNT = 10
export const GROW_PROFILE_CHECK_MIN_MAX_FOLLOWER = 100000
export const GROW_PROFILE_CHECK_MIN_MAX_FOLLOWING = 1000
