import DbManager from "../../helper/DbManager";
import {db_get_ayrshare_url} from "../../helper/dbPaths";

class ManagerAyrshare {
  constructor() {
    this.buffer_time = 5*60*60*1000
  }

  set_profile_key(profile_key) {
    this.profile_key = profile_key
  }

  set_buffer_time(buffer_time) {
    this.buffer_time = buffer_time
  }

  history(ident_key) {
    const db_url = db_get_ayrshare_url() + ident_key
    const profile_key = this.profile_key
    const url = `https://app.ayrshare.com/api/history`
    return this._get(db_url, url)
  }

  user(ident_key) {
    const db_url = db_get_ayrshare_url() + ident_key
    const social_url = "https://app.ayrshare.com/api/user"
    return this._get(db_url, social_url)
  }


  analytics_social(ident_key,platforms) {
    const key = "analytics_social"
    const social_url = "https://app.ayrshare.com/api/analytics/social"
    const db_url = db_get_ayrshare_url() + ident_key
    const body_obj = {
      platforms: platforms // required
    }
    // TODO really test it and not with dummy
    // const dummy = {
    //   "status" : "error",
    //   "instagram" : {
    //     "message" : "something went wrong here"
    //   }
    // }
    // return dummy
    return this._post(db_url, social_url, body_obj)
  }

  analytics_post(data_obj) {
    const key = "analytics_post"
    const { platforms, post_id } = data_obj
    const social_url = "https://app.ayrshare.com/api/analytics/post"
    const db_url = db_get_ayrshare_url() + key + "/" + post_id
    const body_obj = {
      platforms: platforms, // required
      id: post_id, // required
    }
    return this._post(db_url, social_url, body_obj)
  }

  publish(payload_obj) {
    const social_url = "https://app.ayrshare.com/api/post"
    const {
      post,
      platforms,
      mediaUrls,
      scheduleDate,
      instagramOptions,
      comment
    } = payload_obj
    const body_obj = {
      post: post, // required
      platforms: platforms, // required
      mediaUrls: mediaUrls, // required
      scheduleDate: scheduleDate, // required
      instagramOptions: instagramOptions, // required
      comment: comment, // required
    }
    return this._fetch_post(social_url, body_obj)
  }

  delete(post_id) {
    const social_url = "https://app.ayrshare.com/api/post"
    const body_obj = {
      id: post_id, // required
    }
    return this._fetch_delete(social_url, body_obj)
  }

  async _post(db_url, social_url, body_obj){
    const db_manager = new DbManager()
    var data = []
    var return_data = []
    db_manager.set_url(db_url)
    data = await db_manager.get_data()
    // data = false
    if (data && !this._check_if_refresh(data) ) {
      return_data = data
    } else {
      data = await this._fetch_post(social_url, body_obj)
      if(data.status == "error") {
        return data
      }
      if (data){
        data.timestamp = new Date().getTime()
        db_manager.store_data(data)
        return_data = data
      }
    }
    return return_data
  }

  async _get(db_url, social_url){
    const db_manager = new DbManager()
    var data = []
    var return_data = []
    db_manager.set_url(db_url)
    data = await db_manager.get_data()
    // data = false
    if (data && !this._check_if_refresh(data) ) {
      return_data = data
    } else {
      data = await this._fetch_get(social_url)
      if(data.status == "error") {
        return false
      }
      if (data){
        data.timestamp = new Date().getTime()
        db_manager.store_data(data)
        return_data = data
      }
    }
    return return_data
  }

  _check_if_refresh(data) {
    var do_refresh = false
    const recent_timestamp = new Date().getTime()
    if (!data.timestamp) {
      do_refresh = true
    }
    const valid_range = data.timestamp + this.buffer_time
    if (recent_timestamp > valid_range) {
      do_refresh = true
    }
    return do_refresh
  }

  _fetch_get(url) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${this.profile_key}`
        }
      })
      .then(response => response.json())
      .then(response1 => this.parse_data(response1))
      .then(parsed_data => resolve(parsed_data))
      .catch(err => {
        console.log("error",err);
        debugger
        resolve(false)
      });
    })
  }

  _fetch_post(url,body_obj) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${this.profile_key}`
        },
        body: JSON.stringify(
          body_obj
        ),
      })
      .then(response => response.json())
      .then(response1 => this.parse_data(response1))
      .then(parsed_data => resolve(parsed_data))
      .catch(err => {
        console.log("error",err);
        resolve(false)
      });
    })
  }

  _fetch_delete(url,body_obj) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${this.profile_key}`
        },
        body: JSON.stringify(
          body_obj
        ),
      })
      .then(response => response.json())
      .then(response1 => this.parse_data(response1))
      .then(parsed_data => resolve(parsed_data))
      .catch(err => {
        console.log("error",err);
        resolve(false)
      });
    })
  }


  parse_data(raw_data) {
    return raw_data
  }
}

export default ManagerAyrshare
