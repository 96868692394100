class ParserInstagramLocation {
    constructor() {
      this.raw_json = ""
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    get_parsed_data() {
      const location_raw_list = this._get_location_raw_list()
      var location_list = []
      for (var i in location_raw_list) {
        const raw_location = location_raw_list[i]
        const location_obj = this._get_location_obj(raw_location)
        if (location_obj) {
          const parsed_location = this._parse_location(location_obj)
          if (parsed_location) {
            location_list.push(parsed_location)
          }
        }
      }
      if (location_list.length > 0) {
        return location_list
      }
      return false
    }

    _get_location_raw_list() {
      const raw_data = this.raw_json
      try {
        const location_raw_list = raw_data.places
        if (location_raw_list && location_raw_list.length > 0) {
          return location_raw_list
        }
      } catch(err) {
        return false
      }
    }

    _get_location_obj(raw_location_obj) {
      try {
        const location_obj = raw_location_obj.place.location
        if (location_obj) {
          return location_obj
        }
      } catch(err) {
        return false
      }
    }

    _parse_location(location_obj) {
      var obj = {
        "short_name" : location_obj.short_name,
        "facebook_places_id" : location_obj.facebook_places_id,
        "city" : location_obj.city,
      }
      return obj
    }

}


export default ParserInstagramLocation
