// used for instagram_discovery and instagram_hashtag feed, and maybe UserFeedWith Analytics


class ParserMediaInstagramBd {
    constructor() {
      this.obj = {}
      this.raw_media_list = []
      this.feed_group_id = new Date().getTime()
      this.media_formats = ["IMAGE"]
    }
    set_json(raw_json) {
     this.raw_json = raw_json
    }

    set_user_search_query(user_search_query) {
     this.user_search_query = user_search_query
    }

    get_parsed_data() {
      this.parse()
      const obj = {
        "media_list" : this.obj.media_list,
        "metadata" : "METADATA"
      }
      return obj
    }

    parse() {
      this._set_media_list()
      var obj = {
        "media_list" : this._get_media(),
      }
      this.obj= obj
    }

    _is_supported_format(media) {
      if (this.media_formats.includes(media.media_type)) {
        return true
      }
      return false
    }

    set_feed_group_id() {
     this.feed_group_id = new Date().getTime()
    }




    _set_media_list() {
      var media_list = []
      try {
        media_list = this.raw_json[0].business_discovery.media.data
      } catch {
      }
      try {
        media_list = this.raw_json.business_discovery.media.data
      } catch {
      }
      try {
        media_list =
          this.raw_json.business_discovery.business_discovery.media.data
      } catch {
      }
      if (media_list.length < 1) {
        return false
      }
      this.raw_media_list = media_list
    }

    _get_media() {
      const raw_media_list = this.raw_media_list
      const media_list = this._parse_media(raw_media_list)
      return media_list
    }

    _parse_media(raw_media_list) {
      var media_list = []
      for (var i in raw_media_list) {
        var media = raw_media_list[i]
        var parsed_media_obj = this._parse_media_obj(media)
        media_list.push(parsed_media_obj)
      }
      return media_list
    }

    _get_caption(media_obj) {
      if (media_obj.caption) {
        return media_obj.caption
      }
      return "EMPTY"
    }

    _get_timestamp(media_obj) {
      if (!media_obj.timestamp) {
        return "EMPTY"
      }
      const timestamp = media_obj.timestamp
      return timestamp
    }

    _get_taken_at_timestamp(media_obj) {
      if (!media_obj.timestamp) {
        return "EMPTY"
      }
      const timestamp = new Date(media_obj.timestamp).getTime()/1000
      return timestamp
    }

    _parse_media_obj(media_obj) {
        var obj = {
          "media_source" : "instagram_user",
          "caption" : this._get_caption(media_obj),
          "comments_count"  : media_obj.comments_count,
          "id" : media_obj.id,
          "like_count" : media_obj.like_count,
          "media_type" : media_obj.media_type,
          "media_url" : media_obj.media_url,
          // "media_url" : media_obj.media_url,
          // "media_url" : media_obj.permalink + "media/?size=l",
          "permalink" : media_obj.permalink,
          "timestamp" : this._get_timestamp(media_obj),
          "taken_at_timestamp" : this._get_taken_at_timestamp(media_obj),
          "user_id" : media_obj.owner.id,
          "username" : media_obj.username,
          "feed_entry_ident" : "instagram_feed_account",
          "feed_entry_id" : media_obj.id,
          "feed_entry_timestamp" : new Date().getTime(),
          "feed_entry_group_id" : this.feed_group_id,
          "feed_entry_user_search_query" : this.user_search_query
        }
        return obj
    }


}

export default ParserMediaInstagramBd
