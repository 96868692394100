export const registration_url = "/accounts/create-account"
export const login_url = "/accounts/login"
export const hashtag_generator_url = "/app/hashtagGenerator"


// tracking variables

const tracking_variable_campaign = "campaign"
const tracking_variable_page = "page"
const tracking_action_amount = "action_amount"


// tracking variables campaign values
const tracking_variable_campaign_true = "true"
const tracking_variable_campaign_false = "false"

// tracking variables page values

const public_hashtag_generator = "public_hashtag_generator"
const registration = "registration"
const registration_done = "registration_done"
const dashboard_locked = "dashboard_locked"
const purchase_formular_influxio = "purchase_formular_influxio"
const influxio_purchased_thanks = "influxio_purchased_thanks"
const dashboard_unlocked = "dashboard_unlocked"


export const REGISTRATION_URL = "/accounts/create-account"
export const LOGIN_URL = "/accounts/login"
export const DASHBOARD_URL = "/app/dashboard"
export const EXPLORE_URL = "/app/explore"
export const PUBLISH_URL = "/app/inspire"
export const EDITOR_URL = "/app/editor"
export const SETTINGS_URL = "/app/settings"
export const SETTINGS_AYR_URL= "/app/settings?dest=ayr"
export const ANALYTICS_URL = "/app/analytics"
export const HASHTAGGENERATOR_URL = "hashtagGenerator"
export const HASHTAGGENERATORAPP_URL = "/app/hashtagGenerator"
export const HASHTAGGENERATORPUB_URL = "/hashtagGenerator"
export const PROMO_URL = "/app/promo"
export const PURCHASE_URL = "/user/plans/purchase"
export const THANK_URL= "/app/thanks"
export const REGISTRATION_DONE_URL= "/app/registration-done"
export const ACCOUNTSETTINGS_URL= "/app/account-settings"
export const PLANS_URL= "/user/plans"
export const DIGISTORE_URL= "/user/digistore-formular"
export const GET_MORE_URL= "/user/get_more"
export const CONNECT_SOCIAL_URL= "/user/connect_social"
export const ACCOUNT_CONNECTED_URL= "/user/account_connected"
export const ACCOUNT_CONNECTED_DONE_URL= "/user/account_connected_done"
export const ONBOARDING_QUIZ_URL= "/app/quiz"

export const STORYSAVER_URL= "/storysaver/"
export const STORYSAVER_SEARCH_URL= "/storysaver/search"
export const STORYSAVER_RESULT_URL= "/storysaver/result"
export const STORYSAVER_WATCH_URL= "/storysaver/watch"

export const M_HOME= "/m/"
export const M_EXPLORE_MEDIA= "/m/explore/media"
export const M_ANALYTICS_ENGAGER= "/m/analytics/engager"
