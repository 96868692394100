export const SET_AUTH_DATA_BY_KEY = 'SET_AUTH_DATA_BY_KEY'

export function setAuthUser(payload) {
  const key = "user"

  return async dispatch => {
    dispatch(setDataByKey(key,payload))
  }
}

export function setDataByKey(key,payload) {
  const obj = {
    [key] : payload
  }
  return {
    type: SET_AUTH_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}
