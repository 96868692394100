import React, { useState, useEffect } from 'react'


function Loading(props,{}) {

  const class1 = "loader ease-linear rounded-full border-4 border-t-4"
  const class2 = "border-gray-200 h-6 w-6"
  const my_class = class1 + class2

  return (
    <>
      <div className={my_class}></div>
    </>
  )
}

export default Loading
