import DbManager from "../../helper/DbManager";
import RequestManager from "../../helper/RequestManager";


import {
  db_get_willPresetList_user_url,
  db_get_willPreset_user_url
} from '../../helper/dbPaths'

const db_manager = new DbManager()
const request_manager = new RequestManager()

export const SET_PRESET_FINISHED = 'SET_PRESET_FINISHED'
export const SET_PRESET_LIST = 'SET_PRESET_LIST'

export function fetchWillPresets() {
  return async dispatch => {
    db_manager.set_url(db_get_willPresetList_user_url())
    const user_db_presets = await db_manager.get_data()
    dispatch(setPresetList(user_db_presets))
    return true
  }
}

export function setPresetFinished(preset_id, new_count) {
  const data = {finished_count : new_count}
  db_manager.set_url(db_get_willPreset_user_url(preset_id))
  db_manager.store_data(data)
  return {
    type: SET_PRESET_FINISHED,
    preset_id: preset_id,
    new_count: new_count
  }
}
function setPresetList(preset_list) {
  return {
    type: SET_PRESET_LIST,
    preset_list: preset_list,
  }
}
