class ParserIgUserMediaList {
    constructor() {
      this.obj = {}
    }

    get_parsed_data(json) {
      this._set_json(json)
      var media_list = []
      const raw_media_list = this._get_media_list()
      for (var i = 0; i < raw_media_list.length; i++) {
        const parsed_media = this._parse_media(raw_media_list[i])
        media_list.push(parsed_media)
      }
      return media_list
    }

    _set_json(raw_json) {
      this.raw_json = raw_json
    }

    _get_media_list() {
      const data = this.raw_json
      const media_list = data[0].data
      return media_list
    }


    _parse_media(media) {
      var obj= {
        "id" : media.id,
        "media_type" : media.media_type,
        "timestamp" : media.timestamp,

      }
      return obj
    }

  }


  export default ParserIgUserMediaList
