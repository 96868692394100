class ParserHashtag {
    constructor() {
      this.obj = {}
      this.obj_list = []
      this.feed_group_id = new Date().getTime()
      this.media_formats = ["IMAGE"]
      this.user_search_query = ""
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    set_user_search_query(user_search_query) {
     this.user_search_query = user_search_query
    }

    get_parsed_list() {

      // if (this._is_error()) {
      //   return false
      // }
      var data = this._get_media_list()
      var media_list = []
      for (var i in data) {
        var obj = {}
        obj = this._get_parsed_obj(data[i])
        if (this._is_supported_format(obj)){
          media_list.push(obj)
        }
      }
      return media_list
    }

    _is_supported_format(media) {
      if (this.media_formats.includes(media.media_type)) {
        return true
      }
      return false
    }

    _is_error() {
      var is_error = false
      if (this.raw_json[0].error) {
        if (this.raw_json[0].error.code) {
          is_error = true
        }
      }
      if (!this.raw_json) {
        is_error = true
      }
      return is_error
    }


    _get_media_list() {
      var media_list = []
      var error
      try {
        media_list = this.raw_json[0].business_discovery.media.data
        if (media_list.length > 0) {
          return media_list
        } else {
          debugger
        }
      } catch(err) {
        error = err
        media_list = []
      }
      try {
        media_list = this.raw_json[0].data
        if (media_list.length > 0) {
          return media_list
        } else {
          debugger
        }
      } catch(err) {
        error = err
        media_list = []
      }
      try {
        media_list = this.raw_json.data
        if (media_list.length > 0) {
          return media_list
        } else {
          debugger
        }
      } catch(err) {
        error = err
        media_list = []
      }
      if (media_list == [] || !media_list ) {
        console.log(error)
        debugger
      }
      return media_list
    }




    _get_catpion(media_obj) {
      var data = "CAPTION"
      try {
        data = media_obj.caption
        data = this._check_value(data,media_obj,"caption")
      }
      catch(err) {
        console.log(err)
      }
      return data
    }
    _get_publish_utc_date(media_obj) {
      var data = "UTC_DATE"
      try {
        data = media_obj.timestamp
        data = this._check_value(data,media_obj,"utc_date")
      }
      catch(err) {
        console.log(err)
      }
      return data
    }
    _get_timestamp(media_obj) {
      var data = "TIMESTAMP"
      var timestamp = media_obj.timestamp
      timestamp = this._check_value(timestamp,media_obj,"timestmap")
      if (timestamp == "not_set") {
        return data
      }
      return timestamp
    }
    _get_like_count(media_obj) {
      const placeholder = "LIKES"
      const value = media_obj.like_count
      return this._check_value(value,media_obj,placeholder)
    }
    _get_comments_count(media_obj) {
      const placeholder = "COMMENTS"
      const value = media_obj.comments_count
      return this._check_value(value,media_obj,placeholder)
    }

    _get_media_url(media_obj) {
      var data = "MEDIA_URL"
      try {
        data = media_obj.media_url
        data = this._check_value(data,media_obj,"media_url")
      }
      catch(err) {
        console.log(err)
      }
      return data
    }
    _get_permalink(media_obj) {
      var data = "PERMALINK"
      try {
        data = media_obj.permalink
        data = this._check_value(data,media_obj,"permalink")
      }
      catch(err) {
        console.log(err)
      }
      return data
    }
    _get_id(media_obj) {
      var data = "ID"
      try {
        data = media_obj.id
        data = this._check_value(data,media_obj,"id")
      }
      catch(err) {
        console.log(err)
      }
      return data
    }
    _get_media_type(media_obj) {
      var data = "media_type"
      try {
        data = media_obj.media_type
        data = this._check_value(data,media_obj,"media_type")
      }
      catch(err) {
        console.log(err)
      }
      return data
    }

    _check_value(value, media_obj, placeholder) {
      var ok = true
      // if (!value) {
      //   ok = false
      // }
      // if (value == null) {
      //   ok = false
      // }
      if (value == undefined) {
        ok = false
      }
      if (Number.isNaN(value)) {
        ok = false
      }
      if (!ok) {
        // console.log(media_obj,type)
        value = placeholder
        return value
        // debugger
      }
      return value
    }

    _get_parsed_obj(media_obj) {
      var obj = {
        "media_source" : "instagram_hashtag",
        "media_type" : this._get_media_type(media_obj),
        "caption" : this._get_catpion(media_obj),
        "utc_date" : this._get_publish_utc_date(media_obj),
        "timestamp" : this._get_timestamp(media_obj),
        "like_count" : this._get_like_count(media_obj),
        "comments_count" : this._get_comments_count(media_obj),
        "media_url" : this._get_media_url(media_obj),
        "permalink" : this._get_permalink(media_obj),
        "username" : "#" + this.user_search_query,
        "id" : this._get_id(media_obj),
        "feed_entry_ident" : "instagram_feed_hashtag",
        "feed_entry_id" : media_obj.id,
        "feed_entry_timestamp" : new Date().getTime(),
        "feed_entry_group_id" : this.feed_group_id,
        "feed_entry_user_search_query" : this.user_search_query
      }
      return obj
    }

}


export default ParserHashtag
