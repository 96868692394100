import { formatDistanceToNow } from 'date-fns'
import { auth } from './firebase/iniFirebase'

import {
  IDENT_UACC_INSTA_NAME,
} from '../redux/constants/accountConstants'

export function check_if_authed() {
  if (auth().currentUser){
    return true
  }
  return false
}

export function set_url_parameter(ident, value) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  urlParams.set(ident, value)
}

export function get_url_parameter(ident) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const value = urlParams.get(ident)
  if (!value){
    return false
  }
  return value
}

export function get_url_parameter_obj() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entries = urlParams.entries()
  const obj = {}
  for(const entry of entries) {
    const key = entry[0]
    const value = entry[1]
    obj[key] = value
  }
  return obj
}

export function get_profile_dummy() {
  const dummy = {
    metadata : {
      username : "-",
      bio : "Bio",
      full_name : "Name",
      category_name : "Category-Name",
      post_count:"-",
      follower_count:"-",
      following_count:"-",
      likes_per_post:"-",
      comments_per_post:"-",
      engagement_rate:"-",
      engagement_index:"-/-"
    },
    media_list : []
  }
  return dummy
}

export function key_obj_to_int_list(key_list, ident) {
  const return_list = []
  for (var key in key_list){
    return_list.push(key_list[key])
  }
  return return_list
}

export function int_list_to_encoded_key_obj(int_list, ident) {
  const obj = {}
  for (var i in int_list){
    const item = int_list[i]
    if (!item){
      continue
    }
    const key = item[ident]
    if (!key){
      continue
    }
    const encoded_key = encode_insta_name(key)
    obj[encoded_key] = item
  }
  return obj
}

export function get_array_not_match(arr1, arr2) {
  let arrayDifference = arr1.filter(x => !arr2.includes(x));
  return arrayDifference
}

export function array_to_csv1(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';

            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (line != '') line += ','

                    line += array[i][index];
                }

                str += line + '\r\n';
            }

            return str;
 }

export function array_to_csv(objArray) {
     const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
     let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}` + '\r\n';

     return array.reduce((str, next) => {
         str += `${Object.values(next).map(value => `"${value}"`).join(",")}` + '\r\n';
         return str;
        }, str);
 }

export function helper_get_insta_name(cookie, userData) {
  const insta_name_redux = userData[IDENT_UACC_INSTA_NAME]
  const insta_name_cookie = cookie[IDENT_UACC_INSTA_NAME]
  if (insta_name_redux){
    if (insta_name_redux != ""){
      return insta_name_redux
    }
  }
  if (insta_name_cookie){
    if (insta_name_cookie != ""){
      return insta_name_cookie
    }
  }
  return false
}

export function encode_insta_name(insta_name) {
  return insta_name.replaceAll(".",";");
}

export function decode_insta_name(insta_name) {
  return insta_name.replaceAll(";",".");
}


export function remove_doubles(array) {
  return [...new Set(array)]
}

export function cut_string_add_dots(input,size) {
 if (!input){
   return ""
 }
 if (input.length > size) {
    return input.substring(0, size) + '...';
 }
   return input;
};

export function sort_by_key_int(obj_list,key) {
  obj_list.sort((a,b) => b[key] - a[key])
  return obj_list
}


export function sort_by_key_str(obj_list,key) {
  obj_list.sort((a, b) => a[key].localeCompare(b[key]));
  return obj_list
}

export function group_array_by_key(list,key) {
  const result = list.reduce(function (r, a) {
      r[a[key]] = r[a[key]] || [];
      r[a[key]].push(a);
        return r;
          }, Object.create(null));
  return result
}

export function get_time_since(ref_timestamp, locale) {
  var date = cross_browser_create_date(ref_timestamp)
  return formatDistanceToNow(date, { locale: locale })
}


export function cross_browser_create_date(timestamp) {
  var date = new Date(timestamp)
  if (isNaN(date)){
    const date_string = timestamp.replace("+0000","Z")
    date = new Date(date_string)
  }   // const date_string = timestamp + 'Z'
  return date
}

export function shuffle_array(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function shuffle_array_same_seed(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(my_random * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

var seed = 1;
function my_random() {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}

export function check_if_value_exist_in_array(array, value){
  return array.indexOf(value) > -1
}

export function slice_array_to_chunks(array, chunk_size){
  if (array.length <= chunk_size){
    return [array]
  }
  var new_array = []
  var i,j, temporary, chunk = chunk_size;
  for (i = 0,j = array.length; i < j; i += chunk) {
      temporary = array.slice(i, i + chunk);
      if (temporary.length == chunk_size){
        new_array.push(temporary)
      }
  }
  return new_array
}

export function get_env() {
  const hostname = window.location.hostname
  var env = ""
  if ( hostname == "localhost"|| hostname === 'test.influxio.com') {
    env = "dev"
  } else {
    env = "prod"
  }
  return env
}

export function trim_string(string, max_length) {
  //trim the string to the maximum length
  var trimmedString = string.substr(0, max_length);
  //re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(0, Math.min(
    trimmedString.length, trimmedString.lastIndexOf(" ")))
  return trimmedString
}

export function set_occurences(array){
  const occurrences = array.reduce(function (acc, curr) {
    return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
  }, {});
  return occurrences
}

export function calculate_median(values){
  if(values.length ===0) throw new Error("No inputs");

  values.sort(function(a,b){
    return a-b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2)
    return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

export function text_similarity(s1, s2){
        var m = 0;

        // Exit early if either are empty.
        if ( s1.length === 0 || s2.length === 0 ) {
            return 0;
        }

        // Exit early if they're an exact match.
        if ( s1 === s2 ) {
            return 1;
        }

        var range     = (Math.floor(Math.max(s1.length, s2.length) / 2)) - 1,
            s1Matches = new Array(s1.length),
            s2Matches = new Array(s2.length);

        for ( var i = 0; i < s1.length; i++ ) {
            var low  = (i >= range) ? i - range : 0,
                high = (i + range <= s2.length) ? (i + range) : (s2.length - 1);

            for (var j = low; j <= high; j++ ) {
            if ( s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j] ) {
                ++m;
                s1Matches[i] = s2Matches[j] = true;
                break;
            }
            }
        }

        // Exit early if no matches were found.
        if ( m === 0 ) {
            return 0;
        }

        // Count the transpositions.
        var k = 0
        var n_trans = 0

        for ( i = 0; i < s1.length; i++ ) {
            if ( s1Matches[i] === true ) {
            for ( j = k; j < s2.length; j++ ) {
                if ( s2Matches[j] === true ) {
                k = j + 1;
                break;
                }
            }

            if ( s1[i] !== s2[j] ) {
                ++n_trans;
            }
            }
        }

        var weight = (m / s1.length + m / s2.length + (m - (n_trans / 2)) / m) / 3,
            l      = 0,
            p      = 0.1;

        if ( weight > 0.7 ) {
            while ( s1[l] === s2[l] && l < 4 ) {
            ++l;
            }

            weight = weight + l * p * (1 - weight);
        }

        return weight;
}
