import RequestManagerInstagram from './RequestManagerInstagram'
import ParserInstagramBd from './ParserInstagramBd'

import {
  db_get_instagram_business_account_url,
  db_get_hashtag_id_url,
  db_get_hashtag_feed_url
} from "../dbPaths";
import {
  ig_get_business_discovery_url,
  ig_get_hashtag_id_url,
  ig_get_hashtag_feed_url
} from "../socialUrls";

class ManagerInstagramOfficial {
  constructor() {
    this.ig_id_tweet = "17841408760193392"
    this.ig_id = "17841405619328523"
    this.access_token_tweet = `EAAdmaA3XprgBAFC3vIYwXRmSRhSlRwfhSVZCoMnrG7Dun0ujch
    4g0FkvXznx5Hbf42sGoED4skJOw5e9RodgE6wnlJjGZBwre8RwWZCZAdi9CDfITMAN4BW3dRh
    ZArhgZCRMjiRZBpxQBHqfnaUu6EZABgPEUOjA8vFHmZANVQWRVoCXaWOECeczS`
    this.access_token=`EAAdmaA3XprgBANHUvT341Hnp1Tm1Bhjsquy4zLFmZCpOZB4WW8Jb547S0UTAA2w81ufYEFmOponAqKVDAaArurgtrg86HioLmGnATbDDuZC2SoJQGnoKvQlH9c94VgA1YRAkVOlndDuzmwkDsXBUw6ZCDgM8lUygtyFrggPl7CmqqHSCBdIGQTMoeXuQYTJ9XRe12AWqfwZDZD`
    this.request_manager = new RequestManagerInstagram()
  }

  set_buffer_time(buffer_time) {
    this.request_manager.buffer_time = buffer_time
  }

  async business_discovery_parsed(username) {
    const db_url = db_get_instagram_business_account_url(username)
    const ig_id = this.ig_id
    const access_token = this.access_token
    const social_url =
      ig_get_business_discovery_url(ig_id, username, access_token)
    const response = await this.request_manager.get(db_url, social_url, username)
    const parser = new ParserInstagramBd()
    parser.set_json(response)
    const parsed_data = parser.get_parsed_data()
    return parsed_data
  }

  async business_discovery(username) {
    const db_url = db_get_instagram_business_account_url(username)
    const ig_id = this.ig_id
    const access_token = this.access_token
    const social_url =
      ig_get_business_discovery_url(ig_id, username, access_token)
    const response = await this.request_manager.get(db_url, social_url, username)
    // const parser = new ParserInstagramBd()
    // parser.set_json(response)
    // const parsed_data = parser.get_parsed_data()
    return response
  }

  async hashtag(hashtag_string) {
    const ig_id = this.ig_id
    const access_token = this.access_token
    const db_url_id = db_get_hashtag_id_url(hashtag_string)
    const social_url_id =
      ig_get_hashtag_id_url(ig_id, hashtag_string, access_token)
    const hashtag_resp =
      await this.request_manager.get(db_url_id, social_url_id, hashtag_string)
    const hashtag_id = hashtag_resp.data[0].id
    const db_url_feed = db_get_hashtag_feed_url(hashtag_string)
    const type = "top"
    const after = ""
    const social_url_feed =
      ig_get_hashtag_feed_url(hashtag_id, ig_id, type, after, access_token)
    return this.request_manager.get(db_url_feed, social_url_feed, hashtag_string)
  }

}

export default ManagerInstagramOfficial


//https://graph.facebook.com/v13.0/ig_hashtag_search?user_id=17841405619328523&q=instagrammarketing&access_token=EAAdmaA3XprgBAIHL7i8dUBFsgEIwefPfDsdukgpZB0a4OEi3QTh0EeZBx6iTlC3uSrC5YuZBntOQukzMdFdF8sdkNrBDiFrmIMwaYMxW4VwLjmoLr34VUaszZAJ685UwZBVoQqU1ZBWWTBGklZCBN6XdUSBAyX63ZCSV2Iz1Ccv1fz2ZBaZB5cA28IawJYc9DxZA5AZD
//https://graph.facebook.com/v13.0/ig_hashtag_search?user_id=17841405619328523&q=instagrammarketing&access_token=EAAdmaA3XprgBAPG9mA0e5ZBi07O1jm4bTgZCStTZAa6h5t6jF0cAb7rWZCeFPZB4ZAvUhAbJ7wmAdXw78UPTTfhtpGg8miNBXiT0tFfvPevu9KEj1yzCITrcSWRdrpxZBHEh32Tg5vk1pT5VEnwGMkjJyHKuUCkjCQRdKiGYsAZDZD
//https://graph.facebook.com/v13.0/ig_hashtag_search?user_id=17841405619328523&q=instagrammarketing&access_token=EAAdmaA3XprgBAPG9mA0e5ZBi07O1jm4bTgZCStTZAa6h5t6jF0cAb7rWZCeFPZB4ZAvUhAbJ7wmAdXw78UPTTfhtpGg8miNBXiT0tFfvPevu9KEj1yzCITrcSWRdrpxZBHEh32Tg5vk1pT5VEnwGMkjJyHKuUCkjCQRdKiGYsAZDZD
//https://graph.facebook.com/v13.0/ig_hashtag_search?user_id=17841405619328523&q=instagrammarketing&access_token=EAAdmaA3XprgBAPG9mA0e5ZBi07O1jm4bTgZCStTZAa6h5t6jF0cAb7rWZCeFPZB4ZAvUhAbJ7wmAdXw78UPTTfhtpGg8miNBXiT0tFfvPevu9KEj1yzCITrcSWRdrpxZBHEh32Tg5vk1pT5VEnwGMkjJyHKuUCkjCQRdKiGYsAZDZD
//https://graph.facebook.com/v13.0/ig_hashtag_search?user_id=17841405619328523&q=instagrammarketing&access_token=
//https://graph.facebook.com/v11.0/ig_hashtag_search?user_id=17841405619328523&q=instagrammarketing&access_token=EAAdmaA3XprgBAPG9mA0e5ZBi07O1jm4bTgZCStTZAa6h5t6jF0cAb7rWZCeFPZB4ZAvUhAbJ7wmAdXw78UPTTfhtpGg8miNBXiT0tFfvPevu9KEj1yzCITrcSWRdrpxZBHEh32Tg5vk1pT5VEnwGMkjJyHKuUCkjCQRdKiGYsAZDZD
//EAAdmaA3XprgBAIHL7i8dUBFsgEIwefPfDsdukgpZB0a4OEi3QTh0EeZBx6iTlC3uSrC5YuZBntOQukzMdFdF8sdkNrBDiFrmIMwaYMxW4VwLjmoLr34VUaszZAJ685UwZBVoQqU1ZBWWTBGklZCBN6XdUSBAyX63ZCSV2Iz1Ccv1fz2ZBaZB5cA28IawJYc9DxZA5AZD

//EAAdmaA3XprgBAJg1IDteTuZCl7y7c8UFBFXyzdU2W0WgBWwvdRTgTCsADhgd66NmfpJp51ijo5SDLOD7AqPfWGfZADKLGwBLbPOAvSzGzSjy7KjR1UIsWuxlBZCL1E9ozzUtoBpsRbUSx5RaInWAdbk8rGEht1AqIVMQRkYbQZDZD

//received
//EAAdmaA3XprgBAPG9mA0e5ZBi07O1jm4bTgZCStTZAa6h5t6jF0cAb7rWZCeFPZB4ZAvUhAbJ7wmAdXw78UPTTfhtpGg8miNBXiT0tFfvPevu9KEj1yzCITrcSWRdrpxZBHEh32Tg5vk1pT5VEnwGMkjJyHKuUCkjCQRdKiGYsAZDZD
//EAAdmaA3XprgBAIHL7i8dUBFsgEIwefPfDsdukgpZB0a4OEi3QTh0EeZBx6iTlC3uSrC5YuZBntOQukzMdFdF8sdkNrBDiFrmIMwaYMxW4VwLjmoLr34VUaszZAJ685UwZBVoQqU1ZBWWTBGklZCBN6XdUSBAyX63ZCSV2Iz1Ccv1fz2ZBaZB5cA28IawJYc9DxZA5AZD
//correct
