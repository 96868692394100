import moment from 'moment';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'

export function get_env() {
  const hostname = window.location.hostname
  var env = ""
  if ( hostname == "localhost"|| hostname === 'test.influxio.com') {
    env = "dev"
  } else {
    env = "prod"
  }
  return env
}

export function createDummyHashtagData(hashtagList) {
  var random
  // var difficultyOptions = [
  //   ["danger","hard"],
  //   ["primary","med"],
  //   ["success","easy"],
  // ]
  var difficultyOptions = [
    ["neutral","hard"],
    ["neutral","med"],
    ["neutral","easy"],
  ]
  var categoryOptions = [
    ["sport","fitness", "travel"],
    ["fotography","success", "beauty"],
    ["beauty","fashion", "berlin"],
  ]
  var autoRating = [
    ["danger","avoid"],
    ["primary","neutral"],
    ["success","use"],
  ]
  console.log(random)
  for (const [key, value] of hashtagList.entries()) {
    random = Math.floor(Math.random() * 10)
    value.engagementRate = random
    random = Math.floor(Math.random() * 3)
    value.difficulty = difficultyOptions[random]
    random = Math.floor(Math.random() * 3)
    value.categories = categoryOptions[random]
    random = Math.floor(Math.random() * 3)
    value.autoRating = autoRating[random]
  }
  return hashtagList
}

export function createDummyInfluencerData(hashtagList) {
  var random
  // var difficultyOptions = [
  //   ["danger","hard"],
  //   ["primary","med"],
  //   ["success","easy"],
  // ]
  var difficultyOptions = [
    ["neutral","hard"],
    ["neutral","med"],
    ["neutral","easy"],
  ]
  var categoryOptions = [
    ["sport","fitness", "travel"],
    ["fotography","success", "beauty"],
    ["beauty","fashion", "berlin"],
  ]
  var autoRating = [
    ["danger","avoid"],
    ["primary","neutral"],
    ["success","use"],
  ]
  var isVerified = [
      "true","false"
    ]
  var gender = [
      "male","female"
    ]
  for (const [key, value] of hashtagList.entries()) {
    random = Math.floor(Math.random() * 10)
    value.engagementRate = random
    random = Math.floor(Math.random() * 3)
    value.difficulty = difficultyOptions[random]
    random = Math.floor(Math.random() * 3)
    value.categories = categoryOptions[random]
    random = Math.floor(Math.random() * 3)
    value.autoRating = autoRating[random]
    random = Math.floor(Math.random() * 2)
    value.isVerified = isVerified[random]
    random = Math.floor(Math.random() * 1000000 + 5000)
    value.follower = random
    random = Math.floor(Math.random() * 2000)
    value.following = random
    random = Math.floor(Math.random() * 2)
    value.gender = gender[random]
  }
  return hashtagList
}

export function calculatePostStatistics(postList) {
  var random
  for (var [key, value] of postList.entries()) {
    value = value.node
    var like = value.edge_liked_by.count
    var comment = value.edge_media_to_comment.count
    var created_at = value.taken_at_timestamp
    value.engagementRate = comment * 10 + like
    // var t = new Date( value.created_at );
    // var formatted = t.format("dd.mm.yyyy hh:MM");
    var timestamp = created_at * 1000
    var check = moment(created_at * 1000).format('MM-DD-YYYY');
    value.created = check
    value.from_now = moment(created_at * 1000).startOf('day').fromNow()
  }
  console.log(postList)
  return postList
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function same_day(d1, d2) {
  d1 = new Date(d1)
  d2 = new Date(d2)
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

export function get_time_since(d1, d2) {
  return formatDistance(subDays(new Date(), 5), new Date(), { addSuffix: true })
}
