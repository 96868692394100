
import fetch from 'cross-fetch'


export const REQUEST_POSTS = 'REQUEST_POSTS'
export const RECEIVE_POSTS = 'RECEIVE_POSTS'
export const SELECT_SUBREDDIT = 'SELECT_SUBREDDIT'
export const INVALIDATE_SUBREDDIT = 'INVALIDATE_SUBREDDIT'

export const ADD_USER_SELECTED_HASHTAGS = 'ADD_USER_SELECTED_HASHTAGS'
export const REMOVE_USER_SELECTED_HASHTAGS = 'REMOVE_USER_SELECTED_HASHTAGS'
export const CLEAR_USER_SELECTED_HASHTAGS = 'CLEAR_USER_SELECTED_HASHTAGS'

export const SET_HASHTAG_TO_ADD = 'SET_HASHTAG_TO_ADD'

export const SET_FEED_STATUS = 'SET_FEED_STATUS'

export function setHashtagToAdd(hashtag_to_add) {
  return {
    type: SET_HASHTAG_TO_ADD,
    hashtag_to_add
  }
}

export function setFeedStatus(feedStatus) {
  return {
    type: SET_FEED_STATUS,
    feedStatus
  }
}

export function updateUserSelectedHashtags(payload) {
  return {
    type: ADD_USER_SELECTED_HASHTAGS,
    payload:payload
  }
}

export function removeUserSelectedHashtags(payload) {
  return {
    type: REMOVE_USER_SELECTED_HASHTAGS,
    payload:payload
  }
}

export function clearUserSelectedHashtags(payload) {
  return {
    type: CLEAR_USER_SELECTED_HASHTAGS
  }
}


// --------------------------------------> my code


export function selectSubreddit(subreddit) {
  return {
    type: SELECT_SUBREDDIT,
    subreddit
  }
}

export function invalidateSubreddit(subreddit) {
  return {
    type: INVALIDATE_SUBREDDIT,
    subreddit
  }
}

function requestPosts(subreddit) {
  return {
    type: REQUEST_POSTS,
    subreddit
  }
}

function receivePosts(subreddit, json) {
  return {
    type: RECEIVE_POSTS,
    subreddit,
    posts: json,
    receivedAt: Date.now()
  }
}

function fetchPosts(subreddit) {
  return dispatch => {
    dispatch(requestPosts(subreddit))
    return fetch(`https://influx-io.firebaseio.com/ig/hashtags/data/${subreddit}/related/json_data.json`)
    .then(response => response.json())
    .then(json => dispatch(receivePosts(subreddit, json)))
    .catch(err => {
      console.log("error",err);
    });
  }
}

function shouldFetchPosts(state, subreddit) {
  const posts = state.hashtagByHashtags[subreddit]
  if (!posts) {
    return true
  } else if (posts.isFetching) {
    return false
  } else {
    return posts.didInvalidate
  }
}

export function fetchHashtagsIfNeeded(subreddit) {
  return (dispatch, getState) => {
    if (shouldFetchPosts(getState(), subreddit)) {
      return dispatch(fetchPosts(subreddit))
    }
  }
}
