import DbManager from "../DbManager";
import {
  db_get_instagram_business_account_url,
  db_get_hashtag_id_url,
  db_get_hashtag_feed_url
} from "../dbPaths";
import {
  ig_get_business_discovery_url,
  ig_get_hashtag_id_url,
  ig_get_hashtag_feed_url
} from "../socialUrls";

class RequestManagerInstagram {
  constructor() {
    //this.ig_id = "17841408760193392"
    //this.ig_id = "17841405619328523"
    //this.access_token = `EAAdmaA3XprgBAFC3vIYwXRmSRhSlRwfhSVZCoMnrG7Dun0ujch
    //4g0FkvXznx5Hbf42sGoED4skJOw5e9RodgE6wnlJjGZBwre8RwWZCZAdi9CDfITMAN4BW3dRh
    //ZArhgZCRMjiRZBpxQBHqfnaUu6EZABgPEUOjA8vFHmZANVQWRVoCXaWOECeczS`
    //this.access_token = "EAAdmaA3XprgBAIHL7i8dUBFsgEIwefPfDsdukgpZB0a4OEi3QTh0EeZBx6iTlC3uSrC5YuZBntOQukzMdFdF8sdkNrBDiFrmIMwaYMxW4VwLjmoLr34VUaszZAJ685UwZBVoQqU1ZBWWTBGklZCBN6XdUSBAyX63ZCSV2Iz1Ccv1fz2ZBaZB5cA28IawJYc9DxZA5AZD"
    const one_hour_seconds = 3600
    this.buffer_time = one_hour_seconds*3*1000
  }

  //set_ig_data(ig_id, access_token) {
   // this.ig_id = ig_id
  //  this.access_token = access_token
  //}

  set_buffer_time(buffer_time) {
    this.buffer_time = buffer_time
  }

  async get(db_url, social_url){
    var data = []
    var return_data = []
    const db_manager = new DbManager()
    db_manager.set_url(db_url)
    data = await db_manager.get_data()
    // data = false
    if (data && !this._check_if_refresh(data) ) {
      return_data = data
    } else {
      data = await this._fetch_get(social_url)
      if(data.status == "error") {
        debugger
        return false
      }
      if (data){
        data.timestamp = new Date().getTime()
        db_manager.store_data(data)
        return_data = data
      }
    }
    return return_data
  }

  _check_if_refresh(data) {
    var do_refresh = false
    const recent_timestamp = new Date().getTime()
    if (!data.timestamp) {
      do_refresh = true
    }
    const valid_range = data.timestamp + this.buffer_time
    if (recent_timestamp > valid_range) {
      do_refresh = true
    }
    return do_refresh
  }

  _fetch_get(url) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(response => response.json())
      .then(response1 => this.parse_data(response1))
      .then(parsed_data => resolve(parsed_data))
      .catch(err => {
        debugger
        console.log("error",err);
        resolve(false)
      });
    })
  }

  parse_data(raw_data) {
    return raw_data
  }
}

export default RequestManagerInstagram
