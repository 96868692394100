import moment from 'moment';
class ParserData {
    constructor() {
      this.data = {}
    }

    set_data(raw_json) {
      this.raw_json = raw_json[0]
    }

    parse() {
      var obj= {
        "followers_count" : this.get_followers_count(),
        "profile_picture_url" : this.get_profile_picture_url(),
        "media" : {
          "data" : this._get_media_data(),
          "paging": this._get_media_paging()
        },
        "time_since_posted" : this._get_time_since_posted(),
        "username" : this._get_username()
      }
      this.data = obj
    }

    _parse_media(post) {
      var obj = {
        "caption": this._get_caption(post),
        "comments_count": post.comments_count,
        "id": post.id,
        "like_count": post.like_count,
        "media_type": post.media_type,
        "media_url": post.media_url,
        "owner": {
          "id": post.owner.id
        },
        "permalink": post.permalink,
        "taken_at": {
          "timestamp" : this._get_timestamp(post.timestamp),
          "date" :  this._get_date(post.timestamp),
          "date_short" : this._get_date_short(post.timestamp)
        },
        "timestamp": post.timestamp,
        "username": post.username,
      }
      return obj
    }

    _get_timestamp(date_string) {
      // const new_time = (new Date(date_string).getTime()/1000)
      // given string 2020-12-31T17:44:48+0000
      const new_sting = date_string.replace("+0000","")
      const new_time = Date.parse(new_sting)
      return new_time
      // var myDate1 = Date.parseExact("29-11-2010", "dd-MM-yyyy");
      // var split_array =  = date_string.split(/[^0-9]/);
      // const new_timestamp = split_array.split(/[^0-9]/)
    }

    _get_date(date_string) {
      // const new_time = (new Date(date_string).getTime()/1000)
      // given string 2020-12-31T17:44:48+0000
      return date_string
      const new_sting = date_string.replace("+0000","")
      // const new_time = Date.parse(new_sting)
      // console.log(new_time)
      return new_sting
      // var myDate1 = Date.parseExact("29-11-2010", "dd-MM-yyyy");
      // var split_array =  = date_string.split(/[^0-9]/);
      // const new_timestamp = split_array.split(/[^0-9]/)
    }

    _get_date_short(timestamp) {

    const new_sting = timestamp.replace("+0000","")
    var date = new Date(new_sting);
    var new_date = ((date.getMonth() > 8) ? (date.getMonth() + 1) :
    ('0' + (date.getMonth() + 1))) + '.' + ((date.getDate() > 9) ?
    date.getDate() : ('0' + date.getDate()));
    return new_date

    }

    _get_caption(post) {
      if (!post.caption) {
        return "VIDEO"
      }
      return post.caption
    }

    _get_time_since_posted() {
      const media = this._get_media_data()
      try {
        var last_post = media[0]
        var created_at = last_post.taken_at.timestamp
        const now = Date.now()
        created_at = new Date(created_at * 1000);
        var time_since_posted = (now - created_at) / (1000 * 3600 * 24)
        time_since_posted = Math.round(time_since_posted)
        return time_since_posted
      }
      catch(err) {
        console.log(err)
        return ""
      }
    }
    get_followers_count() {
      const data = this.raw_json
      var return_data = []
      try {
        return_data = data.business_discovery.followers_count
        return return_data
      }
      catch(err) {
        return return_data
      }
    }
    _get_username() {
      const data = this.raw_json
      var return_data = ""
      try {
        return_data = data.business_discovery.username
        return return_data
      }
      catch(err) {
        return return_data
      }
    }
    get_profile_picture_url() {
      const data = this.raw_json
      var return_data = []
      try {
        return_data = data.business_discovery.profile_picture_url
        return return_data
      }
      catch(err) {
        return return_data
      }
    }
    _get_media_data() {
      const data = this.raw_json
      var parsed_media_list = []
      try {
        var media_list = data.business_discovery.media.data
        for (var i = 0; i < media_list.length; i++) {
          parsed_media_list.push(this._parse_media(media_list[i]))
        }
        return parsed_media_list
      }
      catch(err) {
        console.log(err)
        return parsed_media_list
      }
    }
    _get_media_paging() {
      var paging = {}
      try {
        const data = this.raw_json
        paging = data.business_discovery.media.paging
        return paging
      }
      catch(err) {
        console.log(err)
        return paging
      }
    }



}


export default ParserData
