import {
  SET_GEN_DATA_BY_KEY,
} from '../actions/generalActions'

export const default_state = {
  "search_query" : "",
  "search_selection" : "",
}

export function general (state = default_state, action) {
  switch (action.type) {
    case SET_GEN_DATA_BY_KEY:
      var new_state =
      {
        ...state,
          [action.key]: action.payload
      }
      return new_state
    default:
      return state
  }
}
