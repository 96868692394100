import DataManager from "../../helper/DataManager";
import DbManager from "../../helper/DbManager";
import RequestManager from "../../helper/RequestManager";

import {
  db_get_fb_long_term_token_url

} from '../../helper/dbPaths'


import {
  fb_me_url
} from '../../helper/socialUrls'

const data_manager = new DataManager()
const db_manager = new DbManager()
const request_manager = new RequestManager()

export const SET_ACCESS_TOKEN_SHORT = 'SET_ACCESS_TOKEN_SHORT'
export const SET_CONNECT_STATUS = 'SET_CONNECT_STATUS'
export const SET_CONNECT_STEP = 'SET_CONNECT_STEP'
export const DISCONNECT = 'DISCONNECT'


export function validateAccessToken() {
  return async dispatch => {
    var is_valid = false
    db_manager.set_url(db_get_fb_long_term_token_url())
    const data = await db_manager.get_data()
    const fb_access_token = data.access_token
    const url = fb_me_url(fb_access_token)
    const fb_account_data = await request_manager.get_data_local(url)
    if (fb_account_data.id) {
      is_valid = true
    }
    dispatch(setConnectStatus(is_valid))
    return is_valid
  }
}

export function setAccessTokenShort(access_token_short) {
  return {
    type: SET_ACCESS_TOKEN_SHORT,
    access_token_short
  }
}

function setConnectStatus(connect_status) {
  return {
    type: SET_CONNECT_STATUS,
    connect_status
  }
}

export function setConnectStep(connect_step) {
  return {
    type: SET_CONNECT_STEP,
    connect_step
  }
}

export function disconnect() {
  return {
    type: DISCONNECT,
  }
}
