class ParserPixabay {
    constructor() {
      this.obj = {}
      this.feed_group_id = new Date().getTime()
    }

    set_json(raw_json) {
      this.raw_json = raw_json
    }

    set_user_search_query(user_search_query) {
     this.user_search_query = user_search_query
    }

    parse() {
      const raw_media_list = this._get_media_list()
      const media_list = []

      for (var i = 0; i < raw_media_list.length; i++) {
        const parsed_media = this.parse_media(raw_media_list[i])
        media_list.push(parsed_media)
      }
      this.obj = media_list
    }

    _get_media_list() {
      var media_list = []
      try {
        media_list = this.raw_json.hits
        return media_list
      }
      catch(err) {
        console.log(err)
        return media_list
      }
    }

    _get_author(media) {
      const obj = {
        "name" : "NAME",
        "url" : "URL"
      }
      try {
        const user = media.user
        const obj = {
          "name" : user,
          "url" : "https://pixabay.com/users/" + user
        }
        if (user) {
          return obj
        }
      }
      catch(err) {
        console.log(err)
      }
      try {
        const obj = {
          "name" : media.author,
          "url" : "facebook.com"
        }
        return obj
      }
      catch(err) {
        console.log(err)
      }
      return obj
    }
    _get_media(media) {
      var obj = {
        "id" : "ID",
        "large" : "LARGE"
      }
      if (media.id) {
        obj.id = media.id
      } else if (media.db_id){
        obj.id = media.db_id
      }
      if (media.largeImageURL) {
        obj.large = media.largeImageURL
      }
      return obj
    }

    _get_db_id(media) {
      var db_id = "DB_ID"
      try {
        db_id = "pixabay_"+media.id
        return db_id
      }
      catch(err) {
        console.log(err)
        return db_id
      }
    }

    _get_media_type(media) {
      var media_type = "MEDIA_TYPE"
      if (media.type == "photo") {
        media_type = "IMAGE"
      }
      return media_type
    }

    parse_media(media) {
      var obj= {
        "media_source" : "pixabay",
        "author" : this._get_author(media),
        "media" : this._get_media(media),
        "db_id" : this._get_db_id(media),
        "like_count" : media.likes,
        "media_type" : this._get_media_type(media),
        "caption" : media.tags,
        "id" : media.id,
        "media_url" : media.largeImageURL,
        "permalink" : "PERMALINK",
        "timestamp" : new Date().getTime(),
        "user_id" : media.user_id,
        "username" : media.user,
        "feed_entry_ident" : "stock_content_pixabay",
        "feed_entry_id" : media.id,
        "feed_entry_timestamp" : new Date().getTime(),
        "feed_entry_group_id" : this.feed_group_id,
        "feed_entry_user_search_query" : this.user_search_query

      }
      return obj
    }
}


export default ParserPixabay
