// export const ADD_TODO = "ADD_TODO";
// export const TOGGLE_TODO = "TOGGLE_TODO";
// export const SET_FILTER = "SET_FILTER";

const CounterTypes = {
    INCREASE_COUNT: 'INCREASE_COUNT',
    DECREASE_COUNT: 'DECREASE_COUNT',
    RECEIVE_POSTS: 'RECEIVE_POSTS',
    REQUEST_POSTS : 'REQUEST_POSTS'

}

export default {
    ...CounterTypes,
}
