import {
  SET_DATA_DEFAULT,
  SET_DATA,
  SET_DATA_BY_KEY,
  ADD_POST_USER_PUBLISH_QUEUE,
  REMOVE_POST_USER_PUBLISH_QUEUE,
  APPEND_PD_BY_KEY,
  REMOVE_PD_BY_KEY
} from '../actions/publishActions'

export const default_state = {
  "modal_open" : false,
  "preview_open" : false,
  "media_requirements_ok" : true,
  "draft_comment" : "",
  "draft_caption" : "",
  "draft_location" : {
    "facebook_places_id" : "",
    "short_name" : ""
  },
  "draft_tag" : {
    "pk" : "",
    "username" : ""
  },
  "draft_platform_list" : ["instagram"],
  "draft_media_url" : "",
  "draft_media_id" : "",
  "draft_media_obj" : {},
  "draft_media_size" : 0,
  "draft_timestamp" : new Date().getTime() - 100,
  "image_url" : "",
  "timestamp" : new Date().getTime() - 100
}

export function publishData (state = default_state, action) {
  switch (action.type) {
    case SET_DATA_DEFAULT:
      return default_state
    case SET_DATA:
      const merged_state = {...default_state, ...action.payload }
      return merged_state
    case APPEND_PD_BY_KEY:
      return { // returning a copy of orignal state
       ...state, //copying the original state
       [action.key]: state[action.key].concat(action.payload) //new todos array
      }
    case REMOVE_PD_BY_KEY:
       return {  // returning a copy of orignal state
        ...state, //copying the original state
        [action.key]: state[action.key].filter(item => item !== action.payload)
      }
    case SET_DATA_BY_KEY:
      var new_state =
      {
        ...state,
          [action.key]: action.payload
      }
      return new_state
    case ADD_POST_USER_PUBLISH_QUEUE:
      new_state =
        {
          ...state,
            [action.key]: {
                ...state[action.key],
                [action.container_id]: action.user_publish_obj,
          },
        };
      return new_state
    case REMOVE_POST_USER_PUBLISH_QUEUE:
      const delete_state = {...state}
      delete delete_state[action.key][action.container_id]
      return delete_state
    default:
      return state
  }
}
