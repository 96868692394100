import fetch from 'cross-fetch'
import {functions, db} from './firebase/iniFirebase'
import DbManager from "./DbManager";
import axios from 'axios'

import {
  setFeedStatus,
} from '../redux/actions/hashtagActions'

class RequestManager {
    constructor() {
      this.buffer_time = 5*60*60*1000
    }

    set_buffer_time(buffer_time) {
      this.buffer_time = buffer_time
    }

    set_url(url) {
      this.url = url
    }

    async get(db_url, social_url){
      var data = []
      var return_data = []
      const db_manager = new DbManager()
      db_manager.set_url(db_url)
      data = await db_manager.get_data()
      // data = false
      if (data && !this._check_if_refresh(data) ) {
        return_data = data
      } else {
        data = await this._get(social_url)
        if(data.status == "error") {
          debugger
          return false
        }
        if (data){
          data.timestamp = new Date().getTime()
          db_manager.store_data(data)
          return_data = data
        }
      }
      return return_data
    }

    _check_if_refresh(data) {
      var do_refresh = false
      const recent_timestamp = new Date().getTime()
      if (!data.timestamp) {
        do_refresh = true
      }
      const valid_range = data.timestamp + this.buffer_time
      if (recent_timestamp > valid_range) {
        do_refresh = true
      }
      return do_refresh
    }


    _get(data_url) {
      return new Promise((resolve, reject) => {
        axios.get(data_url)
        .then(response => this.parse_response(response))
        .then(response1 => this.parse_data(response1))
        .then(parsed_data => resolve(parsed_data))
        .catch(err => {
          debugger
          console.log("error",err);
          resolve(false)
        });
      })
    }

    parse_response(response) {
      const data = response.data
      return data
    }

    parse_data(raw_data) {
      return raw_data
    }


}


export default RequestManager
