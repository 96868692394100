export const IDENT_UACC_INSTA_NAME = "insta_name"
export const IDENT_UACC_HISTORY_EXPLORE = "history_explore"
export const IDENT_UACC_TODO = "todo"
export const IDENT_UACC_TODO_COUNT = "count"
export const IDENT_UACC_TODO_TIMESTAMP = "timestamp"
export const IDENT_COOKIE_PURCHASE_DATA = "purchase_data"
export const IDENT_COOKIE_PURCHASE_DATA_FILTER_ID = "purchase_data_filter_id"
export const IDENT_COOKIE_PURCHASE_DATA_COMPANY = "purchase_data_company"
export const IDENT_COOKIE_PURCHASE_DATA_ZIP = "purchase_data_zip"
export const IDENT_COOKIE_PURCHASE_DATA_STREET = "purchase_data_street"
export const IDENT_COOKIE_PURCHASE_DATA_CITY = "purchase_data_city"
export const IDENT_COOKIE_PURCHASE_DATA_EMAIL = "purchase_data_email"
