class ParserInstagramUser {
    constructor() {
      this.raw_json = ""
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    get_parsed_data() {
      const user_raw_list = this._get_user_raw_list()
      var user_list = []
      for (var i in user_raw_list) {
        const raw_user = user_raw_list[i]
        const user_obj = this._get_user_obj(raw_user)
        if (user_obj) {
          const parsed_user = this._parse_user(user_obj)
          if (parsed_user) {
            user_list.push(parsed_user)
          }
        }
      }
      if (user_list.length > 0) {
        return user_list
      }
      return false
    }

    _get_user_raw_list() {
      const raw_data = this.raw_json
      try {
        const user_raw_list = raw_data.users
        if (user_raw_list && user_raw_list.length > 0) {
          return user_raw_list
        }
      } catch(err) {
        return false
      }
    }

    _get_user_obj(raw_user_obj) {
      try {
        const user_obj = raw_user_obj.user
        if (user_obj) {
          return user_obj
        }
      } catch(err) {
        return false
      }
    }

    _parse_user(user_obj) {
      var obj = {
        "pk" : user_obj.pk,
        "username" : user_obj.username,
      }
      return obj
    }

}


export default ParserInstagramUser
