// used for instagram_discovery and instagram_hashtag feed, and maybe UserFeedWith Analytics


class ParserInstagramProfileScraper {
    constructor() {
      this.obj = {}
      this.raw_media_list = []
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    get_parsed_data() {
      const check_restricted = this._check_restricted()
      if (check_restricted){
        return false
      }
      const user_data = this._get_user_data()
      const username=user_data.username
      const bio=user_data.biography
      const id=user_data.id
      const post_count=user_data.edge_owner_to_timeline_media.count
      const follower_count=user_data.edge_followed_by.count
      const following_count=user_data.edge_follow.count
      const media_feed = this._get_media_feed(user_data)
      var obj = {
        "username" : username,
        "bio" : bio,
        "id" : id,
        "post_count" : post_count,
        "follower_count" : follower_count,
        "following_count" : following_count,
        "media_feed" : media_feed,
      }
      return obj
    }

    _get_data() {
      return this.raw_json.graphql
    }

    _check_restricted() {
      const data = this._get_data()
      const title = data.title
      if (!title) {
        return false
      }
      if (data.title == "Restricted profile") {
        return true
      }
      return false
    }

    _get_user_data() {
      const data = this._get_data()
      const user_data = data.user
      return user_data
    }

    _get_media_feed(user_data) {
      const raw_media_feed = user_data.edge_owner_to_timeline_media.edges
      const parsed_media_list = this._parse_media(raw_media_feed)
      return parsed_media_list
    }

    _parse_media(raw_media_list) {
      var media_list = []
      for (var i in raw_media_list) {
        var media = raw_media_list[i]
        var parsed_media_obj = this._parse_media_obj(media)
        media_list.push(parsed_media_obj)
      }
      return media_list
    }

    _get_caption(media_obj) {
      var parsed_caption_list = []
      if (!media_obj.edge_media_to_caption){
        return "empty"
      }
      const caption_list = media_obj.edge_media_to_caption.edges
      if (!caption_list) {
        return "empty"
      }
      for (var i in caption_list){
        const caption = caption_list[i].node.text
        if (caption){
          parsed_caption_list.push(caption)
        }
      }
      return parsed_caption_list
    }

    _get_accessibility_caption(media_obj) {
      if (media_obj.accessibility_caption) {
        return media_obj.accessibility_caption
      }
      return "EMPTY"
    }

    _get_location(media_obj) {
      if (media_obj.location) {
        return media_obj.location.name
      }
      return "EMPTY"
    }

    _get_timestamp(media_obj) {
      try {
        if (media_obj.taken_at_timestamp) {
          return media_obj.taken_at_timestamp
        }
      } catch {
        return "EMPTY"
      }
      return "EMPTY"
    }

    _parse_media_obj(media_obj) {
      media_obj = media_obj.node
      var obj = {
        "caption" : this._get_caption(media_obj),
        "accessibility_caption" : this._get_accessibility_caption(media_obj),
        "like_count" : media_obj.edge_liked_by.count,
        "comments_count"  : media_obj.edge_media_to_comment.count,
        "location"  : this._get_location(media_obj),
        "id" : media_obj.id,
        "timestamp" : this._get_timestamp(media_obj),
        "shortcode" : media_obj.shortcode,
      }
      return obj
    }


}

export default ParserInstagramProfileScraper
