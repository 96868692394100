// import ParserData from "../../helper/ParserData";
import DbManager from "../../helper/DbManager";
import { IDENT_USER } from '../constants/ayrshareConstants'

import {
  db_get_ayrshare_url,

} from '../../helper/dbPaths'

import ManagerAyrshare from '../../helper/ayrshare/ManagerAyrshare'
import ParserAyrshare from '../../helper/ayrshare/ParserAyrshare'

const db_manager = new DbManager()
const manager_ayrshare = new ManagerAyrshare()

export const SET_API_KEY = 'SET_API_KEY'
export const SET_AY_DATA_BY_KEY = 'SET_AY_DATA_BY_KEY'

export function setProfileKey(payload) {
  const key = "profile_key"

  return async dispatch => {
    dispatch(storeAyrshareDataByKey(key,payload))
  }
}

export function setAyrshareIsError(payload) {
  const key = "is_error"
  return async dispatch => {
    dispatch(storeAyrshareDataByKey(key,payload))
  }
}

export function setAyrshareErrorMessage(payload) {
  const key = "error_message"
  return async dispatch => {
    dispatch(storeAyrshareDataByKey(key,payload))
  }
}

export function setHistoryDataLoaded(payload) {
  const key = "history_data_loaded"
  return async dispatch => {
    dispatch(storeAyrshareDataByKey(key,payload))
  }
}

export function setRefId(payload) {
  const key = "ref_id"

  return async dispatch => {
    dispatch(storeAyrshareDataByKey(key,payload))
  }
}

export function storeAyrshareDataByKey(key,payload) {
  const obj = {
    [key] : payload
  }
  const url = db_get_ayrshare_url()
  db_manager.set_url(url)
  db_manager.store_data(obj)
  return {
    type: SET_AY_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}

export function setAyrshareDataByKey(key,payload) {
  const obj = {
    [key] : payload
  }
  return {
    type: SET_AY_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}

export function fetchAnalyticsSocial(get_key) {
  const parser = new ParserAyrshare()
  const key = "analytics_social"
  return async (dispatch, getState) => {
    const profile_key = getState().ayrshare.profile_key
    if (!profile_key) {
      return
    }
    // const platforms = getState().ayrshare.user.activeSocialAccounts
    const platforms = ["instagram"]
    manager_ayrshare.set_profile_key(profile_key)
    const payload = await manager_ayrshare.analytics_social(key, platforms)
    if (payload.status == "error") {
      const platform = getState().ayrshare.selected_platform
      parser.set_payload(payload)
      parser.set_platform(platform)
      const parsed_payload = parser.get_parsed_data()
      const error_message = parsed_payload.error_message
      dispatch(setAyrshareErrorMessage(error_message))
      dispatch(setAyrshareIsError(true))
    } else {
      dispatch(setAyrshareDataByKey(key,payload))
    }
  }
}

function fechAnalyticPostList() {
  return (dispatch, getState) => {
    const post_history_list = getState().ayrshare.history
    var promise_list = []
    for (var i in post_history_list) {
      const history_entry = post_history_list[i]
      if (history_entry.status == "success") {
        const promise = dispatch(fetchAnalyticsPost(history_entry.id))
        promise_list.push(promise)
      }
    }
    Promise.all(promise_list.map(p => p.catch(e => e)))
      .then(dispatch(setHistoryDataLoaded(true))) // 1,Error: 2,3
      .catch(e => console.log(e));
  }
}

function fetchAnalyticsPost(post_id) {
  const key = "analytics_post"
  return async (dispatch, getState) => {
    const profile_key = getState().ayrshare.profile_key
    // const platforms = getState().ayrshare.user.activeSocialAccounts
    const platforms = ["instagram"]
    manager_ayrshare.set_profile_key(profile_key)
    const data_obj = {
      "platforms" : platforms,
      "post_id" : post_id
    }
    const resp = await manager_ayrshare.analytics_post(key, data_obj)
    dispatch(mergeAnalyticsPostList(key,post_id, resp))
  }
}

function mergeAnalyticsPostList(key, post_id, resp) {
    const new_obj = {
      [post_id] : resp
    }
    return (dispatch, getState) => {
      const old_object = getState().ayrshare[key]
      const merged_analytic_obj = {...new_obj, ...old_object }
      dispatch(setAyrshareDataByKey(key,merged_analytic_obj))
    }
}

export function fetchHistory() {
  const key = "history"
  return async (dispatch, getState) => {
    const profile_key = getState().ayrshare.profile_key
    // const platforms = getState().ayrshare.user.activeSocialAccounts
    manager_ayrshare.set_profile_key(profile_key)
    const payload = await manager_ayrshare.history(key)
    dispatch(setAyrshareDataByKey(key,payload))
  }
}

export function fetchUser() {
  const key = IDENT_USER
  return async (dispatch, getState) => {
    const profile_key = getState().ayrshare.profile_key
    const manager_ayrshare = new ManagerAyrshare()
    // const platforms = getState().ayrshare.user.activeSocialAccounts
    manager_ayrshare.set_profile_key(profile_key)
    const payload = await manager_ayrshare.user(key)
    // debugger
    dispatch(setAyrshareDataByKey(key,payload))
  }
}

export function fetchAyrshareData() {
  const url = db_get_ayrshare_url()
  db_manager.set_url(url)
  return (dispatch, getState) => {
    db_manager.get_data(url)
    .then(resp => { iniAyrshareData(dispatch,resp)
    })
    .catch(err => {
      console.log("error",err);
    });
  }
}

async function iniAyrshareData(dispatch, resp) {
  if (resp.profile_key && resp.ref_id) {
    await dispatch(setAyrshareDataByKey("profile_key",resp.profile_key))
    await dispatch(setAyrshareDataByKey("ref_id",resp.ref_id))
    await dispatch(fetchUser())
    dispatch(fetchAnalyticsSocial())
    await dispatch(fetchHistory())
    dispatch(fechAnalyticPostList())
  }
}
