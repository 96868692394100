  import {
  SET_OPEN_STATUS,
  SET_WILL_TYPE,
  SET_WILL_DATA,
  SET_WILL_INFO_IDENT,
} from '../actions/willActions'

const initial_state_chat_window = {
  // opened : true,
  opened : false,
  data : {},
  // type: "WillTesting"
  // type: "WillSetIgName"
  // type: "WillChangeIgAccount"
  //type: "WillUpload1"
  // type: "WillInfluencerBible",
  type: "WillTour",
  info_ident: "welcome"
  // type: "WillSelectionView"
  // type: "WillPublishCaption"
  // type: "WillPublishFinish"
  // type: "WillSimple"
  // type: "WillNav"
  // type: "WillGetFeedback"
}

export function chatWindow (state = initial_state_chat_window, action) {
  switch (action.type) {
    case SET_WILL_INFO_IDENT:
      var obj =
      {
        ...state,
          info_ident: action.info_ident
      }
      return obj
    case SET_OPEN_STATUS:
      var obj =
      {
        ...state,
          opened: action.opened
      }
      return obj
    case SET_WILL_TYPE:
      var obj =
      {
        ...state,
          type: action.willType,
          opened: true
      }
      return obj
    case SET_WILL_DATA:
      var obj =
      {
        ...state,
          type: action.willType,
          data: action.data
      }
      return obj
    default:
      return state
  }
}
