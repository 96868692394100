import {
  IDENT_UACC_INSTA_NAME,
  IDENT_UACC_HISTORY_EXPLORE,
  IDENT_UACC_TODO,
  IDENT_UACC_TODO_COUNT,
  IDENT_UACC_TODO_TIMESTAMP,
} from '../constants/accountConstants'

import {
  SET_UACC_DATA_BY_KEY,
  SET_UACC_DATA_DEFAULT,
  ACCOUNT_UPDATE_HISTORY,
  SET_UACC_DATA,
} from '../actions/accountActions'

export const default_state = {
  [IDENT_UACC_INSTA_NAME] : false,
  [IDENT_UACC_HISTORY_EXPLORE] : false,
  [IDENT_UACC_TODO] : {
    [IDENT_UACC_TODO_COUNT] : false,
    [IDENT_UACC_TODO_TIMESTAMP] : false,
  }
}

export function account (state = default_state, action) {
  switch (action.type) {
    case SET_UACC_DATA_DEFAULT:
      return default_state
    case SET_UACC_DATA_BY_KEY:
      var new_state =
      {
        ...state,
          [action.key]: action.payload
      }
      return new_state
    case ACCOUNT_UPDATE_HISTORY:
      const insta_name = Object.keys(action.payload)[0]
      new_state =
        {
          ...state,
            [action.key]: {
                ...state[action.key],
                [insta_name]: action.payload[insta_name],
          },
        };
      return new_state
    case SET_UACC_DATA:
      const merged_state = {...default_state, ...action.payload }
      return merged_state
    default:
      return state
  }
}
