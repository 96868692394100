class ParserInstagramPost {
    constructor() {
      this.raw_json = ""
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    get_parsed_data() {
      const data = this._get_data()
      const username = this._get_username(data)
      const obj = {
        "username" : username,
      }
      return obj
    }

    _get_data() {
      try {
        const data = this.raw_json.graphql.shortcode_media
        if (data) {
          return data
        } else {
          return false
        }
      } catch(err) {
        return false
      }
    }

    _get_username(raw) {
      try {
        const data = raw.owner.username
        if (data) {
          return data
        } else {
          return false
        }
      } catch(err) {
        return false
      }
    }


}


export default ParserInstagramPost
