import DbManager from "./DbManager";
import ScraperapiClient from 'scraperapi-sdk'
import async from 'async'


class RequestManagerScraper {
  constructor() {
    this.buffer_time = 5*60*60*1000
    this.retry_amount = 3
    this.retry_interval = 1000
  }

  set_buffer_time(buffer_time) {
    this.buffer_time = buffer_time
  }

  set_url(url) {
    this.url = url
  }

    async _do_scrape(url,empty){
      const scraperapiClient = new ScraperapiClient("5e328d086c42349bd5bded899ad6a6e4")
      const result = await scraperapiClient.get(url)
      return result
  }

  async get(db_url, social_url){
    var data = []
    var return_data = []
    const db_manager = new DbManager()
    db_manager.set_url(db_url)
    data = await db_manager.get_data()
    // data = false
    if (data && !this._check_if_refresh(data) ) {
      return_data = data
    } else {
      data = await this._api_get(social_url)
      if(data.status == "error") {
        debugger
        return false
      }
      if (data){
        data.timestamp = new Date().getTime()
        db_manager.store_data(data)
        return_data = data
      }
    }
    return return_data
  }

  _check_if_refresh(data) {
    var do_refresh = false
    const recent_timestamp = new Date().getTime()
    if (!data.timestamp) {
      do_refresh = true
    }
    const valid_range = data.timestamp + this.buffer_time
    if (recent_timestamp > valid_range) {
      do_refresh = true
    }
    return do_refresh
  }

  _api_get(social_url) {
    var startDate = new Date();
    this.set_url(social_url)
    // Do your operations
    const obj = this
    return new Promise((resolve, reject) => {
      async.retry(
        {times: this.retry_amount, interval: this.retry_interval},
        this._do_scrape.bind(null, social_url),
        function(err, result) {
          if (err) {
            debugger
            return false
          }
          const parsed_response = obj.parse_response(result, startDate)
          const parsed_data = obj.parse_data(parsed_response)
          if (!parsed_data){
            resolve(false)
          }
          resolve(parsed_data)
      });
    })
  }

  _api_get_old(social_url) {
    var startDate = new Date();
    // Do your operations
    return new Promise((resolve, reject) => {
      const scraperapiClient = new ScraperapiClient("5e328d086c42349bd5bded899ad6a6e4")
      scraperapiClient.get(social_url)
      .then(response => this.parse_response(response, startDate))
      .then(response1 => this.parse_data(response1))
      .then(parsed_data => resolve(parsed_data))
      .catch(err => {
        debugger
        console.log("error",err);
        resolve(false)
      });
    })
  }

  parse_response(response,start_date) {
    const json_obj = JSON.parse(response)
    var endDate   = new Date();
    var seconds = (endDate.getTime() - start_date.getTime()) / 1000;
    console.log("time_elapsed:", seconds)
    return json_obj
  }

  parse_data(raw_data) {
    return raw_data
  }
}

export default RequestManagerScraper
