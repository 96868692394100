// import ParserData from "../../helper/ParserData";
import DbManager from "../../helper/DbManager";
import ManagerSocialAccount from '../../helper/ManagerSocialAccount'
import ManagerClient from "../../helper/ManagerClient";

import {
  CLIENT_PRODUCT_DATA,
  CLIENT_SALES_CONTRACTOR_ORDER_DATA
} from '../constants/socialAccountConstants'

import {
  db_get_social_account_root_url,
  db_get_social_account_url
} from '../../helper/dbPaths'


const db_manager = new DbManager()

export const SET_SA_DATA_BY_KEY = 'SET_SA_DATA_BY_KEY'
export const APPEND_SA_BY_KEY = 'APPEND_SA_BY_KEY'
export const REMOVE_SA_BY_KEY = 'REMOVE_SA_BY_KEY'
export const SET_SA_DATA_DEFAULT = 'SET_SA_DATA_DEFAULT'
export const SET_SA_DATA = 'SET_SA_DATA'
export const SET_SA_PROPERTY_BY_KEY = 'SET_SA_PROPERTY_BY_KEY'

export function setSocialAccountCorrectPromoCode(payload) {
  const key = "correct_promo_code"
  return async dispatch => {
    dispatch(setDataByKey(key,payload))
  }
}

export function setClientProductData(payload) {
  const key = CLIENT_PRODUCT_DATA

  return async dispatch => {
    dispatch(setDataByKey(key,payload,false))
  }
}

export function setClientContractorOrderData(payload) {
  const key = CLIENT_SALES_CONTRACTOR_ORDER_DATA

  return async dispatch => {
    dispatch(setDataByKey(key,payload,false))
  }
}

export function setSocialAccountName(payload) {
  const key = "name"

  return async dispatch => {
    dispatch(setDataByKey(key,payload))
  }
}

export function setSocialAccountUrl(payload) {
  const key = "url"

  return async dispatch => {
    dispatch(setDataByKey(key,payload))
  }
}

export function addMemberToSocialAccount(add_uid, role) {
  const manager = new ManagerSocialAccount()
  const key = "member"
  const payload = {
    "role" : role,
    "joined" : false
  }
  return async (dispatch,getState) => {
    await dispatch(setPropertyByKey(key, add_uid, payload))
    const said = getState().userData.current_social_account_id
    manager.add_user_to_social_account(said, add_uid, role)
  }
}

// export function addMemberToSocialAccount(uid,role) {
//   const key = "member_list"
//   const payload = {
//     "uid" : uid,
//     "role" : role,
//     "joined" : false
//   }
//   return async (dispatch,getState) => {
//     await dispatch(appendDataByKey(key,payload))
//     const data = getState().socialAccount[key]
//     const obj = {
//       [key] : data
//     }
//     const url = db_get_social_account_url()
//     db_manager.set_url(url)
//     db_manager.store_data(obj)
//   }
// }


function setPropertyByKey(key,property, payload) {
  return {
    type: SET_SA_PROPERTY_BY_KEY,
    payload:payload,
    key:key,
    property:property
  }
}


export function removeUserFromSocialAccount(payload) {
  const key = "member_list"

  return async (dispatch,getState) => {
    await dispatch(removeDataByKey(key,payload))
    const data = getState().socialAccount[key]
    const obj = {
      [key] : data
    }
    const url = db_get_social_account_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
}

function appendDataByKey(key,payload, store_db = true) {
  return {
    type: APPEND_SA_BY_KEY,
    payload:payload,
    key:key
  }
}

function removeDataByKey(key,payload, store_db = true) {
  return {
    type: REMOVE_SA_BY_KEY,
    payload:payload,
    key:key
  }
}

function setDataByKey(key,payload, store_db = true) {
  const obj = {
    [key] : payload
  }
  if (store_db) {
    const url = db_get_social_account_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
  return {
    type: SET_SA_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}

export function fetchSocialAccountData() {

  return async dispatch => {
    const url = db_get_social_account_url()
    db_manager.set_url(url)
    const data = await db_manager.get_data()
    if (!data) {
      return dispatch(setDataDefault())
    }
    dispatch(setScocialAccountData(data))
  }
}

function setDataDefault() {
  return {
    type: SET_SA_DATA_DEFAULT,
  }
}

function setScocialAccountData(payload) {
  return {
    type: SET_SA_DATA,
    payload:payload
  }
}
