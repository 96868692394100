import ParserInstagramProfileScraper from './ParserInstagramProfileScraper'
import ParserInstagramProfileRapid from './ParserInstagramProfileRapid'
import ParserInstagramProfileRapidBulkProfileScrapper from './ParserInstagramProfileRapidBulkProfileScrapper'

class ParserInstagramProfile {
    constructor() {
      this.obj = {}
      this.raw_media_list = []
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    get_parsed_data() {
      var parsed_data
      var parser
      const data_source = this._check_data_source()
      switch (data_source) {
        case "scraper":
          parser = new ParserInstagramProfileScraper()
          parser.set_raw_data(this.raw_json)
          parsed_data = parser.get_parsed_data()
          return parsed_data
          break;
        case "rapid":
          parser = new ParserInstagramProfileRapid()
          parser.set_raw_data(this.raw_json)
          parsed_data = parser.get_parsed_data()
          return parsed_data
          break;
        case "rapid_bulk":
          parser = new ParserInstagramProfileRapidBulkProfileScrapper()
          parser.set_raw_data(this.raw_json[0])
          parsed_data = parser.get_parsed_data()
          return parsed_data
          break;
      }
    }

    _check_data_source() {
      if (this.raw_json.graphql){
        return "scraper"
      }
      if (this.raw_json.edge_followed_by || this.raw_json.data.edge_followed_by){
        return "rapid"
      }
      if (this.raw_json[0].edge_followed_by){
        return "rapid_bulk"
      }
    }
}

export default ParserInstagramProfile
