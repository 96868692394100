import { auth } from './firebase/iniFirebase'
import { get_social_account_id } from './iniSocialAccount'
import { encode_insta_name } from './helperFunctions'

import {
  IDENT_UACC_INSTA_NAME,
  IDENT_UACC_HISTORY_EXPLORE
} from '../redux/constants/accountConstants'

// account/payed_filter_data

export function db_get_account_payed_filter_data() {
  const uid = auth().currentUser.uid
  const url = `account/billing/${uid}/payed_filter_data`
  return url
}


//account/company_data

export function db_get_account_company_data() {
  const uid = auth().currentUser.uid
  const url = `account/billing/${uid}/company_data`
  return url
}

// filter suggestion

export function db_get_filter_data_suggestion_url() {
  const url = "filter_data_suggestion"
  return url
}

// ig-company  ---------------------------------->

export function db_get_ig_company_data_url() {
  const url = "ig/company_data/"
  return url
}

// crawler_media  ---------------------------------->

export function db_get_to_crawl_media_list_root_url() {
  const url = `to_crawl_media`
  return url
}

export function db_get_to_crawl_media_list_url(media_list_name) {
  const url = `to_crawl_media/${media_list_name}`
  return url
}

export function db_get_crawler_admin_obj_url() {
  const url = `crawler_admin`
  return url
}

// crawler_media  ---------------------------------->

// db_graph  ---------------------------------->

export function db_get_ig_graph_hashtag_search_url(hashtag) {
  const url = `crawler/graph_hashtag_search/${hashtag}/`
  return url
}

export function db_get_ig_graph_hashtag_feed_url(hashtag_id) {
  const url = `crawler/graph_hashtag_feed/${hashtag_id}/`
  return url
}

// db_graph  ---------------------------------->

// filter  ---------------------------------->

export function db_get_filter_data_root_url() {
  const url = `filter_data`
  return url
}

export function db_get_user_filter_url(username) {
  var encoded_username = encode_insta_name(username)
  const uid = auth().currentUser.uid
  const url = `account/ig/${uid}/filter/${encoded_username}`
  return url
}

export function db_get_filter_data_url(filter_id) {
  const url = `filter_data/${filter_id}`
  return url
}

// filter  ---------------------------------->



// firebase_crawler  ---------------------------------->

export function db_get_crawler_profile_url(insta_name) {
  var new_ident = encode_insta_name(insta_name)
  const url = `crawler/profile/${new_ident}`
  return url
}

export function db_get_crawler_profile_feed_url(insta_name) {
  var new_ident = encode_insta_name(insta_name)
  const url = `crawler/profile_feed/${new_ident}`
  return url
}

export function db_get_crawler_request_url() {
  const url = `crawler_request`
  return url
}

export function db_get_crawler_request_user_url(insta_name) {
  insta_name = encode_insta_name(insta_name)
  const url = `crawler_request/${insta_name}`
  return url
}

export function db_get_crawler_hashtag_feed_url(hashtag) {
  const url = `crawler/hashtag_feed/${hashtag}`
  return url
}


export function db_get_crawler_media_liker_url(media_shortcode, counter) {
  var new_ident = media_shortcode.replaceAll(".",";");
  const url = `crawler/media_liker/${new_ident}/result_${counter}`
  return url
}

export function db_get_crawler_media_liker_root_url(media_shortcode) {
    var new_ident = media_shortcode.replaceAll(".",";");
    var url = `crawler/media_liker/${new_ident}`
    return url
}

export function db_get_crawler_media_comment_url(media_shortcode, counter) {
  var new_ident = media_shortcode.replaceAll(".",";");
  const url = `crawler/media_comment/${new_ident}/result_${counter}`
  return url
}

export function db_get_crawler_media_comment_root_url(media_shortcode) {
    var new_ident = media_shortcode.replaceAll(".",";");
    var url = `crawler/media_comment/${new_ident}`
    return url
}

export function db_get_crawler_user_follower_url(insta_name, counter) {
  var new_ident = encode_insta_name(insta_name)
  const url = `crawler/user_follower/${new_ident}/result_${counter}`
  return url
}

export function db_get_crawler_user_following_url(insta_name, counter) {
  var new_ident = encode_insta_name(insta_name)
  const url = `crawler/user_following/${new_ident}/result_${counter}`
  return url
}

// crawler ---------------------------------->

// firebase_crawler  ---------------------------------->
// influ_list  ---------------------------------->

export function db_get_account_influ_list_url(insta_name) {
  var new_ident = encode_insta_name(insta_name)
  const url = `influ_list/${new_ident}`
  return url
}
// influ_list  ---------------------------------->


export function db_get_influ_list_url() {
  const url = `influ_list`
  return url
}

// influ-list  ---------------------------------->

// influencer  ---------------------------------->

export function db_get_influencer_tag_list_url() {
  const url = "influencer/tag_list"
  return url
}

export function db_get_influencer_category_list_url() {
  const url = "influencer/category_tag_list"
  return url
}

export function db_get_influencer_category_url() {
  const url = `influencer/influencer_category_list/`
  return url
}

// account  ---------------------------------->

export function db_get_account_root_url() {
  const uid = auth().currentUser.uid
  const url = `account/ig/${uid}`
  return url
}

export function db_get_account_key_url(key) {
  const uid = auth().currentUser.uid
  const url = `account/ig/${uid}/${key}`
  return url
}

export function db_get_account_grow_list_filter_url(insta_name) {
  insta_name = encode_insta_name(insta_name)
  const uid = auth().currentUser.uid
  const url = `account/ig/${uid}/influ_list_filter/${insta_name}`
  return url
}

export function db_get_account_black_list_root_url(insta_name) {
  insta_name = encode_insta_name(insta_name)
  const uid = auth().currentUser.uid
  const url = `account/ig/${uid}/black_list/${insta_name}`
  return url
}

export function db_get_account_interacted_list_root_url(insta_name) {
  insta_name = encode_insta_name(insta_name)
  const uid = auth().currentUser.uid
  const url = `account/ig/${uid}/grow_list/${insta_name}`
  return url
}

export function db_get_account_grow_list_url(insta_name, target_name, timstamp) {
  insta_name = encode_insta_name(insta_name)
  target_name = encode_insta_name(target_name)
  const uid = auth().currentUser.uid
  const url = `account/ig/${uid}/grow_list/${insta_name}/${target_name}/${timstamp}`
  return url
}

export function db_get_account_black_list_url(insta_name, target_name, timstamp) {
  insta_name = encode_insta_name(insta_name)
  target_name = encode_insta_name(target_name)
  const uid = auth().currentUser.uid
  const url = `account/ig/${uid}/black_list/${insta_name}/${target_name}/${timstamp}`
  return url
}

// export function db_get_account_insta_name_url(insta_name) {
//   var new_ident = encode_insta_name(insta_name)
//   const url = `account/ig/${IDENT_UACC_INSTA_NAME}/${new_ident}`
//   return url
// }
//
// export function db_get_account_history_explore_url() {
//   const url = `account/ig/${IDENT_UACC_HISTORY_EXPLORE}`
//   return url
// }


// account  ---------------------------------->


// text_similarity ---------------------------------->
export function db_get_text_similarity_url() {
  const url = `analysis/text_similarity`
  return url
}

export function db_get_language_detection_url(ident) {
  const url = `analysis/language_detection/${ident}`
  return url
}
// proxy ---------------------------------->



// proxy ---------------------------------->
export function db_get_base64_data_url(media_id) {
  const url = `media_base_64/${media_id}`
  return url
}
// proxy ---------------------------------->

// coin ---------------------------------->
export function db_get_coin_auth_url(auth_user) {
  const url = `coin/auth/${auth_user}`
  return url
}

export function db_get_coin_ip_url(ip) {
  var new_ident = ip.replaceAll(".",";");
  const url = `coin/ip/${new_ident}`
  return url
}
// coin ---------------------------------->

// ig insight results ---------------------------------->

export function db_get_insight_fan_like_request_data_url(insta_name) {
    var new_ident = encode_insta_name(insta_name)
    new_ident =new_ident.replaceAll(" ","__");
    var url = `queue/fan_like/${new_ident}`
    return url
  }

export function db_get_insight_fan_comment_request_data_url(insta_name) {
    var new_ident = encode_insta_name(insta_name)
    new_ident =new_ident.replaceAll(" ","__");
    var url = `queue/fan_comment/${new_ident}`
    return url
  }

export function db_get_insight_follower_request_data_url(insta_name) {
    var new_ident = encode_insta_name(insta_name)
    new_ident =new_ident.replaceAll(" ","__");
    var url = `queue/follower/${new_ident}`
    return url
  }

export function db_get_profile_follower_follower_list_url(insta_name) {
    const timestamp = new Date().getTime()
    insta_name = encode_insta_name(insta_name)
    const url = `ig/follower_follower/${insta_name}`
    return url
  }
export function db_get_profile_follower_following_list_url(insta_name) {
    const timestamp = new Date().getTime()
    insta_name = encode_insta_name(insta_name)
    const url = `ig/follower_following/${insta_name}`
    return url
  }

export function db_get_insight_fan_data_url(insta_name) {
    var new_ident = encode_insta_name(insta_name)
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/stats_fans/${new_ident}`
    return url
  }

export function db_get_grow_list_new_url(insta_name) {
    const new_ident = encode_insta_name(insta_name)
    const url = `ig/grow_list_new/${new_ident}`
    return url
  }

export function db_get_insight_fan_like_data_url(insta_name) {
    var new_ident = encode_insta_name(insta_name)
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/stats_fans_like/${new_ident}`
    return url
  }

export function db_get_insight_fan_comment_data_url(insta_name) {
    var new_ident = encode_insta_name(insta_name)
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/stats_fans_comment/${new_ident}`
    return url
  }

export function db_get_insight_follower_data_url(insta_name) {
    var new_ident = encode_insta_name(insta_name)
    new_ident =new_ident.replaceAll(" ","__");
    var url = `ig/stats_follower/${new_ident}`
    return url
  }

// ig insight results ---------------------------------->

// marketing_data ---------------------------------->

export function db_get_marketing_account_name_ig_url(account_name) {
    var new_ident = account_name.replaceAll(".",";");
    new_ident =new_ident.replaceAll(" ","__");
    var url = `marketing/account_name_ig/${new_ident}`
    return url
  }

export function db_get_marketing_insta_zoom_input_url(query) {
    var new_query = query.replaceAll(".",";");
    var url = `marketing/insta_zoom_input/${new_query}`
    return url
}

export function db_get_marketing_will_tracking_options_url(option_id) {
    var url = `marketing/will_tracking_options/${option_id}`
    return url
}

export function db_get_marketing_quiz_answers(user_id) {
    var url = `marketing/onboarding_quiz/${user_id}`
    return url
}

// user_personal_data ---------------------------------->

export function db_get_user_root_url() {
    var url = `user/`
    return url
  }

export function   db_get_user_data_url() {
    const user_id = auth().currentUser.uid
    var url = `user/${user_id}`
    return url
  }

export function   db_get_user_data_by_id_url(uid) {
    var url = `user/${uid}`
    return url
  }

// social_account_manageement ---------------------------------->
export function db_get_social_account_root_url() {
    var url = `/social_account`
    return url
}
export function db_get_social_account_url() {
    const said = get_social_account_id()
    var url = `/social_account/${said}`
    return url
}

export function db_get_social_account_data_root_url() {
    var url = `/social_account_data`
    return url
}

export function db_get_social_account_data_url() {
    const said = get_social_account_id()
    var url = `/social_account_data/${said}`
    return url
}

export function db_get_social_account_data_by_id_url(said) {
    var url = `/social_account_data/${said}`
    return url
}

export function db_get_social_account_by_id_url(said) {
    var url = `/social_account/${said}`
    return url
}

export function db_get_social_account_member_url(said,uid) {
    var url = `/social_account/${said}/member`
    return url
}

export function db_get_user_social_account_url() {
  const user_id = auth().currentUser.uid
  var url = `user/${user_id}/social_account`
  return url
}

export function db_get_user_social_account_by_id_url(user_id) {
  var url = `user/${user_id}/social_account`
  return url
}


// client management ---------------------------------->

export function db_get_client_list_url(user_id) {
    var url = `/customer/list/${user_id}`
    return url
}

export function db_get_client_order_url(order_id) {
    var url = `/customer/order/${order_id}`
    return url
}

// client management ---------------------------------->


// Instagram-Inofficial ---------------------------------->

// Instagram-Inofficial ---------------------------------->

export function db_get_ig_bad_media_comment_url(post_id, counter) {
    var url = `ig/bad_media_comment/${post_id}/result_${counter}`
    return url
}


export function db_get_ig_bad_media_comment_root_url(post_id, counter) {
    var url = `ig/bad_media_comment/${post_id}`
    return url
}

export function db_get_ig_bad_profile_follower_url(profile_name, counter) {
      var url = `ig/bad_profile_follower/${profile_name}/result_${counter}`
    return url
}

export function db_get_ig_bad_profile_following_url(profile_name, counter) {
  var new_ident = profile_name.replaceAll(".",";");
  new_ident = new_ident.replaceAll(" ","__");
  var url = `ig/bad_profile_following/${new_ident}/result_${counter}`
  return url
}

export function db_get_ig_bad_profile_follower_root_url(profile_name) {
  var new_ident = profile_name.replaceAll(".",";");
  new_ident = new_ident.replaceAll(" ","__");
  var url = `ig/bad_profile_follower/${new_ident}/`
  return url
}

export function db_get_ig_bad_profile_following_root_url(profile_name) {
  var new_ident = profile_name.replaceAll(".",";");
  new_ident = new_ident.replaceAll(" ","__");
  var url = `ig/bad_profile_following/${new_ident}/`
  return url
}

export function db_get_ig_bad_post_info_url(query) {
    var url = `ig/bad_post_info/${query}`
    return url
}

export function db_get_ig_bad_hashtag_feed_url(hashtag_name) {
    var new_ident = hashtag_name.replaceAll(".",";");
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/bad_hashtag_feed/${new_ident}`
    return url
}

export function db_get_ig_bad_user_metadata_url(username) {
    var new_ident = username.replaceAll(".",";");
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/bad_user_metadata/${new_ident}`
    return url
}

export function db_get_ig_bad_user_stories_url(username) {
    var new_ident = username.replaceAll(".",";");
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/bad_user_stories/${new_ident}`
    return url
}

export function db_get_ig_bad_search_user_url(username) {
    var new_ident = username.replaceAll(".",";");
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/bad_search_user/${new_ident}`
    return url
}

export function db_get_ig_bad_user_reels_url(username) {
    var new_ident = username.replaceAll(".",";");
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/bad_user_reels/${new_ident}`
    return url
}

export function db_get_ig_bad_search_profile_url(profile_name) {
    var new_ident = profile_name.replaceAll(".",";");
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/bad_search_profile/${new_ident}`
    return url
}

export function db_get_ig_search_location_url(query) {
    var new_ident = query.replaceAll(".",";");
    new_ident = new_ident.replaceAll(" ","__");
    var url = `ig/search_location/${new_ident}`
    return url
}

export function db_get_ig_search_hashtag_url(profile_name) {
    var url = `ig/search_hashtag/${profile_name}`
    return url
}

// Instagram ---------------------------------->

export function db_get_instagram_url() {
    const said = get_social_account_id()
    var url = `user/${said}/instagram`
    return url
}

export function db_get_instagram_business_account_url(username) {
    var new_ident = username.replaceAll(".",";");
    var url = `ig/business_account/${new_ident}`
    return url
}

export function db_get_hashtag_id_url(hashtag_name) {
    var url = `ig/hashtag_id/${hashtag_name}`
    return url
}

export function db_get_hashtag_feed_url(hashtag_name) {
    var url = `ig/hashtag_feed/${hashtag_name}`
    return url
}

// Ayrshare ---------------------------------->

export function db_get_ayrshare_url() {
    const said = get_social_account_id()
    var url = `social_account_data/${said}/ayrshare/`
    return url
}
// Target ---------------------------------->

export function db_get_user_target_url() {
    const said = get_social_account_id()
    var url = `social_account_data/${said}/target/`
    return url
}

// Publish  Schedule ---------------------------------->

export function db_get_user_publish_url() {
    const said = get_social_account_id()
    var url = `user/${said}/publish/`
    return url
}

export function db_get_user_as_publish_url() {
    const said = get_social_account_id()
    var url = `social_account_data/${said}/as_publish/`
    return url
}

export function db_get_public_schedule_url(container_id) {
    var url = `schedule/${container_id}`
    return url
}

export function db_get_user_media_schedule_url(container_id) {
    const said = get_social_account_id()
    var url = `user/${said}/publish/queue/${container_id}`
    return url
}

export function db_get_as_public_schedule_url(container_id) {
    var url = `as_schedule/${container_id}`
    return url
}

export function db_get_as_user_media_schedule_url(container_id) {
    const said = get_social_account_id()
    var url = `social_account_data/${said}/as_publish/queue/${container_id}`
    return url
}

// Fb ---------------------------------->
export function   db_get_fb_long_term_token_url() {
    const said = get_social_account_id()
    var url = `user/${said}/fb_user/access_token/long`
    return url
}

export function   db_get_fb_short_term_token_url() {
    const said = get_social_account_id()
    var url = `user/${said}/fb_user/access_token/short`
    return url
}

export function   db_get_fb_account_data_url() {
    const said = get_social_account_id()
    var url = `user/${said}/fb_user/account_data`
    return url
}

export function   db_get_fb_account_pages_metadata_url() {
    const said = get_social_account_id()
    var url = `user/${said}/fb_user/pages_metadata`
    return url
}

export function   db_get_fb_account_pages_accounts_url(page_id) {
    const said = get_social_account_id()
    var url = `user/${said}/fb_user/pages_accounts/${page_id}`
    return url
}

export function   db_get_fb_user_image_url() {
    const said = get_social_account_id()
    var url = `user/${said}/images/fb`
    return url
}


// Fb ---------------------------------->

export function   db_get_willPresetList_user_url() {
    const said = get_social_account_id()
    var url = `social_account_data/${said}/willPreset`
    return url
}
export function   db_get_willPreset_user_url(preset_id) {
    const said = get_social_account_id()
    var url = `social_account_data/${said}/willPreset/${preset_id}`
    return url
}

export function  db_get_hashtag_feed_url_1(ident) {
    var url = `data/hashtag_feed/${ident}`
    return url
  }

export function   db_ig_get_user_profile_url(ident) {
    var new_ident = ident.replaceAll(".",";");
    var url = `ig/user_feed/${new_ident}`
    return url
  }

export function   db_ig_get_user_profile_root_url() {
    var url = `ig/user_feed/`
    return url
  }

export function   db_ig_get_user_feed_rapid_url(ident) {
    var new_ident = ident.replaceAll(".",";");
    var url = `ig/user_feed_rapid/${new_ident}`
    return url
  }

export function   db_get_post_info_url(ident) {
    var url = `data/post_info/${ident}`
    return url
  }
export function   db_get_business_discovery_url(ident) {
    var new_ident = ident.replaceAll(".",";");
    var url = `data/business_discovery/${new_ident}`
    return url
  }
export function   db_get_user_influencer_url(ident) {
    const said = get_social_account_id()
    var url = `user/${said}/${ident}/influencer`
    return url
  }



export function   db_get_selected_acc_url() {
    const said = get_social_account_id()
    var url = `user/${said}/selected_acc`
    return url
  }


export function   db_get_user_accounts_url() {
    const said = get_social_account_id()
    var url = `user/${said}/accounts`
    return url
  }


export function   db_ig_get_users_business_accounts_metadata_url(ig_id) {
    const said = get_social_account_id()
    var url = `user/${said}/ig_data/${ig_id}/account_metadata`
    return url
  }

export function db_ig_get_users_insights_url(ig_id,db_id) {
    const said = get_social_account_id()
    var url = `user/${said}/ig_data/${ig_id}/${db_id}`
    return url
  }

export function db_ig_get_users_media_list_url(ig_id) {
    const said = get_social_account_id()
    var url = `user/${said}/ig_data/${ig_id}/media_list`
    return url
  }

export function db_ig_get_user_id_url(ig_id) {
    const said = get_social_account_id()
    var url = `user/${said}/ig_data/${ig_id}/id`
    return url
  }

export function db_ig_get_insights_media_url(media_id) {
    const said = get_social_account_id()
    var url = `user/${said}/ig_data/insights_media/${media_id}`
    return url
  }


export function db_get_media_query_url(query,page) {
    const said = get_social_account_id()
    var url = `media/${query}/${page}`
    return url
}

export function db_get_user_media_selection_url() {
    const said = get_social_account_id()
    var url = `user/${said}/media/selection`
    return url
}

export function db_get_update_user_media_selection_url(media_source,media_id) {
    const said = get_social_account_id()
    var url = `user/${said}/media/selection/${media_source}_${media_id}`
    return url
  }




export function db_get_user_draft_caption_url(key) {
    const said = get_social_account_id()
    var url = `user/${said}/publish/publish/caption`
    return url
}



export function db_get_will_data_url() {
    const said = get_social_account_id()
    var url = `social_account_data/${said}/will`
    return url
}

// export function db_get_hashtag_feed_url(hashtag_name,type) {
//     var url = `ig/hashtag_feed/${hashtag_name}/${type}/data`
//     return url
// }
export function db_get_hashtag_user_search_url() {
    const said = get_social_account_id()
    var url = `user/${said}/ig_hashtag_searched`
    return url
}

export function db_get_publish_queue() {
    var url = `schedule`
    return url
}

export function db_ig_url(ig_id) {
    const said = get_social_account_id()
    // var url = `user/${said}/media/publish/${media_source}_${media_id}`
    var url = `user/${said}/ig_data/${ig_id}`
    return url
  }

export function db_get_update_user_media_publish_url() {
    const said = get_social_account_id()
    // var url = `user/${said}/media/publish/${media_source}_${media_id}`
    var url = `user/${said}/media/publish/media`
    return url
  }


export function db_get_user_media_publish_url() {
    const said = get_social_account_id()
    var url = `user/${said}/media/publish`
    return url
}

export function db_get_user_feed_inspire_url() {
    const said = get_social_account_id()
    var url = `social_account_data/${said}/feed_inspire`
    return url
}


export function db_get_hashtag_data_url(hashtag_id) {
    var url = `ig/hashtag_data/${hashtag_id}`
    return url
}
