import React, { lazy, Suspense, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch, Route, Redirect
} from 'react-router-dom'
import AccessibleNavigationAnnouncer from
'./components/AccessibleNavigationAnnouncer'
import { auth, db } from "./helper/firebase/iniFirebase";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import './helper/i18n/i18n'


// import './assets/css/duke/menu.css'
import './assets/css/duke/colors.css'
import './assets/css/duke/fonts.css'
import './assets/css/duke/new_main.css'
import './assets/css/duke/new_dashboard.css'

import './assets/css/main.css'
import './assets/css/cards/card_feed.css'


import ParserData from "./helper/ParserData";
import DataManager from "./helper/DataManager";
import DbManager from "./helper/DbManager";
import Loading from "./components/Animations/Loading";
import LoadingFull from "./components/Animations/LoadingFull";
import ManagerSocialAccount from './helper/ManagerSocialAccount'
import ManagerClient from './helper/ManagerClient'
import ManagerProduct from './helper/ManagerProduct'
import ManagerMigrationSocialAccount from './helper/ManagerMigrationSocialAccount'

import {
  db_get_user_data_url,
  db_get_fb_user_image_url,
  db_get_account_root_url
} from "./helper/dbPaths";

import {
  setAuthUser
} from './redux/actions/authActions'

import {
  setSearchQuery, setUserData, setAccountData, fetchAccountData,
  setUserAvatarUrl, fetch_user_data_from_db, iniCustomHashtagList,
  setUserAuthed, setDefaultCurrentSocialAccount, setCurrentSocialAccount,
} from './redux/actions/dashboardUserActions'

import {
  fetchPublishData
} from './redux/actions/publishActions'

import {
  fetchTargetData
} from './redux/actions/targetActions'

import {
  fetchSocialAccountData,
  setClientProductData,
  setClientContractorOrderData
} from './redux/actions/socialAccountActions'

// import {
//   fetchWillPresets
// } from './redux/actions/willPresetActions'

import {
  fetchAyrshareData
} from './redux/actions/ayrshareActions'

import {
  accountFetch,
} from './redux/actions/accountActions'

import {
  fetchBusinessAccountData,
  get_account_name,
  iniHashtagList
} from './redux/actions/instagramActions'

const LayoutPublic = lazy(() => import('./containers/LayoutPublic'))
const Layout = lazy(() => import('./containers/Layout'))
const LayoutSetup = lazy(() => import('./containers/LayoutSetup'))
const LayoutMobile = lazy(() => import('./containers/LayoutMobile'))
const LayoutStorySaver = lazy(() => import('./containers/LayoutStorySaver'))
const LayoutOnboarding = lazy(() => import('./containers/LayoutOnboarding'))
const ErrorBoundary = lazy(() => import('./containers/ErrorBoundary'))
const Login = lazy(() => import('./pages/Login'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))




function SignupRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
            <Redirect to="/" />
          )
      }
    />
  );
}
function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
            <Redirect to="/accounts/login" />
          )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
            <Redirect to="/m/profile" />
          )
      }
    />
  );
}

function App(props,{  }) {

  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const ayrshareData = useSelector(state => state.ayrshare)
  const userData = useSelector(state => state.userData)
  const [cookies, setCookie] = useCookies(["search_count"]);

  const parser_data = new ParserData()
  const data_manager = new DataManager()
  const db_manager = new DbManager()

  useEffect(() => {
    iniAuth()
  }, [])

  //useEffect(() => {
    // setIsLoading(true)
    // const account_name = get_account_name(ayrshareData)
    // if (account_name) {
    //   dispatch(fetchBusinessAccountData(account_name))
    //   setIsLoading(false)
    // }
    // if (ayrshareData.is_error) {
    //   setIsLoading(false)
    // }
  //}, [ayrshareData.analytics_social.instagram])

  function iniAuth() {
    auth().onAuthStateChanged(user => {
      if (user) {
        console.log("user_id:", user.uid)
        dispatch(setAuthUser(user))
        //setIsAuthenticated(true)
        dispatch(setUserAuthed(true))
        iniAccount(user)
        //iniData()
      } else {
        console.log("not authenticated")
        setIsAuthenticated(false)
        setIsLoading(false)
      }
    });
  }

  async function iniAccount(user){
    await storeInstaName(user)
    await dispatch(accountFetch())
    setIsLoading(false)
  }

  async function storeInstaName(user){
    const db_manager = new DbManager()
    const uid = user.uid
    const url = db_get_account_root_url(uid)
    db_manager.set_url(url)
    const data = await db_manager.get_data()
    const insta_name_db = data.insta_name
    if (!insta_name_db){
      const insta_name_cookie = cookies.insta_name
      if (insta_name_cookie){
        const obj = {
          "insta_name" : insta_name_cookie
        }
        await db_manager.store_data(obj)
      }
    }
  }

  async function iniData() {
    console.log("user_id",auth().currentUser.uid)

    await ini_social_account()
    await dispatch(fetchSocialAccountData())
    await set_client_data()
    iniInBackground()
    //const prom_1 = dispatch(fetchWillPresets())
    const prom_2 = dispatch(fetch_user_data_from_db())
    const prom_3 = dispatch(fetchPublishData())
    const prom_4 = dispatch(fetchTargetData())

    //const promise_list = [prom_1, prom_2, prom_3, prom_4]
    const promise_list = [prom_2, prom_3, prom_4]
    Promise.all(promise_list.map(p => p.catch(e => e)))
      .then(results => {
        iniAfterUser()
        setIsLoading(false)
      }) // 1,Error: 2,3
      .catch(e => console.log(e));
  }

  async function set_client_data() {
    const manager_social_account = new ManagerSocialAccount()
    const manager_client = new ManagerClient()
    const manager_product = new ManagerProduct()
    const said = await manager_social_account._get_user_current_account()
    const order_data = await manager_client.get_order_data(said)
    if (!order_data) {
      return
    }
    var product_id = order_data.product_id
    if (product_id == "428010") {
      product_id = 427948
    }
    const product_data = await manager_product.get_product_data(product_id)
    if (product_id) {
      dispatch(setClientContractorOrderData(order_data))
      dispatch(setClientProductData(product_data))
    }
  }

  function iniInBackground() {
    dispatch(fetchAyrshareData())

    //dispatch(iniCustomHashtagList())
  }


  function iniAfterUser() {
    dispatch(setDefaultCurrentSocialAccount())
  }

  async function ini_social_account() {
    const manager_migration = new ManagerMigrationSocialAccount()
    const social_account = new ManagerSocialAccount()
    social_account.auth = auth
    await social_account.ini()
    const social_account_id = social_account.current_account.said
    console.log(social_account_id)
    // const social_account_id = social_account.current_account.said
    await setCookie("social_account_id", social_account_id, {
      path: "/"
    });
    manager_migration.set_said(social_account_id)
    const do_migrate = await manager_migration.check_if_migrate()
    if (do_migrate){
      await manager_migration.migrate_data()
    }
  }

  function render_content() {
    return (
      <>
        <Router>
            <AccessibleNavigationAnnouncer />
            <Switch>
              <SignupRoute
                path="/accounts/create-account"
                authenticated={isAuthenticated}
                component={CreateAccount}
              />
              <SignupRoute
                path="/accounts/login"
                authenticated={isAuthenticated}
                component={Login}
              />
              <Route path="/accounts/forgot-password" component={ForgotPassword} />

              {/* Place new routes over this */}
              {/*
                      comment here
              */}

              <PrivateRoute authenticated={isAuthenticated}
                path="/app" component={Layout}
              />

              <Redirect exact from="/" to="/m" />

              <PublicRoute authenticated={isAuthenticated}
                path="/m" component={LayoutMobile}
              />

              <PublicRoute authenticated={isAuthenticated}
                path="/setup" component={LayoutSetup}
              />


              <PrivateRoute authenticated={isAuthenticated}
                path="/user" component={LayoutOnboarding}
              />



              <PublicRoute authenticated={isAuthenticated}
                path="/storysaver" component={LayoutStorySaver}
              />

              {/*
                  <PublicRoute authenticated={isAuthenticated}
                    path="/" component={LayoutPublic}
                  />
              */}

              <PublicRoute authenticated={isAuthenticated}
                path="/" component={LayoutMobile}
              />

              {/* If you have an index page, you can remothis Redirect */}
              {/* <Redirect exact from="/" to="/login" /> */}
            </Switch>
        </Router>
      </>
    )
  }


  return (
    <>
      {isLoading ? <LoadingFull/> : render_content()}
    </>
  )
}

export default App
