// used for instagram_discovery and instagram_hashtag feed, and maybe UserFeedWith Analytics


class ParserInstagramProfileRapidBulkProfileScrapper {
    constructor() {
      this.obj = {}
      this.raw_media_list = []
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    get_parsed_data() {
      const data = this._get_data()
      const check_restricted = this._check_restricted(data)
      if (check_restricted){
        return false
      }
      if (!data) {
        console.log("no_data")
        return false
      }
      const username=data.username
      const bio=data.biography
      const id=data.pk
      const post_count=data.media_count
      const follower_count=data.follower_count
      const following_count=data.following_count
      const media_feed = this._get_media_feed(data)
      const profile_pic_url = data.profile_pic_url
      const profile_pic_url_hd = this._get_profile_pic_hd(data)
      const is_private = data.is_private
      const is_business_account = data.is_business
      const is_professional_account = data.is_professional
      const full_name = data.full_name
      const category_name = this._get_category_name(data)
      const language = this._get_language(data)
      const metadata = {
        "category_name" : category_name,
        "username" : username,
        "bio" : bio,
        "id" : id,
        "post_count" : post_count,
        "follower_count" : follower_count,
        "following_count" : following_count,
        "profile_pic_url" : profile_pic_url,
        "profile_pic_url_hd" : profile_pic_url_hd,
        "is_private" : is_private,
        "is_business_account" : is_business_account,
        "is_professional_account" : is_professional_account,
        "full_name" : full_name,
        "language" : language,
      }
      const obj = {
        "metadata" : metadata,
        "media_list" : media_feed
      }

      return obj
    }

    _get_language(data) {
      if (data.language){
        return data.language
      }
      return "EMPTY"
    }

    _get_category_name(data) {
      if (data.category){
        return data.category
      }
      return "EMPTY"
    }

    _get_data() {
      const first_entry = this.raw_json[0]
      if (first_entry){
        return first_entry
      }
      const data =  this.raw_json
      if (data){
        return data
      }
      return false
    }

    _check_restricted(data) {
      const title = data.title
      if (!title) {
        return false
      }
      if (data.title == "Restricted profile") {
        return true
      }
      return false
    }

    _get_profile_pic_hd(data) {
      if (!data.profile_pic_url_hd){
        return "EMPTY"
      }
      return data.profile_pic_url_hd
    }

    _get_media_feed(user_data) {
      if (!user_data.feed){
        return "EMPTY"
      }
      const raw_media_feed = user_data.feed.data
      const parsed_media_list = this._parse_media(raw_media_feed)
      return parsed_media_list
    }

    _parse_media(raw_media_list) {
      var media_list = []
      for (var i in raw_media_list) {
        var media = raw_media_list[i]
        var parsed_media_obj = this._parse_media_obj(media)
        media_list.push(parsed_media_obj)
      }
      return media_list
    }

    _get_accessibility_caption(media_obj) {
      if (media_obj.accessibility_caption) {
        return media_obj.accessibility_caption
      }
      return "EMPTY"
    }

    _get_location(media_obj) {
      if (media_obj.location) {
        return media_obj.location.name
      }
      return "EMPTY"
    }

    _get_grid_url(media_obj) {
      const image_version2 = media_obj.image_versions2
      if (!image_version2) {
        return "EMPTY"
      }
      const candidates = image_version2.candidates
      if (!candidates) {
        return "EMPTY"
      }
      const candidate_4 = candidates[4]
      if (!candidate_4) {
        return "EMPTY"
      }
      const url = candidate_4.url
      if (url) {
        return url
      }
      return "EMPTY"
    }

    _get_media_type(media_obj) {
      const media_typename = media_obj.media_type
      if (!media_typename){
        return "EMPTY"
      }
      const product_type = media_obj.product_type
      if (media_typename == 2 && product_type == "igtv"){
        return "video"
      }
      if (media_typename == 2 && product_type == "feed"){
        return "video"
      }
      if (media_typename == 2 && product_type == "clips"){
        return "reel"
      }
      if (media_typename == 1){
        return "image"
      }
      if (media_typename == 8){
        return "carousel"
      }
      return "EMPTY"
    }

    _get_media_url(media_obj) {
      const media_type = media_obj.media_type
      var data
      if (media_type == 2){
        data = media_obj.video_versions[0].url
        if (data) {
          return data
        }
      }
      if (media_type == 1){
        data = media_obj.image_versions2.candidates[0].url
        if (data) {
          return data
        }
      }
      if (media_type == 8 ){
        data = media_obj.carousel_media[0].image_versions2.candidates[0].url
        if (data) {
          return data
        }
      }
      return "EMPTY"
    }

    _get_timestamp(media_obj) {
      try {
        if (media_obj.taken_at_timestamp) {
          return media_obj.taken_at_timestamp
        }
      } catch {
        return "EMPTY"
      }
      return "EMPTY"
    }

    _get_caption(media_obj) {
      try {
        if (media_obj.caption.text) {
          return media_obj.caption.text
        }
      } catch {
        return "EMPTY"
      }
      console.log("no caption")
      return "EMPTY"
    }

    _parse_media_obj(media_obj) {
      var obj = {
        "taken_at_timestamp" : media_obj.taken_at,
        "caption" : this._get_caption(media_obj),
        "like_count" : media_obj.like_count,
        "comments_count"  : media_obj.comment_count,
        "location"  : this._get_location(media_obj),
        "id" : media_obj.id,
        "timestamp" : media_obj.taken_at,
        "url" : "not_set",
        "shortcode" : media_obj.code,
        "media_type" : this._get_media_type(media_obj),
        "grid_url" : this._get_grid_url(media_obj),
        "media_url" : this._get_media_url(media_obj),
      }
      return obj
    }


}

export default ParserInstagramProfileRapidBulkProfileScrapper
