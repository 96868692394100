const graph_api_access_token = "EAAdmaA3XprgBAJg1IDteTuZCl7y7c8UFBFXyzdU2W0WgBWwvdRTgTCsADhgd66NmfpJp51ijo5SDLOD7AqPfWGfZADKLGwBLbPOAvSzGzSjy7KjR1UIsWuxlBZCL1E9ozzUtoBpsRbUSx5RaInWAdbk8rGEht1AqIVMQRkYbQZDZD"
const business_discovery_media_limit = "50"
const user_account_id = "17841405619328523"
const client_secret = "e73cbb340858ebe3104494ce7897d4ae"
const app_id = "2082921931843256"
const api_version = "v11.0"




// Instagram-Inofficial ---------------------------------->
// user/ place / hashtag
export function get_ig_search_hashtag_url(query) {
    var url = `https://www.instagram.com/web/search/topsearch/?context=hashtag&query=${query}`
    return url
  }

export function get_ig_search_user_url(query) {
    var url = `https://www.instagram.com/web/search/topsearch/?context=user&query=${query}`
    return url
  }

export function get_ig_search_location_url(query) {
    var url = `https://www.instagram.com/web/search/topsearch/?context=place&query=${query}`
    return url
  }

export function  ig_bad_get_hashtag_feed_url(ident) {
    // var url = `https://www.instagram.com/explore/tags/${ident}/?__a=1`
    var url = `https://www.instagram.com/explore/tags/${ident}/?__a=1`
    return url
  }
export function   ig_get_user_profile_url(ident) {
    var url = `https://www.instagram.com/${ident}/?__a=1`
    return url
  }
export function   ig_get_post_info_url(ident) {
    var url = `https://www.instagram.com/p/${ident}/?__a=1`
    return url
  }

// Fb ---------------------------------->

export function   fb_me_url(access_token) {
  var url = `https://graph.facebook.com/${api_version}/me?access_token=${access_token}`
  return url
}

export function   ig_get_long_term_token_url(access_token) {
    var url = `https://graph.facebook.com/${api_version}/oauth/access_token?grant_type=fb_exchange_token&client_id=${app_id}&client_secret=${client_secret}&fb_exchange_token=${access_token}`
    return url
}

// Fb ---------------------------------->

// instagram ---------------------------------->

export function   ig_get_hashtag_id_url(ig_id, query, access_token) {
    var url = `https://graph.facebook.com/${api_version}/ig_hashtag_search?user_id=${ig_id}&q=${query}&access_token=${access_token}`
    return url
  }

export function   ig_get_hashtag_feed_url(hashtag_id, ig_id, type, after, access_token) {
    // type: top/recent
    // after: can be empty
    var url = `https://graph.facebook.com/${api_version}/${hashtag_id}/${type}_media?user_id=${ig_id}&fields=caption,comments_count,id,like_count,media_type,permalink,media_url,timestamp&after=${after}&limit=50&access_token=${access_token}`
    return url
}


// ig_ini ---------------------------------->
export function   ig_get_hashtag_data_url(hashtag_id, access_token) {
    var url = `https://graph.facebook.com/${api_version}/${hashtag_id}?fields=id,name&access_token=${access_token}`
    return url
  }


export function   ig_get_users_id_url(ig_id, access_token) {
    var url = `https://graph.facebook.com/${api_version}/${ig_id}/?fields=ig_id&access_token=${access_token}`
    return url
  }
// ig_ini ---------------------------------->



export function   ig_get_business_discovery_url1(ident) {
    var url = `https://graph.facebook.com/v15.0/oauth/access_token?client_id=2082921931843256&redirect_uri=https://influxio.com/&client_secret=e73cbb340858ebe3104494ce7897d4ae`
    return url
  }



  export function   ig_get_business_discovery_url(ig_id, query, access_token) {
      var url = `https://graph.facebook.com/v10.0/${ig_id}?fields=business_discovery.username(${query}){biography,id,ig_id,followers_count, follows_count, media_count,name, profile_picture_url, username,website,media.limit(${business_discovery_media_limit}){caption,comments_count,id,media_type,media_url,like_count,owner,permalink,timestamp,username}}&access_token=${access_token}`
      return url
    }

  export function   ig_get_user_pages_url(access_token) {
      var url = `https://graph.facebook.com/${api_version}/me/accounts?access_token=${access_token}`
      return url
    }

  export function   ig_get_page_business_account_url(page_id, access_token) {
      var url = `https://graph.facebook.com/${api_version}/${page_id}?fields=instagram_business_account&access_token=${access_token}`
      return url
    }
  export function   ig_get_users_business_account_metadata_url(ig_id, access_token) {
      var url = `https://graph.facebook.com/${api_version}/${ig_id}?fields=biography,id,username,website,profile_picture_url, followers_count, media_count&access_token=${access_token}`
      return url
    }

  export function   ig_get_users_insights_url(ig_id, metric, period, since, until,access_token) {
      var url = `https://graph.facebook.com/${api_version}/${ig_id}/insights?metric=${metric}&period=${period}&since=${since}&until=${until}&access_token=${access_token}`
      return url
    }

  export function   ig_get_insights_media_url(media_id, metric, access_token) {
      var url = `https://graph.facebook.com/${api_version}/${media_id}/insights?metric=${metric}&period=lifetime&access_token=${access_token}`
      return url
    }
  export function   ig_get_users_media_list_url(ig_id, access_token) {
      var url = `https://graph.facebook.com/${api_version}/${ig_id}/media?fields=media_type,timestamp&access_token=${access_token}`
      return url
    }

  export function   ig_post_media_container_create_url(ig_id, image_url, caption, access_token) {
      var url = `https://graph.facebook.com/${api_version}/${ig_id}/media?image_url=${image_url}&caption=${caption}&access_token=${access_token}`
      return url
    }

  export function   ig_post_media_container_publish_url(ig_id, creation_id, access_token) {
      var url = `https://graph.facebook.com/${api_version}/${ig_id}/media_publish?creation_id=${creation_id}&access_token=${access_token}`
      return url
    }
  export function   ig_post_media_container_draft_url(ig_id, access_token) {
      var url = `https://graph.facebook.com/${api_version}/${ig_id}/media_publish?access_token=${access_token}&creation_id=`
      return url
    }

  export function   get_fb_user_image_url(fb_id, access_token) {
      var url = `https://graph.facebook.com/${api_version}/${fb_id}/picture?redirect=0&access_token=${access_token}`
      return url
    }



  export function   get_pixabay_media_url(query, page) {
      const pixabay_access_token = "4422002-f7aafc04c929f3320e1e2fd06"
      const image_type = "photo"
      const orientation = "horizontal"
      const min_width = "1080"
      const min_height = "608"
      const editors_choice = "false"
      const per_page = "50"
      const language = "de"
      // Accepted values: cs, da, de, en, es, fr, id, it, hu, nl, no, pl, pt, ro, sk, fi, sv, tr, vi, th, bg, ru, el, ja, ko, zh
      var url = `https://pixabay.com/api/?key=${pixabay_access_token}&q=${query}&image_type=photo&page=${page}&per_page=${per_page}&image_type=${image_type}&orientation=${orientation}&min_width=${min_width}&min_height=${min_height}&editors_choice=${editors_choice}&lang=${language}`
      console.log(url)
      return url
    }



  export function   ig_get_hashtag_user_search_url(ig_id, access_token) {
      var url = `https://graph.facebook.com/${ig_id}/recently_searched_hashtags?limit=30&access_token=${access_token}`
      return url
    }
