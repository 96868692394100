import {
  SET_AUTH_DATA_BY_KEY,
} from '../actions/authActions'

export const default_state = {
  "user" : null,
}

export function auth (state = default_state, action) {
  switch (action.type) {
    case SET_AUTH_DATA_BY_KEY:
      var new_state =
      {
        ...state,
          [action.key]: action.payload
      }
      return new_state
    default:
      return state
  }
}
