// import ParserData from "../../helper/ParserData";
import DbManager from "../../helper/DbManager";

import {
  db_get_instagram_url,
} from '../../helper/dbPaths'

import RequestManagerInstagram
  from '../../helper/instagram/ManagerInstagramOfficial'
import SocialMediaCrawler from '../../helper/SocialMediaCrawler'

const db_manager = new DbManager()
const manager_request_instagram = new RequestManagerInstagram()

export const SET_IG_DATA_BY_KEY = 'SET_IG_DATA_BY_KEY'

export function storeInstagramDataByKey(key,payload) {
  const obj = {
    [key] : payload
  }
  const url = db_get_instagram_url()
  db_manager.set_url(url)
  db_manager.store_data(obj)
  return {
    type: SET_IG_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}

export function setInstagramDataByKey(key,payload) {
  const obj = {
    [key] : payload
  }
  return {
    type: SET_IG_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}
export function get_account_name(ayrshareData) {
  try {
    const analytics_instagram = ayrshareData.analytics_social.instagram
    const insta_name = analytics_instagram.analytics.username
    return insta_name
  } catch {
    return false
  }
}

export function fetchBusinessAccountData(account_name) {
  const key = "business_account"
  return async (dispatch, getState) => {
    manager_request_instagram.set_buffer_time(5*60*1000)
    const payload = await manager_request_instagram.business_discovery(account_name)
    dispatch(setInstagramDataByKey(key,payload))
  }
}
