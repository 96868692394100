import DataManager from "../../helper/DataManager";
import DbManager from "../../helper/DbManager";
import RequestManager from "../../helper/RequestManager";
import ManagerAyrshare from "../../helper/ayrshare/ManagerAyrshare";

import {
  db_get_user_as_publish_url,
  db_get_as_user_media_schedule_url,
  db_get_as_public_schedule_url
} from '../../helper/dbPaths'

const data_manager = new DataManager()
const db_manager = new DbManager()
const request_manager = new RequestManager()


export const SET_DATA = 'SET_DATA'
export const SET_DATA_BY_KEY = 'SET_DATA_BY_KEY'
export const ADD_POST_USER_PUBLISH_QUEUE = 'ADD_POST_USER_PUBLISH_QUEUE'
export const SET_DATA_DEFAULT = 'SET_DATA_DEFAULT'
export const REMOVE_POST_USER_PUBLISH_QUEUE = 'REMOVE_POST_USER_PUBLISH_QUEUE'
export const APPEND_PD_BY_KEY = 'APPEND_PD_BY_KEY'
export const REMOVE_PD_BY_KEY = 'REMOVE_PD_BY_KEY'

export function fetchPublishData() {

  return async dispatch => {
    const url = db_get_user_as_publish_url()
    db_manager.set_url(url)
    const publish_data = await db_manager.get_data()
    if (!publish_data) {
      return dispatch(setPublishDataDefault())
    }
    dispatch(setPublishData(publish_data))
  }
}

function setPublishData(payload) {
  return {
    type: SET_DATA,
    payload:payload
  }
}
function setPublishDataDefault() {
  return {
    type: SET_DATA_DEFAULT,
  }
}

export function replaceDraftWithCalendarObj(cal_obj) {
  return async dispatch => {
    dispatch(setPublishDataByKey("draft_media_obj",cal_obj.draft_media_obj))
    dispatch(setPublishDataByKey("draft_media_url",cal_obj.draft_media_url))
    dispatch(setPublishDataByKey("draft_media_id",cal_obj.draft_media_id))
    dispatch(setPublishDataByKey("draft_timestamp",cal_obj.draft_timestamp))
    dispatch(setPublishDataByKey("draft_caption",cal_obj.draft_caption))
  }
}

export function removeDraftMedia(media_id) {
  db_manager.remove_file(media_id)
  return async dispatch => {
    dispatch(setPublishDataByKey("draft_media_obj",{}))
    dispatch(setPublishDataByKey("draft_media_url",""))
    dispatch(setPublishDataByKey("draft_media_id",""))
  }
}

export function removeDraftMediaNotFile() {
  return async dispatch => {
    dispatch(setPublishDataByKey("draft_media_obj",{}))
    dispatch(setPublishDataByKey("draft_media_url",""))
    dispatch(setPublishDataByKey("draft_media_id",""))
  }
}

export function setDraftMediaSize(payload) {
  const key = "draft_media_size"
  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setDraftComment(payload) {
  const key = "draft_comment"
  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setDraftLocation(payload) {
  const key = "draft_location"
  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setDraftPlatformList(payload) {
  const key = "draft_platform_list"
  const obj = {
    [key] : payload
  }
  const url = db_get_user_as_publish_url()
  db_manager.set_url(url)
  db_manager.store_data(obj)
}

export function appendDraftPlatformList(payload) {
  const key = "draft_platform_list"
  return async (dispatch,getState) => {
    await dispatch(appendPublishDataByKey(key,payload))
    const data = getState().publishData.draft_platform_list
    const obj = {
      [key] : data
    }
    const url = db_get_user_as_publish_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
}

export function removeDraftPlatformList(payload) {
  const key = "draft_platform_list"
  return async (dispatch,getState) => {
    await dispatch(removePublishDataByKey(key,payload))
    const data = getState().publishData.draft_platform_list
    const obj = {
      [key] : data
    }
    const url = db_get_user_as_publish_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
}

export function setDraftTag(payload) {
  const key = "draft_tag"
  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setMediaRequirementsOk(payload) {
  const key = "media_requirements_ok"

  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setPublishPreviewOpen(payload) {
  const key = "preview_open"

  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setPublishModalOpen(payload) {
  const key = "modal_open"

  return async dispatch => {
    //TODO can be removed after some time, prevent that
    // stays open all the time for some users
    if (payload == false) {
      dispatch(setPublishDataByKey(key,payload))
    } else {
      dispatch(setPublishDataByKey(key,payload,false))
    }
  }
}

export function setDraftTimestamp(payload) {
  const key = "draft_timestamp"

  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setDraftCaption(payload) {
  const key = "draft_caption"

  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setDraftMediaObj(payload) {
  const key = "draft_media_obj"

  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setDraftMediaUrl(payload) {
  const key = "draft_media_url"

  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

export function setDraftMediaId(payload) {
  const key = "draft_media_id"

  return async dispatch => {
    dispatch(setPublishDataByKey(key,payload))
  }
}

function setPublishDataByKey(key,payload, store_db = true) {
  const obj = {
    [key] : payload
  }
  if (store_db) {
    const url = db_get_user_as_publish_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
  return {
    type: SET_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}

function appendPublishDataByKey(key,payload, store_db = true) {
  const obj = {
    [key] : payload
  }
  // if (store_db) {
  //   const url = db_get_user_as_publish_url()
  //   db_manager.set_url(url)
  //   db_manager.store_data(obj)
  // }
  return {
    type: APPEND_PD_BY_KEY,
    payload:payload,
    key:key
  }
}

function removePublishDataByKey(key,payload, store_db = true) {
  const obj = {
    [key] : payload
  }
  // if (store_db) {
  //   const url = db_get_user_as_publish_url()
  //   db_manager.set_url(url)
  //   db_manager.store_data(obj)
  // }
  return {
    type: REMOVE_PD_BY_KEY,
    payload:payload,
    key:key
  }
}

export function addToPublishQueue(container_id,
    global_publish_obj, user_publish_obj) {
  // no redux needed
  const db_public_schedule_url = global_publish_obj.db_public_schedule_url
  const db_publish_url = global_publish_obj.db_user_schedule_url
  user_publish_obj.schedule_timestamp = global_publish_obj.timestamp
  db_manager.set_url(db_public_schedule_url)
  db_manager.store_data(global_publish_obj)
  return async dispatch => {
    dispatch(addPostUserPublishQueue(
      container_id, db_publish_url, user_publish_obj
    )
    )
  }
}

function addPostUserPublishQueue(container_id, db_publish_url, user_publish_obj) {
  const key = "queue"
  const new_user_publish_obj = {
    "draft_media_obj" : user_publish_obj.draft_media_obj,
    "draft_media_url" : user_publish_obj.draft_media_url,
    "draft_media_id" : user_publish_obj.draft_media_id,
    "draft_timestamp" : user_publish_obj.draft_timestamp,
    "draft_caption" : user_publish_obj.draft_caption,
  }
  const url = db_publish_url
  db_manager.set_url(url)
  db_manager.store_data(new_user_publish_obj)
  return {
    type: ADD_POST_USER_PUBLISH_QUEUE,
    container_id : container_id,
    user_publish_obj : new_user_publish_obj,
    key : key
  }
}

export function removeFromPublishQueue(container_id) {
  const public_scheduel_url = db_get_as_public_schedule_url(container_id)
  const user_schedule_url = db_get_as_user_media_schedule_url(container_id)
  db_manager.set_url(public_scheduel_url)
  db_manager.remove_data()
  db_manager.set_url(user_schedule_url)
  db_manager.remove_data()
  return async (dispatch) => {
    dispatch(ayrshareRemoveFromQueue(container_id))
    dispatch(removePostUserPublishQueue(container_id))
  }
}

function removePostUserPublishQueue(container_id) {
  const key = "queue"
  return  {
    type: REMOVE_POST_USER_PUBLISH_QUEUE,
    container_id : container_id,
    key : key
  }
}

function ayrshareRemoveFromQueue(post_id) {
  return async (dispatch, getState) => {
    const profile_key = getState().ayrshare.profile_key
    const manager_ayrshare = new ManagerAyrshare()
    manager_ayrshare.set_profile_key(profile_key)
    const resp = await manager_ayrshare.delete(post_id)
  }
}
