import RequestManagerScraper from './RequestManagerScraper'
import ParserInstagramMedia from "./ParserInstagramMedia";
import ParserInstagramLocation from "./ParserInstagramLocation";
import ParserInstagramUser from "./ParserInstagramUser";
import ParserInstagramSearchHashtag from "./ParserInstagramSearchHashtag";
import ParserInstagramProfile from "./ParserInstagramProfile";
import ParserInstagramPost from "./ParserInstagramPost";

import { db_get_ig_bad_hashtag_feed_url } from './dbPaths'
import { ig_bad_get_hashtag_feed_url } from './socialUrls'

import { db_get_ig_search_user_url } from './dbPaths'
import { get_ig_search_user_url } from './socialUrls'

import { db_get_ig_search_hashtag_url } from './dbPaths'
import { get_ig_search_hashtag_url } from './socialUrls'

import { db_get_ig_search_location_url } from './dbPaths'
import { get_ig_search_location_url } from './socialUrls'

import { db_get_ig_bad_search_profile_url } from './dbPaths'
import { ig_get_user_profile_url } from './socialUrls'

import { db_get_ig_bad_post_info_url } from './dbPaths'
import { ig_get_post_info_url } from './socialUrls'

class SocialMediaCrawler {
    constructor() {
      const scraper = new RequestManagerScraper()
      this.scraper = scraper
    }

    set_buffer_time(buffer_time) {
      this.scraper.set_buffer_time(buffer_time)
    }

    async instagram_tag_feed(tag) {
      const parser = new ParserInstagramMedia()
      const db_url = db_get_ig_bad_hashtag_feed_url(tag)
      const social_url = ig_bad_get_hashtag_feed_url(tag)
      const data = await this.scraper.get(db_url, social_url)
      if (data) {
        parser.set_raw_data(data)
        const parsed_data = parser.get_parsed_media_list()
        if (parsed_data) {
          return parsed_data
        }
      }
      return false
    }

    async instagram_search_user(user) {
      const parser = new ParserInstagramUser()
      const db_url = db_get_ig_bad_search_profile_url(user)
      const social_url = get_ig_search_user_url(user)
      const data = await this.scraper.get(db_url, social_url)
      if (data) {
        parser.set_raw_data(data)
        const parsed_data = parser.get_parsed_data()
        if (parsed_data) {
          return parsed_data
        }
      }
      return false
    }

    async instagram_search_hashtag(hashtag) {
      const parser = new ParserInstagramSearchHashtag()
      const db_url = db_get_ig_search_hashtag_url(hashtag)
      const social_url = get_ig_search_hashtag_url(hashtag)
      const data = await this.scraper.get(db_url, social_url)
      if (data) {
        parser.set_raw_data(data)
        const parsed_data = parser.get_parsed_data()
        if (parsed_data) {
          return parsed_data
        }
      }
      return false
    }

    async instagram_search_location(location) {
      const parser = new ParserInstagramLocation()
      const db_url = db_get_ig_search_location_url(location)
      const social_url = get_ig_search_location_url(location)
      const data = await this.scraper.get(db_url, social_url)
      if (data) {
        parser.set_raw_data(data)
        const parsed_data = parser.get_parsed_data()
        if (parsed_data) {
          return parsed_data
        }
      }
      return false
    }

    async instagram_profile(user) {
      const parser = new ParserInstagramProfile()
      const db_url = db_get_ig_bad_search_profile_url(user)
      const social_url = ig_get_user_profile_url(user)
      const data = await this.scraper.get(db_url, social_url)
      if (data) {
        parser.set_raw_data(data)
        const parsed_data = parser.get_parsed_data()
        if (parsed_data) {
          return parsed_data
        }
      }
      return false
    }

    async instagram_post_data(query) {
      const parser = new ParserInstagramPost()
      const db_url = db_get_ig_bad_post_info_url(query)
      const social_url = ig_get_post_info_url(query)
      const data = await this.scraper.get(db_url, social_url)
      if (data) {
        parser.set_raw_data(data)
        const parsed_data = parser.get_parsed_data()
        if (parsed_data) {
          return parsed_data
        }
      }
      return false
    }

}


export default SocialMediaCrawler
