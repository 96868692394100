import DbManager from './DbManager'
import RequestManager from './RequestManager'

import { db_ig_get_user_profile_url } from './dbPaths'
import { ig_get_user_profile_url } from './socialUrls'
import { db_get_post_info_url } from './dbPaths'
import { ig_get_post_info_url } from './socialUrls'

import { db_get_business_discovery_url } from './dbPaths'
import { ig_get_business_discovery_url } from './socialUrls'

import { db_get_fb_long_term_token_url } from './dbPaths'
import { ig_get_long_term_token_url } from './socialUrls'
import { db_get_fb_account_pages_metadata_url } from './dbPaths'
import { ig_get_user_pages_url } from './socialUrls'
import { db_get_fb_account_pages_accounts_url } from './dbPaths'
import { ig_get_page_business_account_url } from './socialUrls'
import { db_ig_get_users_business_accounts_metadata_url } from './dbPaths'
import { ig_get_users_business_account_metadata_url } from './socialUrls'

import { db_get_media_query_url } from './dbPaths'
import { get_pixabay_media_url } from './socialUrls'

import { db_get_hashtag_id_url } from './dbPaths'
import { ig_get_hashtag_id_url } from './socialUrls'
import { db_get_hashtag_feed_url } from './dbPaths'
import { ig_get_hashtag_feed_url } from './socialUrls'
import { db_get_hashtag_user_search_url } from './dbPaths'
import { ig_get_hashtag_user_search_url } from './socialUrls'

import { db_get_hashtag_data_url } from './dbPaths'
import { ig_get_hashtag_data_url } from './socialUrls'

import { db_get_ig_bad_hashtag_feed_url } from './dbPaths'
import { ig_bad_get_hashtag_feed_url } from './socialUrls'

import {
  db_get_fb_user_image_url,
  db_ig_get_users_insights_url,
  db_ig_get_insights_media_url,
  db_get_fb_account_data_url,
  db_ig_get_users_media_list_url,
  db_ig_get_user_id_url
} from './dbPaths'
import {
  get_fb_user_image_url,
  ig_get_users_insights_url,
  ig_get_insights_media_url,
  fb_me_url,
  ig_get_users_media_list_url,
  ig_get_users_id_url
} from './socialUrls'

class DataManager {

  constructor() {
    this.sleep_time = 100
  }

  sleep(ms = this.sleep_time) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  string_to_list(ident) {
    var ident_list = []
    if (typeof ident == "string") {
      ident_list.push(ident)
    }
    if (typeof ident == "object") {
      ident_list = ident
    }
    return ident_list
  }

  _get_urls(source, ident) {
    switch (source) {
      case "ig_bad_hashtag_feed":
        return {
          "db_url" : db_get_ig_bad_hashtag_feed_url(ident.query),
          "ig_url" : ig_bad_get_hashtag_feed_url(ident.query)
        }
      case "business_discovery":
        return {
          "db_url" : db_get_business_discovery_url(ident.query),
          "ig_url" : ig_get_business_discovery_url(
            ident.ig_id,
            ident.query,
            ident.fb_access_token
          )
        }
      case "fb_account_data":
        return {
          "db_url" : db_get_fb_account_data_url(),
          "ig_url" : fb_me_url(ident)
        }
      case "user_feed":
        return {
          "db_url" : db_ig_get_user_profile_url(ident),
          "ig_url" : ig_get_user_profile_url(ident)
        }
      case "hashtag_feed":
        return {
          "db_url" : db_get_hashtag_feed_url(ident),
          "ig_url" : ig_get_hashtag_feed_url(ident)
        }
      case "media_info":
        return {
          "db_url" : db_get_post_info_url(ident),
          "ig_url" : ig_get_post_info_url(ident)
        }
      case "long_term_token":
        return {
          "db_url" : db_get_fb_long_term_token_url(),
          "ig_url" : ig_get_long_term_token_url(ident)
        }
      case "user_pages":
        return {
          "db_url" : db_get_fb_account_pages_metadata_url(),
          "ig_url" : ig_get_user_pages_url(ident)
        }
      case "page_business_account":
        return {
          "db_url" : db_get_fb_account_pages_accounts_url(ident.page_id),
          "ig_url" : ig_get_page_business_account_url(
            ident.page_id,
            ident.fb_access_token
          )
        }
      case "business_account_metadata":
        return {
          "db_url" : db_ig_get_users_business_accounts_metadata_url(ident.ig_id),
          "ig_url" : ig_get_users_business_account_metadata_url(
            ident.ig_id,
            ident.fb_access_token
          )
        }
      case "ig_hashtag_id":
        return {
          "db_url" : db_get_hashtag_id_url(ident.query),
          "ig_url" : ig_get_hashtag_id_url(
            ident.ig_id,
            ident.query,
            ident.fb_access_token
          )
        }
      case "ig_hashtag_data":
        return {
          "db_url" : db_get_hashtag_data_url(ident.ig_hashtag_id),
          "ig_url" : ig_get_hashtag_data_url(
            ident.ig_hashtag_id,
            ident.fb_access_token
          )
        }
      case "ig_hashtag_feed":
        console.log(ident)
        return {
          "db_url" : db_get_hashtag_feed_url(ident.query,ident.type),
          "ig_url" : ig_get_hashtag_feed_url(
            ident.hashtag_id,
            ident.ig_id,
            ident.type,
            ident.after,
            ident.fb_access_token
          )
        }

      case "ig_hashtag_user_search":
        return {
          "db_url" : db_get_hashtag_user_search_url(),
          "ig_url" : ig_get_hashtag_user_search_url(
            ident.ig_id,
            ident.fb_access_token
          )
        }
      case "ig_insights":
        return {
          "db_url" : db_ig_get_users_insights_url(
            ident.ig_id,
            ident.db_id
          ),
          "ig_url" : ig_get_users_insights_url(
            ident.ig_id,
            ident.metric,
            ident.period,
            ident.since,
            ident.until,
            ident.fb_access_token
          )
        }
        case "ig_insights_media_image":
          return {
            "db_url" : db_ig_get_insights_media_url(
              ident.media_id
            ),
            "ig_url" : ig_get_insights_media_url(
              ident.media_id,
              "engagement, impressions, reach, saved",
              ident.fb_access_token
            )
          }
          case "ig_insights_media_video":
            return {
              "db_url" : db_ig_get_insights_media_url(
                ident.media_id
              ),
              "ig_url" : ig_get_insights_media_url(
                ident.media_id,
                "engagement, impressions, reach, saved, video_views",
                ident.fb_access_token
              )
            }
          case "ig_user_media_list":
            return {
              "db_url" : db_ig_get_users_media_list_url(
                ident.ig_id
              ),
              "ig_url" : ig_get_users_media_list_url(
                ident.ig_id,
                ident.fb_access_token
              )
            }
          case "ig_user_id":
            return {
              "db_url" : db_ig_get_user_id_url(
                ident.ig_id
              ),
              "ig_url" : ig_get_users_id_url(
                ident.ig_id,
                ident.fb_access_token
              )
            }
        case "fb_user_image":
          return {
            "db_url" : db_get_fb_user_image_url(),
            "ig_url" : get_fb_user_image_url(
              ident.fb_id,
              ident.fb_access_token
            )
          }
        case "pixabay_images":
          return {
            "db_url" : db_get_media_query_url(
              ident.query,
              ident.page
            ),
            "ig_url" : get_pixabay_media_url(
              ident.query,
              ident.page,
            ),
          }
        default:
        return console.log("selected media does not exist")
    }

  }

  async get_data(source, ident) {

    ident = this.string_to_list(ident)

    var db_manager = new DbManager()
    var request_manager = new RequestManager()
    var data = []
    var data_list = []
    var max = ident.length

    for (var i in ident) {
      // console.log(i,"/",max)
      const {db_url, ig_url} = this._get_urls(source, ident[i])

      db_manager.set_url(db_url)
      request_manager.set_url(ig_url)

      data = await this._get_data(db_manager, request_manager)
      data_list.push(data)
    }

    return data_list

  }

  _check_if_refresh(data) {
    var do_refresh = false
    const recent_timestamp = new Date().getTime()
    if (!data.timestamp) {
      do_refresh = true
    }
    const valid_range = data.timestamp + 5*60*60*1000
    if (recent_timestamp > valid_range) {
      do_refresh = true
    }
    return do_refresh
  }

  async _get_data(db_manager,request_manager){
    var data = []
    var return_data = []
    data = await db_manager.get_data()
    // data = false
    if (data && !this._check_if_refresh(data) ) {
      return_data = data
    } else {
      data = await request_manager.get_data()
      if(data.error) {
        return false
      }
      if (data){
        data.timestamp = new Date().getTime()
        db_manager.store_data(data)
        return_data = data
        await this.sleep();
      }
    }
    return return_data
  }


}

export default DataManager
