class ParserAyrshare {
  constructor() {
  }
  set_payload(payload) {
    this.payload = payload
  }
  set_platform(platform) {
    this.platform = platform
  }
  get_parsed_data() {
    const parsed_data = {
      "error_message" : this._get_error_message()
    }
    return parsed_data
  }
  _get_error_message() {
    var data = "not_set"
    var message
    const platform = this.platform
    try {
      message = this.payload[platform].message
      if (message) {
        data = message
      }
    } catch {

    }
    try {
      message = this.payload.message
      if (message) {
        data = message
      }
    } catch {

    }
    return data
  }
}
export default ParserAyrshare
