import DbManager from "./DbManager";
import DigistoreIpn from "./fake_firebase/DigistoreIpn";
import ManagerProdukt from "./ManagerProduct";
import { auth } from './firebase/iniFirebase'
import {
  db_get_client_list_url,
  db_get_social_account_url
 } from './dbPaths'

import {db_get_client_order_url} from './dbPaths'

class ManagerClient {

  constructor() {
    this.empty = ""
  }

  test(){
    const ipn = new DigistoreIpn(auth)
    ipn.handle_fake_request()
  }

  store_account(said){
    const db_manager = new DbManager()
    const uid = auth().currentUser.uid
    const user_mail = auth().currentUser.email
    const key = said
    const customer_obj = {
      "uid" : uid,
      "email" : user_mail,
      "said" : said
    }
    const url = db_get_client_list_url(key)
    db_manager.set_url(url)
    db_manager.store_data(customer_obj)
  }

  async check_if_customer(said) {
    const db_manager = new DbManager()
    const customer_list_url = db_get_client_list_url(said)
    db_manager.set_url(customer_list_url)
    const order_data = await db_manager.get_data()
    const order_id = order_data.order_id
    const order_event = order_data.event
    if (order_data)
      if (order_id) {
        if (order_event == "on_payment") {
          return true
        } else {
          return false
        }
      }
      return false
  }

  async get_order_data(said) {
    const db_manager = new DbManager()
    const order_status = await this._get_sa_order_status(said)
    const order_id = order_status.order_id
    if (!order_id){
      return false
    }
    const url = db_get_client_order_url(order_id)
    db_manager.set_url(url)
    const data = await db_manager.get_data()
    if (data) {
      return data
    }
    return false
  }

  async _get_sa_order_status(said) {
    const db_manager = new DbManager()
    const order_status_url = db_get_client_list_url(said)
    db_manager.set_url(order_status_url)
    const order_status = await db_manager.get_data()
    const order_id = order_status.order_id
    const order_event = order_status.event
    if (order_status)
      if (order_id) {
        if (order_event == "on_payment") {
          return order_status
        }
      }
    return false
  }

}

export default ManagerClient
