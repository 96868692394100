import DbManager from "../../helper/DbManager";
import {
  IDENT_UACC_INSTA_NAME,
  IDENT_UACC_HISTORY_EXPLORE,
  IDENT_UACC_TODO,
  IDENT_UACC_TODO_COUNT,
  IDENT_UACC_TODO_TIMESTAMP,
} from '../constants/accountConstants'

import {
  db_get_account_root_url,
  db_get_account_key_url,
} from '../../helper/dbPaths'

import {
  GROW_DAILY_TODO_AMOUNT,
} from '../../helper/global_variables'

export const SET_UACC_DATA_BY_KEY = 'SET_UACC_DATA_BY_KEY'
export const SET_UACC_DATA_DEFAULT = 'SET_UACC_DATA_DEFAULT'
export const SET_UACC_DATA = 'SET_UACC_DATA'
export const ACCOUNT_UPDATE_HISTORY = 'ACCOUNT_UPDATE_HISTORY'

export function accountSetTodoCount() {
  const key = IDENT_UACC_TODO
  const url = db_get_account_root_url()
  return async (dispatch,getState) => {
    const db_timestamp = getState().account[key].timestamp
    const midnight_timestamp = new Date().setHours(23,59,59,0)
    const recent_timestamp = new Date().getTime()
    if (!db_timestamp || recent_timestamp > db_timestamp){
      const obj = {
        [IDENT_UACC_TODO_COUNT] : GROW_DAILY_TODO_AMOUNT,
        [IDENT_UACC_TODO_TIMESTAMP] : midnight_timestamp
      }
      dispatch(storeUpdateAccountDataByKey(key,obj,url))
    }
  }
}

export function accountUpdateTodoCount() {
  const key = IDENT_UACC_TODO
  const url = db_get_account_root_url()
  return async (dispatch,getState) => {
    const db_obj = getState().account[key]
    const count = db_obj[IDENT_UACC_TODO_COUNT]
    var new_count = count - 1
    if (new_count < 0 ) {
      new_count = 0
    }
    db_obj[IDENT_UACC_TODO_COUNT] = new_count
    dispatch(storeUpdateAccountDataByKey(key,db_obj,url))
  }
}

export function accountRemoveInstaName() {
  const key = IDENT_UACC_INSTA_NAME
  const url = db_get_account_root_url()
  return async dispatch => {
    dispatch(storeUpdateAccountDataByKey(IDENT_UACC_INSTA_NAME,"",url))
  }
}

export function accountStoreInstaName(payload) {
  const key = IDENT_UACC_INSTA_NAME
  const url = db_get_account_root_url()

  return async dispatch => {
    dispatch(storeUpdateAccountDataByKey(key, payload, url))
  }
}

export function accountStoreExploreHistory(payload) {
  const key = IDENT_UACC_HISTORY_EXPLORE
  const url = db_get_account_key_url(key)

  return async dispatch => {
    dispatch(storeAccountHistory(key, payload, url))
  }
}

export function accountFetch() {
  return async dispatch => {
    const db_manager = new DbManager()
    const url = db_get_account_root_url()
    db_manager.set_url(url)
    const data = await db_manager.get_data()
    if (!data) {
      return dispatch(setDataDefault())
    }
    dispatch(setAccountData(data))
  }
}
export function storeUpdateAccountDataByKey(key, payload, url) {
  const db_manager = new DbManager()
  const obj = {
    [key] : payload
  }
  db_manager.set_url(url)
  db_manager.store_data(obj)
  return {
    type: SET_UACC_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}
function storeAccountHistory(key, payload, url) {
  const db_manager = new DbManager()
  db_manager.set_url(url)
  db_manager.store_data(payload)
  return {
    type: ACCOUNT_UPDATE_HISTORY,
    payload:payload,
    key:key
  }
}

export function setAccountDataByKey(key,payload) {
  const obj = {
    [key] : payload
  }
  return {
    type: SET_UACC_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}

function setDataDefault() {
  return {
    type: SET_UACC_DATA_DEFAULT,
  }
}

function appendDataByKey(key,payload, store_db = true) {
  return {
    type: ACCOUNT_UPDATE_HISTORY,
    payload:payload,
    key:key
  }
}

function setAccountData(payload) {
  return {
    type: SET_UACC_DATA,
    payload:payload
  }
}
