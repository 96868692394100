import ParserData from "../../helper/ParserData";
import DataManager from "../../helper/DataManager";
import DbManager from "../../helper/DbManager";
import RequestManager from "../../helper/RequestManager";

import {
  db_get_will_data_url,

} from '../../helper/dbPaths'

const parser_data = new ParserData()
const data_manager = new DataManager()
const db_manager = new DbManager()
const request_manager = new RequestManager()

export const SET_OPEN_STATUS = 'SET_OPEN_STATUS'
export const SET_WILL_TYPE = 'SET_WILL_TYPE'
export const SET_WILL_DATA = 'SET_WILL_DATA'
export const SET_WILL_INFO_IDENT = 'SET_WILL_INFO_IDENT'

export function setOpenStatus(opened) {
  return {
    type: SET_OPEN_STATUS,
    opened
  }
}

export function setWillType(willType) {
  const obj = {
    "will_type" : willType
  }
  const url = db_get_will_data_url()
  db_manager.set_url(url)
  db_manager.store_data(obj)
  return {
    type: SET_WILL_TYPE,
    willType
  }
}

export function setWillInfo(info_ident) {
  return dispatch => {
    dispatch(setWillInfoIdent(info_ident))
    dispatch(setWillType("WillInfo"))
  }
}

function setWillInfoIdent(info_ident) {
  return {
    type: SET_WILL_INFO_IDENT,
    info_ident
  }
}

export function setWillData(data) {
  return {
    type: SET_WILL_DATA,
    data
  }
}
