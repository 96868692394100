class ParserInstagramMedia {
    constructor() {
      this.raw_json = ""
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    get_parsed_media_list() {
      const raw_media_list_top = this._get_media_top()
      const raw_media_list_recent = this._get_media_recent()
      const media_list_top = this._get_parsed_media_list(raw_media_list_top)
      const media_list_recent = this._get_parsed_media_list(raw_media_list_recent)
      const obj = {
        "top" : media_list_top,
        "recent" : media_list_recent
      }
      return obj
    }

    _get_media_top() {
      try {
        const data = this.raw_json.graphql.hashtag
        const media_list = data.edge_hashtag_to_top_posts.edges
        if (media_list && media_list.length > 0) {
          return media_list
        }
      } catch(err) {
        return false
      }
    }

    _get_media_recent() {
      try {
        const data = this.raw_json.graphql.hashtag
        const media_list = data.edge_hashtag_to_media.edges
        if (media_list && media_list.length > 0) {
          return media_list
        }
      } catch(err) {
        return false
      }
    }

    _get_parsed_media_list(raw_media_list) {
      var media_list = []
      for (var i in raw_media_list) {
        const raw_object = raw_media_list[i]
        var obj = {}
        obj = this._parse_media(raw_object)
        media_list.push(obj)
      }
      return media_list
    }

    _parse_media(media_obj) {

      var obj = {
        "caption" : this._get_catpion(media_obj),
        // "timestamp" : this._get_timestamp(media_obj),
        // "like_count" : this._get_like_count(media_obj),
        // "comments_count" : this._get_comments_count(media_obj),
        // "media_url" : this._get_media_url(media_obj),
        // "permalink" : this._get_permalink(media_obj),
        // "username" : "#" + this.user_search_query,
        // "id" : this._get_id(media_obj),
      }
      return obj
    }

    _get_catpion(media_obj) {
      const key = "caption"
      var data
      try {
        data = media_obj.node.edge_media_to_caption.edges[0].node.text
        data = this._check_value(data, key)
      }
      catch(err) {
        data = key
      }
      return data
    }

    _check_value(value, placeholder) {
      var ok = true
      if (value == undefined) {
        ok = false
      }
      if (Number.isNaN(value)) {
        ok = false
      }
      if (!ok) {
        value = placeholder
        return value
      }
      return value
    }



}


export default ParserInstagramMedia
