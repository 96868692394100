// import DbManager from "../../helper/DbManager";
import {
  IDENT_SEARCH_QUERY,
  IDENT_SEARCH_SELECTION,
  IDENT_FILTER_DATA
} from '../constants/generalConstants'

// import {
//   db_get_ayrshare_url,
//
// } from '../../helper/dbPaths'


// const db_manager = new DbManager()

export const SET_GEN_DATA_BY_KEY = 'SET_GEN_DATA_BY_KEY'

export function setSearchQuery(payload) {
  const key = IDENT_SEARCH_QUERY

  return async dispatch => {
    dispatch(setGeneralDataByKey(key,payload))
  }
}

export function setFilterDataIdent(payload) {
  const key = IDENT_FILTER_DATA

  return async dispatch => {
    dispatch(setGeneralDataByKey(key,payload))
  }
}

export function setSearchSelection(payload) {
  const key = IDENT_SEARCH_SELECTION

  return async dispatch => {
    dispatch(setGeneralDataByKey(key,payload))
  }
}

export function setGeneralDataByKey(key,payload) {
  const obj = {
    [key] : payload
  }
  return {
    type: SET_GEN_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}
