import {
  SET_ACCESS_TOKEN_SHORT,
  SET_CONNECT_STATUS,
  SET_CONNECT_STEP,
  DISCONNECT
} from '../actions/fbActions'


export const default_state = {
  "access_token_short" : "",
  "connected" : false,
  "connect_step" : 0
}


export function fb (state = default_state, action) {
  switch (action.type) {
    case SET_ACCESS_TOKEN_SHORT:
      var new_state =
      {
        ...state,
          access_token_short: action.access_token_short
      }
      return new_state
    case SET_CONNECT_STATUS:
      var new_state =
      {
        ...state,
          connect_status: action.connect_status
      }
      return new_state
    case SET_CONNECT_STEP:
      var new_state =
      {
        ...state,
          connect_step: action.connect_step
      }
      return new_state
    case DISCONNECT:
        var new_state = default_state
        return new_state
    default:
      return state
  }
}
