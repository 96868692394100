import {db} from '../../helper/firebase/iniFirebase'
import {default_state} from '../reducers/dashboardUserReducer'
import { sort_by_key_int } from '../../helper/helperFunctions'

import {
  TOUR_PUBLISH,
  TOUR_INSPIRE,
} from '../constants/dashboardUserConstants'

import ParserData from "../../helper/ParserData";
import DataManager from "../../helper/DataManager";
import DbManager from "../../helper/DbManager";
import RequestManager from "../../helper/RequestManager";
import ParserPixabay from '../../helper/ParserPixabay'
import ParserHashtag from '../../helper/ParserHashtag'
import ParserIgUserMediaList from '../../helper/ParserIgUserMediaList'
import
  RequestManagerInstagram from "../../helper/instagram/ManagerInstagramOfficial";
import ManagerSocialAccount from '../../helper/ManagerSocialAccount'

import {
  db_get_update_user_media_selection_url,
  db_get_user_data_url,
  db_get_user_media_selection_url,
  db_get_user_media_publish_url,
  db_get_update_user_media_publish_url,
  db_get_user_draft_caption_url,
  db_get_selected_acc_url,
  db_get_fb_account_data_url,
  db_get_fb_long_term_token_url,
  db_ig_get_users_insights_url,
  db_get_user_feed_inspire_url,
  db_ig_url,
  db_get_user_media_schedule_url,


} from '../../helper/dbPaths'

import {
  ig_get_users_insights_url
} from '../../helper/socialUrls'

export const SET_INSTA_NAME = 'SET_INSTA_NAME'
export const SET_USER_AUTH = 'SET_USER_AUTH'
export const SET_USER_DATA = 'SET_USER_DATA'
export const ADD_TO_FEED_INSPIRE = 'ADD_TO_FEED_INSPIRE'
export const SET_SELECTED_ACC = 'SET_SELECTED_ACC'
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA'
export const SET_USER_AVATAR = 'SET_USER_AVATAR'
export const LOGOUT_FACEBOOK = 'LOGOUT_FACEBOOK'
export const REFRESH_USER_SELECTED_MEDIA = 'REFRESH_USER_SELECTED_MEDIA'
export const REFRESH_USER_PUBLISH_MEDIA = 'REFRESH_USER_PUBLISH_MEDIA'
export const SET_FB_ACCOUNT_DATA = 'SET_FB_ACCOUNT_DATA'
export const SET_FB_LONG_TERM_TOKEN = 'SET_FB_LONG_TERM_TOKEN'
export const SET_USER_PUBLISH_SCHEDULED = 'SET_USER_PUBLISH_SCHEDULED'
export const ADD_TO_REPORTING_LIST = 'ADD_TO_REPORTING_LIST'
export const ADD_POST_USER_PUBLISH_QUEUE = 'ADD_POST_USER_PUBLISH_QUEUE'
export const REMOVE_POST_USER_PUBLISH_QUEUE = 'REMOVE_POST_USER_PUBLISH_QUEUE'
export const SET_DISPLAY_PREVIEW = 'SET_DISPLAY_PREVIEW'
export const SET_IG_USER_MEDIA_LIST = 'SET_IG_USER_MEDIA_LIST'
export const SET_IG_INSIGHTS_ONLINE_FOLLOWER = 'SET_IG_INSIGHTS_ONLINE_FOLLOWER'
export const SET_IG_INSIGHTS_PROFILE = 'SET_IG_INSIGHTS_PROFILE'
export const SET_IG_ACCOUNT_METADATA = 'SET_IG_ACCOUNT_METADATA'
export const SET_IG_DATA = 'SET_IG_DATA'
export const SET_USER_DATA_BY_KEY = 'SET_USER_DATA_BY_KEY'
export const APPEND_DU_BY_KEY = 'APPEND_DU_BY_KEY'
export const REMOVE_DU_BY_KEY = 'REMOVE_DU_BY_KEY'
export const ADD_DU_PROPERTY_BY_KEY = 'ADD_DU_PROPERTY_BY_KEY'

const parser_data = new ParserData()
const data_manager = new DataManager()

const db_manager = new DbManager()
const request_manager = new RequestManager()
const parser_pixabay = new ParserPixabay()
const parser_hashtag = new ParserHashtag()
const parser_ig_user_media_list = new ParserIgUserMediaList()
const manager_request_instagram = new RequestManagerInstagram()

// export function userAddSocialAccount(said, primary=false) {
//   const manager = new ManagerSocialAccount()
//   const key = "social_account_list"
//   const payload = {
//     "said" : said,
//     "primary" : primary
//   }
//   return async (dispatch,getState) => {
//     await dispatch(appendDataByKey(key,payload))
//     const uid = getState().auth.user.uid
//     const data = getState().userData[key]
//     const obj = {
//       [key] : data
//     }
//     const url = db_get_user_data_url()
//     db_manager.set_url(url)
//     db_manager.store_data(obj)
//   }
// }

export function userAddSocialAccount(said, primary=false) {
  const manager = new ManagerSocialAccount()
  const key = "social_account"
  const payload = {
    "primary" : primary
  }
  return async (dispatch,getState) => {
    await dispatch(addPropertyByKey(key, said, payload))
    const uid = getState().auth.user.uid
    // manager.add_social_account_to_user(said,uid)
  }
}

function addPropertyByKey(key,property, payload) {
  return {
    type: ADD_DU_PROPERTY_BY_KEY,
    payload:payload,
    key:key,
    property:property
  }
}

function appendDataByKey(key,payload) {
  return {
    type: APPEND_DU_BY_KEY,
    payload:payload,
    key:key
  }
}

export function setTourStatus(key, payload) {

  return async dispatch => {
    dispatch(storeDataByKey(key,payload))
  }
}

export function setNewAccountModalOpen(payload) {
  const key = "new_account_modal_open"

  return async dispatch => {
    dispatch(setDataByKey(key,payload))
  }
}

export function setCurrentSocialAccount(payload) {
  const key = "current_social_account_id"
  return async dispatch => {
    dispatch(storeDataByKey(key,payload))
  }
}

export function setUserAuthed(payload) {
  const key = "is_authed"
  return async dispatch => {
    dispatch(storeDataByKey(key,payload))
  }
}






function setDataByKey(key,payload) {
  const obj = {
    [key] : payload
  }
  return {
    type: SET_USER_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}

function storeDataByKey(key,payload) {
  const obj = {
    [key] : payload
  }
  const url = db_get_user_data_url()
  db_manager.set_url(url)
  db_manager.store_data(obj)
  return {
    type: SET_USER_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}

export function setDefaultCurrentSocialAccount() {
  const key = "current_social_account_id"
  return async (dispatch,getState) => {
    const user_data = getState().userData
    const social_account = user_data.social_account
    const current_social_account_id = user_data.current_social_account_id
    if (current_social_account_id != "not_set"){
      return
    }
    if (!social_account) {
      return
    }
    const new_current_social_account_id = Object.keys(social_account)[0];
    dispatch(storeDataByKey(key,new_current_social_account_id))
  }
}

export function fetch_user_data_from_db() {
  return async dispatch => {
    const url = db_get_user_data_url()
    var ig_id, fb_access_token
    var user_name = ""
    var snapshot = await db.ref(url).once('value')
    var user_data = snapshot.val()
    const merged_user_data = {...default_state, ...user_data }
    dispatch(setUserData(merged_user_data))
  }
}

export function setUserData(userData) {
  return {
    type: SET_USER_DATA,
    userData
  }
}

export function fetchAccountData(ident_obj) {
  const ig_id = ident_obj.ig_id
  const fb_access_token = ident_obj.fb_access_token
  return async dispatch => {
    // await dispatch(fetchIgAccountMetadata(ig_id, fb_access_token))
    // await dispatch(fetchIgInsightsOnlineFollower(ig_id, fb_access_token))
    // await dispatch(fetchIgInsightsProfile(ig_id, fb_access_token))
    // await dispatch(fetchIgUserMediaList(ig_id, fb_access_token))
    // const data = await data_manager.get_data("business_discovery",[ident_obj])
    const prom_1 = dispatch(fetchIgAccountMetadata(ig_id, fb_access_token))
    const prom_2 = dispatch(fetchIgInsightsOnlineFollower(ig_id, fb_access_token))
    const prom_3 = dispatch(fetchIgInsightsProfile(ig_id, fb_access_token))
    const prom_4 = dispatch(fetchIgUserMediaList(ig_id, fb_access_token))
    const prom_5 = data_manager.get_data("business_discovery",[ident_obj])
    const promise_list = [prom_1, prom_2, prom_3, prom_4, prom_5]
    Promise.all(promise_list.map(p => p.catch(e => e)))
      .then(results => {
        const data = results[4]
        parser_data.set_data(data)
        parser_data.parse()
        const account_data = parser_data.data
        dispatch(setAccountData(account_data))
      }) // 1,Error: 2,3
      .catch(e => console.log(e));
  }
}

export function setAccountData(account_data) {
  return {
    type: SET_ACCOUNT_DATA,
    account_data
  }
}

export function fetchIgAccountMetadata(ig_id,access_token) {

  const ident = {
    "ig_id" : ig_id,
    "fb_access_token" : access_token
  }
  return async dispatch => {
    const data = await data_manager.get_data("business_account_metadata",[ident])
    const parsed_data = data[0]
    dispatch(setIgAccountMetadata(parsed_data))
  }
}

function setIgAccountMetadata(data) {
    return {
      type: SET_IG_ACCOUNT_METADATA,
      data: data
    }
}

export function setDisplayPreview(display) {
  return {
    type: SET_DISPLAY_PREVIEW,
    display
  }
}

export function getHashtagFeed(userData, ident) {
  return async dispatch => {
    const ig_id = userData.selected_acc.ig_id
    const fb_access_token = userData.fb_user.access_token.long.access_token
    var ident_obj = {
      "ig_id" : ig_id,
      "query" : ident,
      "fb_access_token" : fb_access_token
    }
    const hashtag_id_response = await data_manager.get_data(
      "ig_hashtag_id",
      [ident_obj]
    )
    if (hashtag_id_response[0].error) {
      // open will
      return false
    }
    const hashtag_id = hashtag_id_response[0].data[0].id
    ident_obj = {
      "fb_access_token" : fb_access_token,
      "ig_id" : ig_id,
      "hashtag_id" : hashtag_id,
      "query" : ident,
      "type" : "top",
      "after" : "",

    }
    const raw_feed_data = await data_manager.get_data(
      "ig_hashtag_feed",
      [ident_obj]
    )
    if (raw_feed_data[0].error && !raw_feed_data[0].data) {
      // open will
      return false
    }
    parser_hashtag.set_user_search_query(ident)
    parser_hashtag.set_raw_data(raw_feed_data)
    const parsed_feed_data = parser_hashtag.get_parsed_list()
    const list = parsed_feed_data
    const sorted_list = sort_by_key_int(list,"like_count")
    // const sliced_sorted_list = sorted_list.slice(0,2)
    const sliced_sorted_list = sorted_list.slice(0,45)
    for (var i in sliced_sorted_list) {
      dispatch(addDataFeedInspire(sliced_sorted_list[i]))
    }
    return parsed_feed_data
  }
}




export function addToReportingList(reporting_obj) {
  return {
    type: ADD_TO_REPORTING_LIST,
    reporting_obj
  }
}

export function setSearchQuery(subreddit) {
  return {
    type: SET_INSTA_NAME,
    subreddit
  }
}

export function addDataFeedInspire(data_feed_inspire) {
  // const url = "user/wW6e0hHeBuSbcUrONuc744nUsn73/"
  const url = db_get_user_feed_inspire_url()
  db_manager.set_url(url)
  // const data = {
  //   "feed_inspire" : "moin"
  // }
  const db_response_obj = db_manager.push_data(data_feed_inspire)
  // const db_response_obj = db_manager.store_data(data)
  return async dispatch => {
    const deb_response = await db_response_obj.promise
    const entry_key = db_response_obj.ref.key
    dispatch(updateFeedInspire(entry_key, data_feed_inspire))
  }
}

export function setSelectedAcc(selected_acc) {
  const url = db_get_selected_acc_url()
  db.ref(url).update(selected_acc);
  return {
    type: SET_SELECTED_ACC,
    selected_acc
  }
}

export function setUserAvatarUrl(user_avatar_url) {
  return {
    type: SET_USER_AVATAR,
    user_avatar_url
  }
}




function setUserPublishMediaScheduled(isScheduled) {
  const is_scheduled = {
    "is_scheduled" : isScheduled
  }
  const url = db_get_user_media_schedule_url()
  db_manager.set_url(url)
  db_manager.store_data(is_scheduled)
  return {
    type: SET_USER_PUBLISH_SCHEDULED,
    is_scheduled
  }
}

export function logoutFacebook() {
  return {
    type: LOGOUT_FACEBOOK,
  }
}

function setIgData(payload) {
  return {
    type: SET_IG_DATA,
    key: payload.key,
    data: payload.data
  }
}

export function addUserSelectedMedia(payload) {
  const media_source = payload.media_source
  const media_id = payload.media.id
  const url = db_get_update_user_media_selection_url(media_source,media_id)
  db_manager.set_url(url)
  db_manager.store_data(payload)
  return async dispatch => {
    const url = db_get_user_media_selection_url()
    db_manager.set_url(url)
    const media_to_post = await db_manager.get_data()
    dispatch(refreshUserSelectedMedia(media_to_post))
  }
}



export function addUserPublishMedia(payload) {

  return async dispatch => {
    const uploaded_url = db_get_update_user_media_publish_url()
    db_manager.set_url(uploaded_url)
    await db_manager.store_data(payload)
    const url = db_get_user_media_publish_url()
    db_manager.set_url(url)
    const media_to_post = await db_manager.get_data()
    await dispatch(refreshUserPublishMedia(media_to_post))
  }
}

export function removeUserPublishMedia(media_id, payload) {
  const url = db_get_update_user_media_publish_url()
  db_manager.set_url(url)
  db_manager.remove_data()
  db_manager.remove_file(media_id)
  return async dispatch => {
    dispatch(refreshUserPublishMedia(payload))
  }
}

export function refreshUserPublishDraft() {
  const url = db_get_user_media_publish_url()
  db_manager.set_url(url)
  db_manager.remove_data()
  return async dispatch => {
    dispatch(refreshUserPublishMedia({}))
  }
}

function refreshUserPublishMedia(payload) {
  return {
    type: REFRESH_USER_PUBLISH_MEDIA,
    payload:payload
  }
}

export function setFbLongTermToken(long_term_token) {
  const db_url = db_get_fb_long_term_token_url()
  db_manager.set_url(db_url)
  db_manager.store_data(long_term_token)
  long_term_token = {
    "long" : long_term_token
  }

  return {
    type: SET_FB_LONG_TERM_TOKEN,
    long_term_token
  }

}


function refreshUserSelectedMedia(payload) {
  return {
    type: REFRESH_USER_SELECTED_MEDIA,
    payload:payload
  }
}

async function refreshUserPublishData() {

}

export function setFbAccountData(fb_account_data) {
  const url = db_get_fb_account_data_url()
  db_manager.set_url(url)
  db_manager.store_data(fb_account_data)
  return {
    type: SET_FB_ACCOUNT_DATA,
    fb_account_data
  }
}
// TODO: maybe remove, can be used to check if ig account connected
export function fetchIgId(ig_id,access_token) {

  const ident = {
    "ig_id" : ig_id,
    "fb_access_token" : access_token,
    "key" : "id"
  }
  return async dispatch => {
    const data = await data_manager.get_data("ig_user_id",[ident])
    if (data) {
      ident.data = data[0].ig_id
    }
    dispatch(setIgData(ident))
  }
}

// ig insights
export function fetchIgInsightsOnlineFollower(ig_id,access_token) {

  const metric = "online_followers"
  const period = "lifetime"
  const since = Math.floor(Date.now() / 1000) - 2592000
  const until = Math.floor(Date.now() / 1000)
  const db_id = "insights_onlineFollowers"

  const ident = {
    "ig_id" : ig_id,
    "metric" : metric,
    "period" : period,
    "since" : since,
    "until" : until,
    "db_id" : db_id,
    "fb_access_token" : access_token
  }
  return async dispatch => {
    const data = await data_manager.get_data("ig_insights",[ident])
    dispatch(setIgInsightsOnlineFollower(data))
  }
}

function setIgInsightsOnlineFollower(data) {
    return {
      type: SET_IG_INSIGHTS_ONLINE_FOLLOWER,
      data: data
    }
}

function fetchIgUserMediaList(ig_id,access_token) {

  const ident = {
    "ig_id" : ig_id,
    "fb_access_token" : access_token
  }
  return async dispatch => {
    const data = await data_manager.get_data("ig_user_media_list",[ident])
    const parsed_data = parser_ig_user_media_list.get_parsed_data(data)
    dispatch(setIgUserMediaList(parsed_data))
  }
}

function setIgUserMediaList(data) {
    return {
      type: SET_IG_USER_MEDIA_LIST,
      data: data
    }
}

export function fetchIgInsightsProfile(ig_id,access_token) {

  const since = Math.floor(Date.now() / 1000) - 2592000
  const until = Math.floor(Date.now() / 1000)
  const metric1 = "email_contacts, follower_count, get_directions_clicks, "
  const metric2 = "impressions, phone_call_clicks, profile_views, "
  const metric3 = "reach, text_message_clicks, website_clicks"
  const metric = metric1 + metric2 + metric3
  const db_id = "insights_profile"
  const ident = {
    "ig_id" : ig_id,
    "fb_access_token" : access_token,
    "metric" : metric,
    "period" : "day",
    "since" : since,
    "until" : until,
    "db_id" : db_id
  }
  return async dispatch => {
    const data = await data_manager.get_data("ig_insights",[ident])
    dispatch(setIgInsightsProfile(data))
  }
}

function setIgInsightsProfile(data) {
    return {
      type: SET_IG_INSIGHTS_PROFILE,
      data: data
    }
}

// ------------------------------------------------------> new usefuel ones
export function iniCustomHashtagList() {

  const hashtag_list = [
    'instagrammarketing',
    'instagrammarketingtips',
    'instagrammarketingstrategy',
    'instagrammarketingguide',
    'instagrammarketingsecrets',
  ]

  return async dispatch => {
    for (let i in hashtag_list) {
      const hashtag = hashtag_list[i]
      const payload = await manager_request_instagram.hashtag(hashtag)
      const ident = "ht_" + hashtag
      parser_hashtag.set_raw_data(payload)
      parser_hashtag.set_user_search_query(hashtag)
      const parsed_data = parser_hashtag.get_parsed_list()
      dispatch(addToFeedInspire(ident,parsed_data))
      await new Promise(r => setTimeout(r, 1000));
    }
  }
}

export function addToFeedInspire(ident, parsed_data) {

  const url = db_get_user_feed_inspire_url()
  db_manager.set_url(url)
  const data_obj = {
    [ident] : parsed_data
  }
  const db_response_obj = db_manager.store_data(data_obj)
  return async dispatch => {
    dispatch(updateFeedInspire(ident, parsed_data))
  }
}

export function updateFeedInspire(id, data_feed_inspire) {
  return {
    type: ADD_TO_FEED_INSPIRE,
    id : id,
    data: data_feed_inspire,

  }
}
