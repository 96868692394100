import {functions, db, storage} from './firebase/iniFirebase'

class DbManager {

  constructor() {
  }

  set_url(url) {
    this.url = url
  }

  async upload_file(file_obj) {
    const timestamp = Date.now().toString()
    const file_name = file_obj.file.name.split('.').slice(0, -1).join('.')
    const file_id = timestamp + "_" + file_obj.source + "_" + file_name
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file_id);
    const store_response = await fileRef.put(file_obj.file);
    const file_url = await fileRef.getDownloadURL()
    const obj = {
      "file_id" : file_id,
      "file_url" : file_url
    }
    return obj
  }

  async upload_file_replace(file_obj) {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file_obj.db_id);
    const store_response = await fileRef.put(file_obj.blob);
    const file_url = await fileRef.getDownloadURL()
    return file_url
  }

  async upload_file_from_url(file_obj) {
    const timestamp = Date.now().toString()
    const file_name = file_obj.name
    const file_id = timestamp + "_" + file_obj.source + "_" + file_name
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file_id);
    const store_response = await fileRef.put(file_obj.blob);
    const file_url = await fileRef.getDownloadURL()
    const obj = {
      "file_id" : file_id,
      "file_url" : file_url
    }
    return obj
  }

  async remove_file(file_id) {
    console.log(file_id)
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file_id);
    const response = await fileRef.delete();
    return response
  }

  get_data() {
    const url = this.url
    return new Promise((resolve, reject) => {
     var data = []
     db.ref(url).once('value',snapshot => {
        if (snapshot.exists()){
          data = snapshot.val()
          resolve(data)
        }
        resolve(false)
      });
    })
  }

  get_data_limit(amount) {
    const url = this.url
    return new Promise((resolve, reject) => {
     var data = []
     db.ref(url).limitToFirst(amount).once('value',snapshot => {
        if (snapshot.exists()){
          data = snapshot.val()
          resolve(data)
        }
        resolve(false)
      });
    })
  }

  get_data_filter(query) {
    const url = this.url
    return new Promise((resolve, reject) => {
     var data = []
     db.ref(url).orderByChild(query).equalTo(true).once('value',snapshot => {
        if (snapshot.exists()){
          data = snapshot.val()
          resolve(data)
        }
        resolve(false)
      });
    })
  }

  check_if_data_exists() {
    const url = this.url
    return new Promise((resolve, reject) => {
     var data = []
     db.ref(url).once('value',snapshot => {
        if (snapshot.exists()){
          resolve(true)
        }
        resolve(false)
      });
    })
  }


  set_listener() {
    const url = this.url
    return new Promise((resolve, reject) => {
     var data = []
     db.ref(url).on('value',snapshot => {
        if (snapshot.exists()){
          data = snapshot.val()
          resolve(snapshot)
        }
        resolve(false)
      });
    })
  }

  get_ref() {
    const url = this.url
    const ref = db.ref(url)
    return ref
  }

  store_data(feed_data) {
    const url = this.url
    if (url == "" || url == "/") {
      debugger
      return false
    }
    try {
      const promise = db.ref(url).update(feed_data);
      return promise
    } catch(err) {
      debugger
      console.log(err)
      return false
    }
  }

  async store_data_if_not_exist(data) {
    const url = this.url
    const data_exist = await this.check_if_data_exists()
    if (data_exist){
      return
    }
    if (url == "" || url == "/") {
      debugger
      return false
    }
    try {
      const promise = db.ref(url).update(data);
      return promise
    } catch(err) {
      debugger
      console.log(err)
      return false
    }
  }

  store_data_add(data) {
    const url = this.url
    if (url == "" || url == "/") {
      debugger
      return false
    }
    try {
      const promise = db.ref(url).set(data);
      return promise
    } catch(err) {
      debugger
      console.log(err)
      return false
    }
  }

  push_data(data) {
    const url = this.url
    try {
      const new_post_ref = db.ref(url).push();
      const promise = new_post_ref.set(data)
      const response_obj = {
        "promise" : promise,
        "ref" : new_post_ref
      }
      return response_obj
    } catch(err) {
      debugger
      console.log(err)
      return false
    }
  }

  remove_data() {
    const url = this.url
    if (!url) {
      debugger
      return false
    }
    if (url == "" || url == "/") {
      debugger
      return false
    }
    if (url == "/user/" || url == "/social_account/" || url == "/social_account_data/") {
      debugger
      return false
    }
    if (url == "/user" || url == "/social_account" || url == "/social_account_data") {
      debugger
      return false
    }
    try {
      const promise = db.ref(url).remove()
      return promise
    } catch(err) {
      console.log(err)
      return false
    }
  }

}

export default DbManager
