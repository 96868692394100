import {
  SET_INSTA_NAME,
  SET_USER_DATA,
  SET_ACCOUNT_DATA,
  SET_SELECTED_ACC,
  SET_USER_AVATAR,
  ADD_TO_FEED_INSPIRE,
  LOGOUT_FACEBOOK,
  REFRESH_USER_SELECTED_MEDIA,
  SET_FB_ACCOUNT_DATA,
  REFRESH_USER_PUBLISH_MEDIA,
  SET_FB_LONG_TERM_TOKEN,
  SET_USER_PUBLISH_SCHEDULED,
  ADD_TO_REPORTING_LIST,
  ADD_POST_USER_PUBLISH_QUEUE,
  REMOVE_POST_USER_PUBLISH_QUEUE,
  SET_DISPLAY_PREVIEW,
  SET_IG_USER_MEDIA_LIST,
  SET_IG_INSIGHTS_ONLINE_FOLLOWER,
  SET_IG_INSIGHTS_PROFILE,
  SET_IG_ACCOUNT_METADATA,
  SET_IG_DATA,
  SET_USER_DATA_BY_KEY,
  APPEND_DU_BY_KEY,
  REMOVE_DU_BY_KEY,
  ADD_DU_PROPERTY_BY_KEY,
} from '../actions/dashboardUserActions'

import {
  TOUR_PUBLISH,
  TOUR_INSPIRE,
} from '../constants/dashboardUserConstants'


import { de } from 'date-fns/locale'

export function userSearchQuery (state = '', action) {
  switch (action.type) {
    case SET_INSTA_NAME:
      return action.subreddit
    default:
      return state
  }
}


export const default_state = {
  "correct_promo_code" : false,
  "is_authed" : false,
  "selected_acc" : "",
  "user_avatar_url" : "https://influxio.com/fileadmin/_processed_/" +
  "e/5/csm_Influxio_Duke_pointing_4aed9223ad.png",
  "account_data" : {},
  "media" : {
    "publish" :
    {
        // media : ""
    },
    "selection" : {},
    "schedule" : {}
  },
  "reporting_list" : [],
  "ig_data" : {
    "publish_draft_timestamp" : new Date().getTime()
  },
  "feed_inspire" : [],
  "social_account" : {},
  "current_social_account_id" : "not_set",
  "display_preview" : false,
  "locale" : {
    "time_zone" : "Europe/Berlin",
    "date_format" : "de-DE",
    "language" : "de",
    "fns_locale": de
  },
  [TOUR_PUBLISH] : "not_done",
  [TOUR_INSPIRE] : "not_done",
}

export function userData (state = default_state, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return action.userData
    case SET_USER_DATA_BY_KEY:
        var new_state =
        {
          ...state,
            [action.key]: action.payload
        }
        return new_state
    case APPEND_DU_BY_KEY:
      return { // returning a copy of orignal stat
       ...state, //copying the original state
       [action.key]: state[action.key].concat(action.payload) //new todos array
      }
    case ADD_DU_PROPERTY_BY_KEY:
        var new_state =
        {
          ...state,           // copy all other key-value pairs of food object
          [action.key]: {                     // specific object of food object
            ...state[action.key],   // copy all pizza key-value pairs
            [action.property]: action.payload
          }
        }
          return new_state
    case REMOVE_DU_BY_KEY:
       return {  // returning a copy of orignal state
        ...state, //copying the original state
        [action.key]: state[action.key].filter(item => item !== action.payload)
      }
    case ADD_TO_REPORTING_LIST:
      //TODO: not clean solution
      if (!state.reporting_list) {
        state.reporting_list = []
      }
      var new_state =
      {
        ...state,
          reporting_list: [...state.reporting_list, action.reporting_obj]
      }
      return new_state
    case ADD_TO_FEED_INSPIRE:
      return {
        ...state,
        feed_inspire: {
            ...state.feed_inspire,
            [action.id]: action.data,
        },
      };
    case SET_ACCOUNT_DATA:
      var new_state =
      {
        ...state,
          account_data: action.account_data
      }
      return new_state
    case SET_DISPLAY_PREVIEW:
      var new_state =
      {
        ...state,
          display_preview: action.display
      }
      return new_state
    case SET_SELECTED_ACC:
      var new_state =
      {
        ...state,
          selected_acc: action.selected_acc
      }
      return new_state
    case SET_USER_AVATAR:
      var new_state =
      {
        ...state,
          user_avatar_url: action.user_avatar_url
      }
      return new_state
    case SET_FB_LONG_TERM_TOKEN:
      var new_state =
      {
        ...state,
        fb_user: {
            ...state.fb_user,
            access_token: action.long_term_token,
        },
      }
      return new_state
    case SET_FB_ACCOUNT_DATA:
      var new_state =
      {
        ...state,
        fb_user: {
            ...state.fb_user,
            account_data: action.fb_account_data,
        },
      }
      return new_state
    case REFRESH_USER_SELECTED_MEDIA:
      return {
        ...state,
        media: {
            ...state.media,
            selection: action.payload,
        },
      };
    case REFRESH_USER_PUBLISH_MEDIA:
      return {
        ...state,
        media: {
            ...state.media,
            publish: action.payload,
        },
      };
    case SET_USER_PUBLISH_SCHEDULED:
      return {
        ...state,
        media: {
            ...state.media,
            publish: {
                ...state.media.publish,
                scheduled: action.is_scheduled,
            },
        },
      };
    case ADD_POST_USER_PUBLISH_QUEUE:
      return {
        ...state,
        media: {
            ...state.media,
            schedule: {
                ...state.media.schedule,
                [action.container_id]: action.user_publish_obj,
            },
        },
      };
    case REMOVE_POST_USER_PUBLISH_QUEUE:
      return {
        ...state,
        media: {
            ...state.media,
            schedule: {
                ...state.media.schedule,
                [action.container_id]: false,
            },
        },
      };
    case SET_IG_USER_MEDIA_LIST:
      return {
        ...state,
        ig_data: {
            ...state.ig_data,
            media_list: action.data
        },
      };
    case SET_IG_DATA:
      return {
        ...state,
        ig_data: {
            ...state.ig_data,
            [action.key]: action.data
        },
      };
      case SET_IG_INSIGHTS_ONLINE_FOLLOWER:
        return {
          ...state,
          ig_data: {
              ...state.ig_data,
              insights_onlineFollowers: action.data
          },
        };
        case SET_IG_INSIGHTS_PROFILE:
          return {
            ...state,
            ig_data: {
                ...state.ig_data,
                insights_profile: action.data
            },
          };
          case SET_IG_ACCOUNT_METADATA:
            return {
              ...state,
              ig_data: {
                  ...state.ig_data,
                  account_metadata: action.data
              },
            };
      // return Object.assign({}, state, {
      //   state.media.publish[action.payload.db_id]: action.payload,
      // })
    case LOGOUT_FACEBOOK:
      var new_state = default_state
      return new_state
    default:
      return state
  }
}
