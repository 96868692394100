import {PRODUCT_FREE} from '../../helper/ManagerProduct'

import {
  SET_SA_DATA_BY_KEY,
  APPEND_SA_BY_KEY,
  REMOVE_SA_BY_KEY,
  SET_SA_DATA_DEFAULT,
  SET_SA_DATA,
  SET_SA_PROPERTY_BY_KEY
} from '../actions/socialAccountActions'

import {
  CLIENT_PRODUCT_DATA,
  CLIENT_SALES_CONTRACTOR_ORDER_DATA
} from '../constants/socialAccountConstants'

export const default_state = {
  "member" : {},
  "name" : "",
  "url" : "",
  "owner" : "",
  "new_account_modal_open" : false,
  [CLIENT_PRODUCT_DATA] : null, //object
  [CLIENT_SALES_CONTRACTOR_ORDER_DATA] : null //object

}

export function socialAccount (state = default_state, action) {
  switch (action.type) {
    case SET_SA_DATA_DEFAULT:
      return default_state
    case SET_SA_DATA:
      const merged_state = {...default_state, ...action.payload }
      return merged_state
    case SET_SA_DATA_BY_KEY:
      var new_state =
      {
        ...state,
          [action.key]: action.payload
      }
      return new_state
    case SET_SA_PROPERTY_BY_KEY:
        var new_state =
        {
          ...state,           // copy all other key-value pairs of food object
          [action.key]: {                     // specific object of food object
            ...state[action.key],   // copy all pizza key-value pairs
            [action.property]: action.payload
          }
        }
          return new_state
    case APPEND_SA_BY_KEY:
      return { // returning a copy of orignal stat
       ...state, //copying the original state
       [action.key]: state[action.key].concat(action.payload) //new todos array
      }
    case REMOVE_SA_BY_KEY:
       return {  // returning a copy of orignal state
        ...state, //copying the original state
        [action.key]: state[action.key].filter(item => item !== action.payload)
      }
    default:
      return state
  }
}
