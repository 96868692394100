// import ParserData from "../../helper/ParserData";
import DbManager from "../../helper/DbManager";

import {
  db_get_user_target_url,
} from '../../helper/dbPaths'


const db_manager = new DbManager()

export const SET_TGT_DATA_BY_KEY = 'SET_TGT_DATA_BY_KEY'
export const APPEND_TGT_BY_KEY = 'APPEND_TGT_BY_KEY'
export const REMOVE_TGT_BY_KEY = 'REMOVE_TGT_BY_KEY'
export const SET_TGT_DATA_DEFAULT = 'SET_TGT_DATA_DEFAULT'
export const SET_TGT_DATA = 'SET_TGT_DATA'

export function addInfluencerToTargetList(payload) {
  const key = "influencer_list"

  return async (dispatch,getState) => {
    await dispatch(appendDataByKey(key,payload))
    const data = getState().target[key]
    const obj = {
      [key] : data
    }
    const url = db_get_user_target_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
}

export function removeInfluencerFromTargetList(payload) {
  const key = "influencer_list"

  return async (dispatch,getState) => {
    await dispatch(removeDataByKey(key,payload))
    const data = getState().target[key]
    const obj = {
      [key] : data
    }
    const url = db_get_user_target_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
}

export function addHashtagToTargetList(payload) {
  const key = "hashtag_list"

  return async (dispatch,getState) => {
    await dispatch(appendDataByKey(key,payload))
    const data = getState().target[key]
    const obj = {
      [key] : data
    }
    const url = db_get_user_target_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
}

export function removeHashtagFromTargetList(payload) {
  const key = "hashtag_list"

  return async (dispatch,getState) => {
    await dispatch(removeDataByKey(key,payload))
    const data = getState().target[key]
    const obj = {
      [key] : data
    }
    const url = db_get_user_target_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
}

function appendDataByKey(key,payload, store_db = true) {
  return {
    type: APPEND_TGT_BY_KEY,
    payload:payload,
    key:key
  }
}

function removeDataByKey(key,payload, store_db = true) {
  return {
    type: REMOVE_TGT_BY_KEY,
    payload:payload,
    key:key
  }
}

function setDataByKey(key,payload, store_db = true) {
  const obj = {
    [key] : payload
  }
  if (store_db) {
    const url = db_get_user_target_url()
    db_manager.set_url(url)
    db_manager.store_data(obj)
  }
  return {
    type: SET_TGT_DATA_BY_KEY,
    payload:payload,
    key:key
  }
}

export function fetchTargetData() {

  return async dispatch => {
    const url = db_get_user_target_url()
    db_manager.set_url(url)
    const target_data = await db_manager.get_data()
    if (!target_data) {
      return dispatch(setDataDefault())
    }
    dispatch(setTargetData(target_data))
  }
}

function setDataDefault() {
  return {
    type: SET_TGT_DATA_DEFAULT,
  }
}

function setTargetData(payload) {
  return {
    type: SET_TGT_DATA,
    payload:payload
  }
}

// export function fetchTargetData() {
//   const url = db_get_user_target_url()
//   db_manager.set_url(url)
//   return (dispatch, getState) => {
//     db_manager.get_data(url)
//     .then(resp => {
//       console.log(resp)
//       iniAyrshareData(dispatch,resp)
//     })
//     .catch(err => {
//       console.log("error",err);
//     });
//   }
// }
