import DbManager from "../../helper/DbManager";

import {
  db_get_client_list_url,
  db_get_client_order_url
} from '../dbPaths'

// case 'on_payment_missed':
// EDIT HERE: Add the php code to cancel the subscription with the
//            missed payment. If the payment continues, a new
//            "on_payment" call is run.



class DigistoreIpn {

  constructor(admin) {
    this.admin = admin
    this.db_path_order = "/customer/order"
    this.db_path_customer = "/customer/list"
    this.dummy_json = {
      "order_id" : "aösldj2342jöasdklfj",
      "email" : "test@influxio.com",
      "event" : "on_payment",
      // "custom" : "-MoY37rByKUQ9F8HQDrA",
      "custom" : "-Mpp7bVx1tW4x-4PiHS7",
      "address" : "elbental23",
      "phone" : "02234502345987",
      "billing_type" : "subscription"
    }
  }

  set_request_data(request_data) {
    this.request_data = request_data
  }

  async handle_fake_request(){
    this.set_request_data(this.dummy_json)
    this.store_order()
    const account_to_update = await this.get_account_to_update()
    this.update_account(account_to_update)
    return "OK"
  }

  async handle_request(){
    this.store_order()
    const account_to_update = await this.get_account_to_update()
    this.update_account(account_to_update)
    return "OK"
  }

  store_order(){
    const db_manager = new DbManager(this.admin)
    const key = this.request_data.order_id
    const store_obj = this.request_data
    const url = db_get_client_order_url(key)
    db_manager.set_url(url)
    db_manager.store_data(store_obj)
  }

  async get_account_to_update() {
    const db_manager = new DbManager(this.admin)
    const url = db_get_client_list_url("")
    db_manager.set_url(url)
    const custom = this.request_data.custom
    const account_list = await db_manager.get_data()
    debugger
    for (var key in account_list) {
      const account_obj = account_list[key]
      if (key === custom) {
        return account_obj
      }
    }
    return {}
  }

  update_account(account_obj){
    const db_manager = new DbManager(this.admin)
    const key = account_obj.said
    const url = db_get_client_list_url(key)
    debugger
    account_obj.order_id = this.request_data.order_id
    account_obj.event = this.request_data.event
    db_manager.set_url(url)
    db_manager.store_data(account_obj)
  }
}

export default DigistoreIpn
