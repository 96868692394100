import { PURCHASE_URL } from './redirect_urls'
import { CLIENT_SALES_CONTRACTOR_ORDER_DATA }
  from '../redux/constants/socialAccountConstants'

export const PRODUCT_FREE = "free"
const PRODUCT_BASIC = "basic"
const PRODUCT_STARTER = "starter"

const FEATURE_ANALYTICS = "FEATURE_ANALYTICS"
const FEATURE_INSTAGRAM_ANALYTICS = "FEATURE_INSTAGRAM_ANALYTICS"
const FEATURE_WILL = "FEATURE_WILL"
const FEATURE_INSTAGRAM_KNOWLEDGE = "FEATURE_INSTAGRAM_KNOWLEDGE"
const FEATURE_EVENT_CALENDER = "FEATURE_EVENT_CALENDER"
const FEATURE_STOCK_PHOTO = "FEATURE_STOCK_PHOTO"
const FEATURE_PROFILE_SEARCH = "FEATURE_PROFILE_SEARCH"
const FEATURE_HASHTAG_GENERATOR = "FEATURE_HASHTAG_GENERATOR"
const FEATURE_PLANNER = "FEATURE_PLANNER"
const FEATURE_SCHEDULE = "FEATURE_SCHEDULE"
const FEATURE_HASHTAG_SUGGESTIONS = "FEATURE_HASHTAG_SUGGESTIONS"
const FEATURE_CALL_TO_ACTIONS = "FEATURE_CALL_TO_ACTIONS"
export const FEATURE_TAGGING = "FEATURE_TAGGING"
export const FEATURE_LOCATION = "FEATURE_LOCATION"
const FEATURE_IMAGE_EDITOR = "FEATURE_IMAGE_EDITOR"
export const FEATURE_ACCESS_GROUP = "FEATURE_ACCESS_GROUP"
export const FEATURE_ALL_PLATFORMS = "FEATURE_ALL_PLATFORMS"
export const FEATURE_UPGRADE_BANNER = "FEATURE_UPGRADE_BANNER"


const products =
  {
    [PRODUCT_FREE] :
    {
      "id" : PRODUCT_FREE,
      "title_id" : "onboarding.plans.product.free.title",
      "description_id" : "onboarding.plans.product.free.description",
      "price" : 0,
      "user" : 1,
      "post_amount" : 10,
      "sales_formular_url" : `${PURCHASE_URL}?${PRODUCT_FREE}`,
      [CLIENT_SALES_CONTRACTOR_ORDER_DATA] : "",
      "level" : 0,
      "platform" : "Instagram"
    },
    [PRODUCT_BASIC] :
    {
      "id" : PRODUCT_BASIC,
      "title_id" : "onboarding.plans.product.basic.title",
      "description_id" : "onboarding.plans.product.basic.description",
      "price" : 8,
      "user" : 1,
      "post_amount" : 30,
      // "sales_formular_url" : `${PURCHASE_URL}?${PRODUCT_BASIC}`,
      "sales_formular_url" : "https://www.digistore24.com/product/427948",
      // "sales_formular_url" : `${PURCHASE_URL}?${PRODUCT_BASIC}`,
      [CLIENT_SALES_CONTRACTOR_ORDER_DATA] : "427948",
      "level" : 1,
      "platform" : `
                      Instagram, Facebook, Google, LinkedIn, Pinterest,
                      Twitter,TikTok, YouTube, Pinterest
                  `
    },
    [PRODUCT_STARTER] :
    {
      "id" : PRODUCT_STARTER,
      "title_id" : "onboarding.plans.product.starter.title",
      "description_id" : "onboarding.plans.product.starter.description",
      "price" : 47,
      "user" : 5,
      "post_amount" : "∞",
      // "sales_formular_url" : `${PURCHASE_URL}?${PRODUCT_STARTER}`,
      "sales_formular_url" : "https://www.digistore24.com/product/413357",
      [CLIENT_SALES_CONTRACTOR_ORDER_DATA] : "413357",
      "level" : 2,
      "platform" : `
                      Instagram, Facebook, Google, LinkedIn, Pinterest,
                      Twitter,TikTok, YouTube, Pinterest
                  `
    }
  }

const features =
    {
    [FEATURE_INSTAGRAM_ANALYTICS] :
      {
        "id" : FEATURE_INSTAGRAM_ANALYTICS,
        "title_id" : "onboarding.plans.feature.instagram_analytics.title",
        "description_id" : "onboarding.plans.feature.instagram_analytics.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : true,
        "normal_feature" : false
      },
    [FEATURE_WILL] :
      {
        "id" : FEATURE_WILL,
        "title_id" : "onboarding.plans.feature.will.title",
        "description_id" : "onboarding.plans.feature.will.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : true,
        "normal_feature" : false
      },
    [FEATURE_INSTAGRAM_KNOWLEDGE] :
      {
        "id" : FEATURE_INSTAGRAM_KNOWLEDGE,
        "title_id" : "onboarding.plans.feature.instagram_knowledge.title",
        "description_id" : "onboarding.plans.feature.instagram_knowledge.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_EVENT_CALENDER] :
      {
        "id" : FEATURE_EVENT_CALENDER,
        "title_id" : "onboarding.plans.feature.event_calendar.title",
        "description_id" : "onboarding.plans.feature.event_calendar.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_STOCK_PHOTO] :
      {
        "id" : FEATURE_STOCK_PHOTO,
        "title_id" : "onboarding.plans.feature.stock_photo.title",
        "description_id" : "onboarding.plans.feature.stock_photo.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_PROFILE_SEARCH] :
      {
        "id" : FEATURE_PROFILE_SEARCH,
        "title_id" : "onboarding.plans.feature.profile_search.title",
        "description_id" : "onboarding.plans.feature.profile_search.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_HASHTAG_GENERATOR] :
      {
        "id" : FEATURE_HASHTAG_GENERATOR,
        "title_id" : "onboarding.plans.feature.hashtag_generator.title",
        "description_id" : "onboarding.plans.feature.hashtag_generator.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : true,
        "normal_feature" : false
      },
    [FEATURE_PLANNER] :
      {
        "id" : FEATURE_PLANNER,
        "title_id" : "onboarding.plans.feature.planner.title",
        "description_id" : "onboarding.plans.feature.planner.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_SCHEDULE] :
      {
        "id" : FEATURE_SCHEDULE,
        "title_id" : "onboarding.plans.feature.schedule.title",
        "description_id" : "onboarding.plans.feature.schedule.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : true,
        "normal_feature" : false
      },
    [FEATURE_HASHTAG_SUGGESTIONS] :
      {
        "id" : FEATURE_HASHTAG_SUGGESTIONS,
        "title_id" : "onboarding.plans.feature.hashtag_suggestions.title",
        "description_id" : "onboarding.plans.feature.hashtag_suggestions.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_CALL_TO_ACTIONS] :
      {
        "id" : FEATURE_CALL_TO_ACTIONS,
        "title_id" : "onboarding.plans.feature.call_to_actions.title",
        "description_id" : "onboarding.plans.feature.call_to_actions.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_TAGGING] :
      {
        "id" : FEATURE_TAGGING,
        "title_id" : "onboarding.plans.feature.tagging.title",
        "description_id" : "onboarding.plans.feature.tagging.description",
        "product_list" : [ PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_LOCATION] :
      {
        "id" : FEATURE_LOCATION,
        "title_id" : "onboarding.plans.feature.location.title",
        "description_id" : "onboarding.plans.feature.location.description",
        "product_list" : [ PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_IMAGE_EDITOR] :
      {
        "id" : FEATURE_IMAGE_EDITOR,
        "title_id" : "onboarding.plans.feature.image_editor.title",
        "description_id" : "onboarding.plans.feature.image_editor.description",
        "product_list" : [ PRODUCT_FREE, PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : true,
        "normal_feature" : false
      },
    [FEATURE_ACCESS_GROUP] :
      {
        "id" : FEATURE_ACCESS_GROUP,
        "title_id" : "onboarding.plans.feature.access_group.title",
        "description_id" : "onboarding.plans.feature.access_group.description",
        "product_list" : [ PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : true
      },
    [FEATURE_ALL_PLATFORMS] :
      {
        "id" : FEATURE_ALL_PLATFORMS,
        "title_id" : "",
        "description_id" : "",
        "product_list" : [ PRODUCT_BASIC, PRODUCT_STARTER ],
        "key_feature" : false,
        "normal_feature" : false
      },
      [FEATURE_UPGRADE_BANNER] :
      {
        "id" : FEATURE_UPGRADE_BANNER,
        "title_id" : "upgrade_banner",
        "description_id" : "upgrade_banner",
        "product_list" : [ PRODUCT_FREE ],
        "key_feature" : false,
        "normal_feature" : false
      },
    }

class ManagerProduct {
  constructor() {
    this.debug = false
  }

  get_product_list() {
    return products
  }
  get_feature_list() {
    return features
  }

  get_product_data(contractor_id) {
    const product_list = this.get_product_list()
    for (var i in product_list){
      const product = product_list[i]
      if (product[CLIENT_SALES_CONTRACTOR_ORDER_DATA] == contractor_id ){
        return product
      }
    }
    return false
  }

  get_feature(feature_id){
    const feature_list = this.get_feature_list()
    for (var i in feature_list){
      const feature = feature_list[i]
      if (feature.id == feature_id ){
        return feature
      }
    }
    return false
  }

}

export default ManagerProduct
