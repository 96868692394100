class ParserInstagramSearchHashtag {
    constructor() {
      this.raw_json = ""
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    get_parsed_data() {
      const hashtag_raw_list = this._get_hashtag_raw_list()
      var hashtag_list = []
      for (var i in hashtag_raw_list) {
        const raw_hashtag = hashtag_raw_list[i]
        const hashtag_obj = this._get_hashtag_obj(raw_hashtag)
        if (hashtag_obj) {
          const parsed_hashtag = this._parse_hashtag(hashtag_obj)
          if (parsed_hashtag) {
            hashtag_list.push(parsed_hashtag)
          }
        }
      }
      if (hashtag_list.length > 0) {
        return hashtag_list
      }
      return false
    }

    _get_hashtag_raw_list() {
      const raw_data = this.raw_json
      try {
        const hashtag_raw_list = raw_data.hashtags
        if (hashtag_raw_list && hashtag_raw_list.length > 0) {
          return hashtag_raw_list
        }
      } catch(err) {
        return false
      }
    }

    _get_hashtag_obj(raw_hashtag_obj) {
      try {
        const hashtag_obj = raw_hashtag_obj.hashtag
        if (hashtag_obj) {
          return hashtag_obj
        }
      } catch(err) {
        return false
      }
    }

    _parse_hashtag(hashtag_obj) {
      var obj = {
        "id" : hashtag_obj.id,
        "name" : hashtag_obj.name,
        "media_count" : hashtag_obj.media_count,
        "search_result_subtitle" : hashtag_obj.search_result_subtitle,
      }
      return obj
    }

}


export default ParserInstagramSearchHashtag
