
export default {
  input: {
    active:
        'focus:border-gray-400 border-gray-300 dark:border-gray-600 focus:ring focus:border-gray-600 dark:focus:border-gray-600 dark:focus:ring-gray-300 dark:bg-gray-700',
  },
  avatar: {
    size: {
      large: 'w-20 h-20',
      regular: 'w-10 h-10',
      small: 'w-8 h-8',
    },
  },
  button: {
    base:
      'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 duration-150 font-medium focus:outline-none',
    primary: {
      active: 'moinmoinmoinmoinmoin',
    },
  }
}
