// used for instagram_discovery and instagram_hashtag feed, and maybe UserFeedWith Analytics


class ParserInstagramProfileRapid {
    constructor() {
      this.obj = {}
      this.raw_media_list = []
    }

    set_raw_data(raw_json) {
      this.raw_json = raw_json
    }

    get_parsed_data() {
      const check_restricted = this._check_restricted()
      if (check_restricted){
        return false
      }
      const data = this._get_data()
      const username=data.username
      const bio=data.biography
      const id=data.id
      const post_count=data.edge_owner_to_timeline_media.count
      const follower_count=data.edge_followed_by.count
      const following_count=data.edge_follow.count
      const media_feed = this._get_media_feed(data)
      const profile_pic_url = data.profile_pic_url
      const profile_pic_url_hd = data.profile_pic_url_hd
      const category_name = data.category_name
      const business_category_name = data.business_category_name
      const is_business_account = data.is_business_account
      const is_professional_account = data.is_professional_account
      const is_verified = data.is_verified
      const is_private = data.is_private
      const full_name = data.full_name
      const external_url = data.external_url
      const metadata = {
        "username" : username,
        "bio" : bio,
        "id" : id,
        "post_count" : post_count,
        "follower_count" : follower_count,
        "following_count" : following_count,
        "profile_pic_url" : profile_pic_url,
        "profile_pic_url_hd" : profile_pic_url_hd,
        "category_name" : category_name,
        "business_category_name" : business_category_name,
        "is_business_account" : is_business_account,
        "is_professional_account" : is_professional_account,
        "is_verified" : is_verified,
        "is_private" : is_private,
        "full_name" : full_name,
        "external_url" : external_url,
      }
      const obj = {
        "metadata" : metadata,
        "media_list" : media_feed
      }

      return obj
    }

    _get_data() {
      return this.raw_json
    }

    _check_restricted() {
      const data = this._get_data()
      const title = data.title
      if (!title) {
        return false
      }
      if (data.title == "Restricted profile") {
        return true
      }
      return false
    }

    _get_media_feed(user_data) {
      const raw_media_feed = user_data.edge_owner_to_timeline_media.edges
      const parsed_media_list = this._parse_media(raw_media_feed)
      return parsed_media_list
    }

    _parse_media(raw_media_list) {
      var media_list = []
      for (var i in raw_media_list) {
        var media = raw_media_list[i]
        var parsed_media_obj = this._parse_media_obj(media)
        media_list.push(parsed_media_obj)
      }
      return media_list
    }

    _get_caption(media_obj) {
      var parsed_caption_list = []
      if (!media_obj.edge_media_to_caption){
        return "empty"
      }
      const caption_list = media_obj.edge_media_to_caption.edges
      if (!caption_list) {
        return "empty"
      }
      for (var i in caption_list){
        const caption = caption_list[i].node.text
        if (caption){
          parsed_caption_list.push(caption)
        }
      }
      return parsed_caption_list
    }

    _get_accessibility_caption(media_obj) {
      if (media_obj.accessibility_caption) {
        return media_obj.accessibility_caption
      }
      return "EMPTY"
    }

    _get_location(media_obj) {
      if (media_obj.location) {
        return media_obj.location.name
      }
      return "EMPTY"
    }

    _get_grid_url(media_obj) {
      const data = media_obj.thumbnail_src
      if (data) {
        return data
      }
      return "EMPTY"
    }

    _get_media_type(media_obj) {
      const media_typename = media_obj.__typename
      if (media_typename == "GraphVideo"){
        return "video"
      }
      if (media_typename == "GraphImage"){
        return "image"
      }
      if (media_typename == "GraphSidecar"){
        return "carousel"
      }
      return "EMPTY"
    }

    _get_media_url(media_obj) {
      var data
      if (media_obj.__typename == "GraphVideo"){
        data = media_obj.video_url
        if (data) {
          return data
        }
      }
      const media_type = media_obj.__typename
      if (media_type == "GraphImage" || media_type == "GraphSidecar"){
        data = media_obj.display_url
        if (data) {
          return data
        }
      }
      return "EMPTY"
    }

    _get_timestamp(media_obj) {
      try {
        if (media_obj.taken_at_timestamp) {
          return media_obj.taken_at_timestamp
        }
      } catch {
        return "EMPTY"
      }
      return "EMPTY"
    }

    _parse_media_obj(media_obj) {
      media_obj = media_obj.node
      var obj = {
        "taken_at_timestamp" : media_obj.taken_at_timestamp,
        "caption" : this._get_caption(media_obj),
        "accessibility_caption" : this._get_accessibility_caption(media_obj),
        "like_count" : media_obj.edge_liked_by.count,
        "comments_count"  : media_obj.edge_media_to_comment.count,
        "location"  : this._get_location(media_obj),
        "id" : media_obj.id,
        "timestamp" : this._get_timestamp(media_obj),
        "url" : media_obj.display_url,
        "shortcode" : media_obj.shortcode,
        "media_type" : this._get_media_type(media_obj),
        "grid_url" : this._get_grid_url(media_obj),
        "media_url" : this._get_media_url(media_obj),
      }
      return obj
    }


}

export default ParserInstagramProfileRapid
