import { combineReducers } from "redux";
// import visibilityFilter from "./visibilityFilter";
import counter from "./counterReducer";
import { postsBySubreddit, selectedSubreddit } from "./redditReducer";
import { postsByInsta, selectedPost } from "./postReducer";
import { chatWindow} from "./willReducer";
import { hashtag, hashtagByHashtags, selectedHashtag, userSelectedHashtags } from "./hashtagReducer";
import { userSearchQuery, userAuth, userData  } from "./dashboardUserReducer";
import { fb } from "./fbReducer";
import { publishData } from "./publishReducer";
import { willPreset } from "./willPresetReducer";
import { ayrshare } from "./ayrshareReducer";
import { instagram } from "./instagramReducer";
import { target } from "./targetReducer";
import { socialAccount } from "./socialAccountReducer";

import { general } from "./generalReducer";
import { auth } from "./authReducer";
import { account } from "./accountReducer";

//
const reducer =  combineReducers(
  {
    fb,
    chatWindow,
    
    auth,
    general,
    account,
  });

// const reducer =  combineReducers(
//   {
//     auth,
//     general,
//     instagram,
//     ayrshare,
//     publishData,
//     userSearchQuery,
//     userData,
//     chatWindow,
//     willPreset,
//     userSelectedHashtags,
//     target,
//     socialAccount
//   });


export default reducer
